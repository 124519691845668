import { ServiceTypeFour } from "./ServiceTypeFour";
import { ServiceTypeOne } from "./ServiceTypeOne";
import { ServiceTypeThree } from "./ServiceTypeThree";

import { ServiceTypeTwo } from "./ServiceTypeTwo";

import { useTranslation } from "react-i18next";
import { useProfileStore } from "../../../store/useProfileStore";
import { useControlsStore } from "../../../store/useControlsStore";
import { useRatesStore } from "../../../store/useRatesStore";
import { useProfileDetailsStore } from "../../../store/useProfileDetailsStore";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useErrorFieldStore } from "../../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../../store/useDetailParentsStore";
import { DateIcon } from "../../../assets/image/DateIcon";
import { TUTOR_FIELDS } from "../../../utils/variables";

export function StepThreeContent({ updateProfile, afterLoginsField }) {
  const { t } = useTranslation();
  const [setMobile, setStep, setCalendarType, setProcess] = useControlsStore((state) => [
    state.setMobile,
    state.setStep,
    state.setCalendarType,
    state.setProcess,
  ]);
  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const [detailParents, setDetailParents] = useDetailParentsStore((state) => [
    state.detailParents,
    state.setDetailParents,
  ]);

  const { nanyRate, generalRate, secondaryRate, tutorRate } = useRatesStore();

  const { qualifications } = useProfileDetailsStore();

  const detail = useProfileStore((state) => state.detail);

  const today = new Date();

  return (
    <div className="detail preferences   work-experience job_performance setp3">
      {detail.service_type && detail.service_type.tab1 && <ServiceTypeOne />}

      {detail.service_type && detail.service_type.tab2 && <ServiceTypeTwo />}
      {detail.service_type && detail.service_type.tab3 && <ServiceTypeThree />}
      {detail.service_type && detail.service_type.tab4 && <ServiceTypeFour />}
      <div className="general_info tutor  preferred_school_jobs parents2">
        <h2 className="border">{t("general-info")}</h2>
        <div className="form_group full">
          <label>
            {t("work-experience-preffer")}
            <span className={errorField.tutorexp != "" ? "starred" : ""}>
              *
            </span>
          </label>
          <div class="select">
            <select
              value={
                detailParents.tutorexp != ""
                  ? detailParents.tutorexp
                  : t("choose-list")
              }
              onChange={(e) => {
                setErrorField({ ...errorField, tutorexp: "" });
                setDetailParents({
                  ...detailParents,
                  tutorexp: e.target.value,
                });
              }}
            >
              <option disabled={true}>{t("choose-list")}</option>
              <option value={t("years-0-1")}>{t("years-0-1")}</option>
              <option value={"1 - 2 years"}>{t("years-1-2")}</option>
              <option value={"2 - 4 years"}>{t("years-2-4")}</option>
              <option value={"More than 4 years"}>{t("years-4-more")}</option>
            </select>
          </div>
        </div>

        <div className="form_group full">
          <label>
            {t("age-provider-preffer")}
            <span
              className={
                errorField.preferredageofprovider != "" ? "starred" : ""
              }
            >
              *
            </span>
          </label>
          <div class="select">
            <select
              value={
                detailParents.preferredageofprovider != ""
                  ? detailParents.preferredageofprovider
                  : t("choose-list")
              }
              onChange={(e) => {
                setErrorField({
                  ...errorField,
                  preferredageofprovider: "",
                });
                setDetailParents({
                  ...detailParents,
                  preferredageofprovider: e.target.value,
                });
              }}
            >
              <option disabled={true}>{t("choose-list")}</option>
              <option value={"18 - 23 years"}>{t("years-18-23")}</option>
              <option value={"24 - 30 years"}>{t("years-24-30")}</option>
              <option value={"31 - 40 years"}>{t("years-31-40")}</option>
              <option value={"Older than 40 years"}>{t("years-40")}</option>
              <option value={"Any"}>{t("text-any")}</option>
            </select>
          </div>
        </div>
        <div className="form_group full">
          <label>
            {t("start-date")}
            <span className={errorField.tutorstartdate != "" ? "starred" : ""}>
              *
            </span>
          </label>
          <div className="date_cal">
            <span>
              <DateIcon />
            </span>
            <DatePicker
              className={errorField.tutorstartdate != "" ? "bordererror" : ""}
              minDate={today}
              selected={
                detailParents.tutorstartdate != null &&
                detailParents.tutorstartdate != ""
                  ? new Date(detailParents.tutorstartdate)
                  : today
              }
              onChange={(date) => {
                setErrorField({ ...errorField, tutorstartdate: "" });
                setDetailParents({
                  ...detailParents,
                  tutorstartdate:
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0"),
                });
              }}
            />
          </div>
        </div>
        <div className="form_group full">
          <label>
            {t("text-frequency")}
            <span
              className={errorField.tutorintrestedin != "" ? "starred" : ""}
            >
              *
            </span>
          </label>
          <div className="checkbox create">
            <ul
              onClick={(e) =>
                setErrorField({ ...errorField, tutorintrestedin: "" })
              }
            >
              <li>
                <input
                  type="radio"
                  name="a"
                  checked={
                    detailParents.tutorintrestedin == t("full-time")
                      ? true
                      : false
                  }
                  onClick={(e) =>
                    setDetailParents({
                      ...detailParents,
                      tutorintrestedin: t("full-time"),
                    })
                  }
                />
                <span> {t("full-time")}</span>
              </li>
              <li>
                <input
                  type="radio"
                  name="a"
                  checked={
                    detailParents.tutorintrestedin == t("part-time")
                      ? true
                      : false
                  }
                  onClick={(e) =>
                    setDetailParents({
                      ...detailParents,
                      tutorintrestedin: t("part-time"),
                    })
                  }
                />
                <span> {t("part-time")}</span>
              </li>
              <li>
                <input
                  type="radio"
                  name="a"
                  checked={
                    detailParents.tutorintrestedin == t("occasionally")
                      ? true
                      : false
                  }
                  onClick={(e) =>
                    setDetailParents({
                      ...detailParents,
                      tutorintrestedin: t("occasionally"),
                    })
                  }
                />
                <span> {t("occasionally")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="button">
        <button
          onClick={(e) => {
            setStep(2);
            setMobile(-20);
            window.scrollTo({ top: 0 });
          }}
        >
          {t("text-back")}
        </button>
        <button
          onClick={(e) => {
            if (
              (detail.service_type && detail.service_type.tab1
                ? detailParents.liveinnany != "" && nanyRate.max > 5
                : detailParents.tutorexp != "") &&
              (detail.service_type && detail.service_type.tab4
                ? (qualifications.English ||
                    qualifications.Serbian ||
                    qualifications.Mathematics ||
                    qualifications.Physics ||
                    qualifications.Chemistry ||
                    qualifications.Other) &&
                  detailParents.tutorintrestedonlinecls != "" &&
                  tutorRate.max > 5
                : detailParents.tutorexp != "") &&
              detailParents.tutorexp != "" &&
              detailParents.preferredageofprovider != "" &&
              detailParents.tutorstartdate != "" &&
              detailParents.tutorintrestedin &&
              (detail.service_type && detail.service_type.tab2
                ? generalRate.max > 5
                : detailParents.tutorintrestedin) &&
              (detail.service_type && detail.service_type.tab3
                ? secondaryRate.max > 5
                : detailParents.tutorintrestedin)
            ) {
              setStep(4);
              setProcess(57);
              setMobile(-32);
              window.scrollTo({ top: 0 });
              updateProfile(false);
              setTimeout(() => {
                setCalendarType(1);
              }, 500);
            } else {
              TUTOR_FIELDS.map((e) => {
                afterLoginsField(e.name);
              });
            }
          }}
        >
          {t("text-next")}
        </button>
      </div>
    </div>
  );
}
