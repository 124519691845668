import { useTranslation } from "react-i18next";
import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useControlsStore } from "../../store/useControlsStore";
import { REQUIREMENTS_FIELDS } from "../../utils/variables";
import { useProfileDetailsStore } from "../../store/useProfileDetailsStore";

import { BanCarIcon } from "../../assets/image/BanCarIcon";
import { CookIcon } from "../../assets/image/CookIcon";
import { BanCookIcon } from "../../assets/image/BanCookIcon";
import { HouseIcon } from "../../assets/image/HouseIcon";
import { BanHouseIcon } from "../../assets/image/BanHouseIcon";
import { BanPlaneIcon } from "../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../assets/image/PlaneIcon";
import { CarIcon } from "../../assets/image/CarIcon";

import { DATA_COUNTRY } from "../../utils/data";
import { saveToLocalStorage } from "../../utils/helperFunctions";

export function StepFiveContent({ updateProfile, afterLoginsField }) {
  const { t } = useTranslation();
  const [setMobile, setStep, setCalendarType, setProcess] = useControlsStore(
    (state) => [
      state.setMobile,
      state.setStep,
      state.setCalendarType,
      state.setProcess,
    ]
  );
  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const [detailParents, setDetailParents] = useDetailParentsStore((state) => [
    state.detailParents,
    state.setDetailParents,
  ]);

  const { oralSpeak, habit, setOralSpeak, setHabit } = useProfileDetailsStore();

  const detail = useProfileStore((state) => state.detail);

  console.log(oralSpeak, "ORALSPEACK")

  return (
    <div className="detail  additional_info info_parents setp6">
      <div className="form_group border qualification">
        <label>
          {t("ideal-candidate-speak")}
          <span className={errorField.candidatespeaks == "" ? "" : "starred"}>
            *
          </span>
        </label>
        <div className="checkbox create setredio vertical">
          <ul
            onClick={(e) =>
              setErrorField({ ...errorField, candidatespeaks: "" })
            }
          >
            <li>
              <input
                type="checkbox"
                name=""
                checked={oralSpeak.English == "English" ? true : false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setOralSpeak({
                      ...oralSpeak,
                      English: "English",
                    });
                  } else {
                    setOralSpeak({ ...oralSpeak, English: "" });
                  }
                }}
              />
              <span> {t("english")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                checked={oralSpeak.Spanish == "Spanish" ? true : false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setOralSpeak({
                      ...oralSpeak,
                      Spanish: "Spanish",
                    });
                  } else {
                    setOralSpeak({ ...oralSpeak, Spanish: "" });
                  }
                }}
              />
              <span> {t("spanish")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                checked={oralSpeak.French == "French" ? true : false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setOralSpeak({ ...oralSpeak, French: "French" });
                  } else {
                    setOralSpeak({ ...oralSpeak, French: "" });
                  }
                }}
              />
              <span> {t("french")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                checked={oralSpeak.Chinese == "Chinese" ? true : false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setOralSpeak({
                      ...oralSpeak,
                      Chinese: "Chinese",
                    });
                  } else {
                    setOralSpeak({ ...oralSpeak, Chinese: "" });
                  }
                }}
              />
              <span> {t("chinese")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                checked={oralSpeak.German == "German" ? true : false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setOralSpeak({ ...oralSpeak, German: "German" });
                  } else {
                    setOralSpeak({ ...oralSpeak, German: "" });
                  }
                }}
              />
              <span> {t("german")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                checked={oralSpeak.Italian == "Italian" ? true : false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setOralSpeak({
                      ...oralSpeak,
                      Italian: "Italian",
                    });
                  } else {
                    setOralSpeak({ ...oralSpeak, Italian: "" });
                  }
                }}
              />
              <span> {t("italian")}</span>
            </li>
            <li>
              <input type="checkbox" name="" />
              <span>
                {t("text-other")}
                <input
                  type="text"
                  placeholder={t("text-type")}
                  defaultValue={oralSpeak.Other}
                  onChange={(e) => {
                    setOralSpeak({
                      ...oralSpeak,
                      Other: t(e.target.value),
                    });
                  }}
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="iconsec">
        <div className="left2">
          {detail.service_type && detail.service_type.tab1 ? (
            <div className="icon border">
              <div className="form_group" style={{ marginBottom: "0" }}>
                <label>
                  {t("text-i-need")}
                  <span>*</span>
                </label>
                <br />
              </div>
              <ul>
                <li
                  onClick={(e) =>
                    setErrorField({
                      ...errorField,
                      childtransportation: "",
                    })
                  }
                  className={
                    errorField.childtransportation != "" ? "starred" : ""
                  }
                >
                  {t("child-transportation")}
                  <div className="icons">
                    <BanCarIcon
                      className={habit.licence == "false" ? "active" : ""}
                      onClick={() => setHabit({ ...habit, licence: "false" })}
                    />
                    <CarIcon
                      className={habit.licence == "true" ? "active" : ""}
                      onClick={(e) => setHabit({ ...habit, licence: "true" })}
                    />
                  </div>
                </li>
                <li
                  onClick={(e) =>
                    setErrorField({
                      ...errorField,
                      occasionaltraveling: "",
                    })
                  }
                  className={
                    errorField.occasionaltraveling != "" ? "starred" : ""
                  }
                >
                  {t("occasional-traveling")}
                  <div className="icons">
                    <BanPlaneIcon
                      className={habit.family == "false" ? "active" : ""}
                      onClick={(e) => setHabit({ ...habit, family: "false" })}
                    />

                    <PlaneIcon
                      className={habit.family == "true" ? "active" : ""}
                      onClick={(e) => setHabit({ ...habit, family: "true" })}
                    />
                  </div>
                </li>
                <li
                  onClick={(e) =>
                    setErrorField({
                      ...errorField,
                      lighthousework: "",
                    })
                  }
                  className={errorField.lighthousework != "" ? "starred" : ""}
                >
                  {t("light-housework")}
                  <div className="icons">
                    <BanHouseIcon
                      className={habit.housework == "false" ? "active" : ""}
                      onClick={(e) =>
                        setHabit({ ...habit, housework: "false" })
                      }
                    />

                    <HouseIcon
                      className={habit.housework == "true" ? "active" : ""}
                      onClick={(e) => setHabit({ ...habit, housework: "true" })}
                    />
                  </div>
                </li>
                <li
                  onClick={(e) =>
                    setErrorField({
                      ...errorField,
                      cookingforkids: "",
                    })
                  }
                  className={errorField.cookingforkids != "" ? "starred" : ""}
                >
                  {t("cooking-kids")}
                  <div className="icons">
                    <BanCookIcon
                      className={habit.kids == "false" ? "active" : ""}
                      onClick={(e) => setHabit({ ...habit, kids: "false" })}
                    />

                    <CookIcon
                      className={habit.kids == "true" ? "active" : ""}
                      onClick={(e) => setHabit({ ...habit, kids: "true" })}
                    />
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}

          <div className="form_group full" style={{ marginTop: "20px" }}>
            <label>
              {t("services-abroad-question")}
              <span className={errorField.workingabroad != "" ? "starred" : ""}>
                *
              </span>
            </label>
            <div className="checkbox create">
              <ul
                onClick={(e) =>
                  setErrorField({ ...errorField, workingabroad: "" })
                }
              >
                <li>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) =>
                      setDetailParents({
                        ...detailParents,
                        workingabroad: "Yes",
                      })
                    }
                    checked={
                      detailParents.workingabroad == "Yes" ? true : false
                    }
                  />
                  <span> {t("confirm")}</span>
                </li>
                <li>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) =>
                      setDetailParents({
                        ...detailParents,
                        workingabroad: "No",
                      })
                    }
                    checked={detailParents.workingabroad == "No" ? true : false}
                  />
                  <span> {t("objection")}</span>
                </li>
              </ul>
            </div>
            {/* <div className='errorfield'>{error.message}</div>*/}
          </div>
          {detailParents.workingabroad == "Yes" ? (
            <>
              <div className="form_group full">
                <label>
                  {t("preffer-country")}
                  <span
                    className={
                      errorField.preferredcountry != "" ? "starred" : ""
                    }
                  >
                    *
                  </span>
                </label>
                <div class="select">
                  <select
                    onChange={(e) => {
                      setErrorField({
                        ...errorField,
                        preferredcountry: "",
                      });
                      setDetailParents({
                        ...detailParents,
                        preferredcountry: e.target.value,
                      });
                    }}
                  >
                    <option disabled="">
                      {detailParents.preferredcountry != ""
                        ? detailParents.preferredcountry
                        : t("choose-list")}
                    </option>
                    {DATA_COUNTRY.data.map((e) => {
                      return <option value={e.country}>{e.country}</option>;
                    })}
                  </select>
                </div>
                {/* <div className='errorfield'>{error.message}</div>*/}
              </div>

              <div className="form_group full">
                <label>
                  {t("preffer-city")}
                  <span
                    className={errorField.preferredcity != "" ? "starred" : ""}
                  >
                    *
                  </span>
                </label>
                <div class="text">
                  <input
                    type="text"
                    placeholder={t("text-type")}
                    onChange={(e) => {
                      setErrorField({
                        ...errorField,
                        preferredcity: "",
                      });
                      setDetailParents({
                        ...detailParents,
                        preferredcity: e.target.value,
                      });
                    }}
                    defaultValue={detailParents.preferredcity}
                  />
                </div>
                {/* <div className='errorfield'>{error.message}</div>*/}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="button">
        <button
          onClick={(e) => {
            setStep(4);
            setMobile(-46);
            window.scrollTo({ top: 0 });
            setTimeout(() => {
              setCalendarType(1);
            }, 500);
          }}
        >
          {t("text-back")}
        </button>
        <button
          onClick={(e) => {
            if (
              (oralSpeak.English != "" ||
                oralSpeak.Spanish ||
                oralSpeak.French ||
                oralSpeak.Chinese ||
                oralSpeak.German ||
                oralSpeak.Italian ||
                oralSpeak.Other) &&
              (detail.service_type &&
              detail.service_type.tab1 &&
              Object.keys(detail.service_type).length == 1
                ? habit.licence && habit.family && habit.housework && habit.kids
                : detailParents.workingabroad != "") &&
              detailParents.workingabroad != "" &&
              (detailParents.workingabroad == "Yes"
                ? detailParents.preferredcountry != "" &&
                  detailParents.preferredcity != ""
                : detailParents.workingabroad != "")
            ) {
              setStep(6);
              setMobile(-60);
              setProcess(86);
              saveToLocalStorage("oralSpeak", oralSpeak);
              window.scrollTo({ top: 0 });
              updateProfile(false);
            } else {
              REQUIREMENTS_FIELDS.map((e) => {
                afterLoginsField(e.name);
              });
            }
          }}
        >
          {t("text-next")}
        </button>
      </div>
    </div>
  );
}
