import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export const SuccessModal = ({
    isSuccessfullyModal,
    setIsSuccessfullyModal,
    generalJobApply
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language

  return (
    <Modal
      show={isSuccessfullyModal}
      onHide={(e) => setIsSuccessfullyModal(false)}
    >
      <Modal.Body>
        <div className="promocode_content login_first">
          <h2>{t("text-congratulations")}</h2>
          {generalJobApply == t("post-apply-job") ? (
            ""
          ) : (
            <img src={window.location.origin + "/images/activate.png"} />
          )}
          <p>
            {language === "en" && generalJobApply.includes("post-apply-job")
              ? generalJobApply
              : "Uspešno ste se prijavili za ovaj posao. Srećno!"}
          </p>

          {generalJobApply == t("post-apply-job") ? (
            <button onClick={(e) => setIsSuccessfullyModal(false)}>
              {t("text-close-up")}
            </button>
          ) : (
            <button>
              <Link to={"/search-parents"}>{t("apply-similar-jobs")}</Link>
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
