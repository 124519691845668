import React from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useTranslation } from "react-i18next";

import { LinkedinIcon } from "../../../assets/image/LinkedinIcon";
import { authLinkedin } from "../../../api";

export function LinkedinContent({ profile, status, handleResponse }) {
  const { t } = useTranslation();

  const { linkedInLogin } = useLinkedIn({
    clientId: "860qiqz9pllqgi",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_emailaddress r_liteprofile",
    onSuccess: (response) => {
      fetchProfileLinkedin(response);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const fetchProfileLinkedin = async (accessToken) => {
    try {
      const result = await authLinkedin(accessToken);
      if (JSON.parse(result.data).elements[0]) {
        handleResponse({
          profile: {
            email: Object.values(JSON.parse(result.data).elements[0])[0]
              .emailAddress,
            type: "linkedIn",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  return (
    <>
      {profile ? (
        <LinkedinIcon
          onClick={linkedInLogin}
          className={status ? "active" : ""}
          color={"#B7B7B7"}
        />
      ) : (
        <a className="indi" onClick={linkedInLogin}>
          <i className="fa-brands fa-linkedin-in"></i>
          {t("log-in-linkedin")}
        </a>
      )}
    </>
  );
}
