import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { api } from "../../../urls";

import { DATA_COUNTRY } from "../../../utils/data";
import { FacebookProvider, LoginButton } from "react-facebook";
import { useTranslation } from "react-i18next";
import { ProfessionChange } from "../ProfessionChange";
import { Calendar } from "../Calendar";
import { LinkedinContent } from "../LinkedinContent";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { LinkedinIcon } from "../../../assets/image/LinkedinIcon";
import { DateIcon } from "../../../assets/image/DateIcon";
import { BanCarIcon } from "../../../assets/image/BanCarIcon";
import { CarIcon } from "../../../assets/image/CarIcon";
import { BanPlaneIcon } from "../../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../../assets/image/PlaneIcon";
import { BanHouseIcon } from "../../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../../assets/image/HouseIcon";
import { BanCookIcon } from "../../../assets/image/BanCookIcon";
import { CookIcon } from "../../../assets/image/CookIcon";
import { FlagIcon } from "../../../assets/image/FlagIcon";
import { ArrowUpIcon } from "../../../assets/image/ArrowUpIcon";
import { FacebookIcon } from "../../../assets/image/FacebookIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";
import { VideoIcon } from "../../../assets/image/VideoIcon";
import { PawsIcon } from "../../../assets/image/PawsIcon";
import { UploadIcon } from "../../../assets/image/UploadIcon";
import { DoneIcon } from "../../../assets/image/DoneIcon";
import { ThingIcon } from "../../../assets/image/ThingIcon";
import { MarkIcon } from "../../../assets/image/MarkIcon";
import { DumpIcon } from "../../../assets/image/DumpIcon";
import { AddIcon } from "../../../assets/image/AddIcon";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../../utils/variables";

export function ViewEdit() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const today = new Date();

  const [isShowModal, setIsShowModal] = useState(false);
  const [isDisable, setIsDisable] = useState("");

  const [generalInfo, setGeneralInfo] = useState({
    About: localStorage.getItem("edittime") ? "active" : "",
    kids: localStorage.getItem("edittime") ? "active" : "",
    job: localStorage.getItem("edittime") ? "active" : "",
    availability: localStorage.getItem("edittime") ? "active" : "",
    info: localStorage.getItem("edittime") ? "active" : "",
    security: localStorage.getItem("edittime") ? "active" : "",
    personal: localStorage.getItem("edittime") ? "active" : "",
  });

  const [calendarType, setCalendarType] = useState(5);
  const [promoCity, setPromoCity] = useState({
    firstcity: "",
    secondcity: "",
    thirdcity: "",
  });

  const [offerPromotional, setOfferPromotional] = useState({
    afterAll: "",
    licensed: "",
    medical: "",
    education: "",
    special: "",
    activities: "",
    other: "",
  });

  const [offerEducation, setOfferEducation] = useState({
    sensory: "",
    school: "",
    all: "",
    educational: "",
    baby: "",
  });

  const [editGeneralInfo, setEditGeneralInfo] = useState({
    About: localStorage.getItem("edittime") ? "edit" : "",
    kids: localStorage.getItem("edittime") ? "edit" : "",
    job: localStorage.getItem("edittime") ? "edit" : "",
    availability: localStorage.getItem("edittime") ? "edit" : "",
    info: localStorage.getItem("edittime") ? "edit" : "",
    security: localStorage.getItem("edittime") ? "edit" : "",
    personal: localStorage.getItem("edittime") ? "edit" : "",
  });
  const [isRequest, setIsRequest] = useState(true);

  const [errorField, setErrorField] = useState({
    about: "",
    video: "",
    file_path: "",
    childneedcareno: "",
    childage: "",
    childsocialneed: "",
    yourkidallapplicable: "",
    childneediep: "",
    childmedicalcondition: "",
    childmedicaldescription: "",
    childanyallergies: "",
    childallergiesdescription: "",
    liveinnany: "",
    nanyperhrrate: "",
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",
    tutorperhrrate: "",
    tutorexp: "",
    tutorstartdate: "",
    tutorintrestedin: "",
    preferredageofprovider: "",
    fulltime: "",
    beforeschool: "",
    afterschool: "",
    weekends: "",
    overnight: "",
    candidatespeaks: "",
    occasionaltraveling: "",
    cookingforkids: "",
    lighthousework: "",
    preferredcountry: "",
    childtransportation: "",
    workingabroad: "",
    preferredcity: "",
    phone: "",
    jobs: "",
    jobs_description: "",
    jobs2: "",
    jobs_description2: "",
    seperhrrate: "",
    seterhrrate: "",
  });

  const [detailParents, setDetailParents] = useState({
    about: "",
    video: "",
    file_path: "",
    childneedcareno: "",
    childage: "",
    childsocialneed: "",
    yourkidallapplicable: "",
    childneediep: "",
    childmedicalcondition: "",
    childmedicaldescription: "",
    childanyallergies: "",
    childallergiesdescription: "",
    liveinnany: "",
    nanyperhrrate: "",
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",
    tutorperhrrate: "",
    tutorexp: "",
    tutorstartdate: "",
    tutorintrestedin: "",
    preferredageofprovider: "",
    countrycode: "",
    fulltime: {},
    beforeschool: {},
    afterschool: {},
    weekends: {},
    overnight: {},
    candidatespeaks: "",
    occasionaltraveling: "",
    cookingforkids: "",
    lighthousework: "",
    preferredcountry: "",
    childtransportation: "",
    workingabroad: "",
    preferredcity: "",
    facebookverify: "",
    twitterverify: "",
    linkdinverify: "",
    instaverify: "",
    phone: "",
    jobs: "",
    jobs_description: "",
    jobs2: "",
    jobs_description2: "",
    seperhrrate: "",
    seterhrrate: "",
    alertDeals: "",
    alertEDUproducts: "",
    alertJobposts: "",
    sendnotificationsto: "",
    plateformonsocialmedia: "",
    active_service: {},
    inactive_service: {},
    calanderlastupdate: "",
  });

  const [error, setError] = useState({
    phone: "",
    email: "",
  });
  const [image, setImage] = useState(null);
  const [isPhotoUpload, setIsPhotoUpload] = useState(false);
  const [isOtp, setIsOtp] = useState("");

  const [isLoad, setIsLoad] = useState(false);
  const [detail, setDetail] = useState({});
  const [childrenAge, setChildrenAge] = useState([]);
  const [children, setChildren] = useState(0);
  const [listSelectElement, setListSelectElement] = useState([]);
  const [verify, setVerify] = useState("");
  const [isCode, setIsCode] = useState(true);

  const [inActiveProfessional, setInActiveProfessional] = useState({});
  const [activeProfession, setActiveProfession] = useState({});

  const [contactCode, setContactCode] = useState({
    code: "",
    flag: "",
  });
  const [nannyRate, setNannyRate] = useState({
    min: 0,
    max: 60,
  });

  const [tutorRate, setTutorRate] = useState({
    min: 0,
    max: 60,
  });

  const [generalRate, setGeneralRate] = useState({
    min: 0,
    max: 60,
  });
  const [secondaryRate, setSecondaryRate] = useState({
    min: 0,
    max: 60,
  });
  const [qualifications, setQualifications] = useState({
    English: "",
    Serbian: "",
    Mathematics: "",
    Physics: "",
    Chemistry: "",
    Other: "",
  });
  const [photo, setPhoto] = useState("");

  const [isOpen, setIsOpen] = useState(true);
  const [oralSpeak, setOralSpeak] = useState({
    English: "",
    Spanish: "",
    French: "",
    Chinese: "",
    German: "",
    Italian: "",
    Other: "",
  });
  const [habit, setHabit] = useState({
    licence: "",
    kids: "",
    housework: "",
    family: "",
  });
  const [failed, setFailed] = useState(0);

  const handleElementByID = (e, x) => {
    if (isOpen) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsOpen(true);
    }
  };

  const handleCodeSelect = () => {
    if (isCode) {
      setIsCode(false);
    } else {
      setIsCode(true);
    }
  };

  const handleCloseModal = () => setIsShowModal(false);

  const handleSelectElement = (data) => {
    let sum = false;
    listSelectElement.map((e, index) => {
      if (e.name == data) {
        sum = true;
        listSelectElement.splice(index, 1);
      }
    });
    if (
      (children == "twins" ? 1 : children) > listSelectElement.length ||
      children == 3
    ) {
      if (sum == false) {
        listSelectElement.push({ name: data });
      }
    }
    setTimeout(() => {
      setListSelectElement([...listSelectElement]);
    }, 500);
  };

  const handleDeleteElement = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setChildrenAge([...childrenAge]);
    }, 500);
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
        setTimeout(() => {
          const x = Object.keys(detailParents).forEach(function (key) {
            detailParents[key] =
              result.data[key] != null ? result.data[key] : "";
            setDetailParents({ ...detailParents });
          });
        }, 500);
        setTimeout(() => {
          if (result.data.calanderlastupdate != null) {
            setCalendarType(parseInt(result.data.calanderlastupdate));
          }
        }, 2000);
        setChildren(result.data.childneedcareno);
        setListSelectElement(
          result.data.childage != null ? result.data.childage : []
        );
        setChildrenAge(
          result.data.yourkidallapplicable != null
            ? result.data.yourkidallapplicable
            : []
        );
        setNannyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1] > 0
                ? result.data.nanyperhrrate
                    .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                    .split("-")[1]
                : 60
              : 60,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1] > 0
                ? result.data.tutorperhrrate
                    .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                    .split("-")[1]
                : 60
              : 60,
        });
        setGeneralRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1] > 0
                ? result.data.seterhrrate
                    .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                    .split("-")[1]
                : 60
              : 60,
        });
        setSecondaryRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1] > 0
                ? result.data.seperhrrate
                    .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                    .split("-")[1]
                : 60
              : 60,
        });
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        setOralSpeak(
          result.data.candidatespeaks != null
            ? result.data.candidatespeaks
            : { ...oralSpeak }
        );

        setHabit({
          licence: result.data.childtransportation
            ? result.data.childtransportation
            : "",
          kids: result.data.cookingforkids ? result.data.cookingforkids : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.occasionaltraveling
            ? result.data.occasionaltraveling
            : "",
        });
        result.data.prefcityforpromo
          ? setPromoCity(result.data.prefcityforpromo)
          : setPromoCity(promoCity);
        result.data.promotionaloffersfor
          ? setOfferPromotional(result.data.promotionaloffersfor)
          : setOfferPromotional(offerPromotional);
        result.data.educationalproductoffersfor
          ? setOfferEducation(result.data.educationalproductoffersfor)
          : setOfferEducation(offerEducation);
        setActiveProfession(result.data.service_type);
      })
      .catch((error) => console.log("error", error));
  };

  const handleMobileVerify = () => {
    if (detailParents.phone.length > 7) {
      setIsLoad(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonenoforverify:
          contactCode.code != ""
            ? contactCode.code + detailParents.phone
            : "+" + detailParents.countrycode + detailParents.phone,
        phone: detailParents.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : detailParents.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/sendotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsLoad(false);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log("error", error);
        });
    } else {
      setError({
        ...error,
        phone: "error",
      });
    }
  };

  const handleVerifyOtp = () => {
    if (isOtp.length > 3) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonetoken: isOtp,
        phone: detailParents.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : detailParents.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/verifyotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setVerify(result.success ? "done" : "");
          setFailed(failed + 1);
          result.success == true
            ? (document.getElementById("success").style.display = "block")
            : (document.getElementById("success").style.display = "none");
          result.message == "failed" && failed == 0
            ? (document.getElementById("success4").style.display = "block")
            : (document.getElementById("success4").style.display = "none");
          result.message == "failed" && failed == 1
            ? (document.getElementById("success3").style.display = "block")
            : (document.getElementById("success3").style.display = "none");
        })
        .catch((error) => console.log("error", error));
    } else {
      setError({
        ...error,
        otperror: "error",
      });
    }
  };

  useEffect(() => {
    if (isRequest) {
      setIsRequest(false);
      handleProfileData();
      setTimeout(() => {}, 500);
    }
  }, [
    detail,
    detailParents,
    promoCity,
    offerPromotional,
    inActiveProfessional,
  ]);

  const handleCalendarData = (name, e) => {
    console.log(name, e);
    setDetailParents({ ...detailParents, [name]: e });
  };

  const handleResponse = (data) => {
    if (data.profile.type) {
      setDetailParents({ ...detailParents, linkdinverify: true });
    } else {
      setDetailParents({ ...detailParents, facebookverify: true });
    }
  };

  const handleErrorMessage = (error) => {
    console.log({ error });
  };

  const handleProfileUpdate = (inactive) => {
    localStorage.setItem("search", "Profile");
    localStorage.setItem("search2", "setting");
    setIsLoad(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("about", detailParents.about);
    detailParents.video.name
      ? formdata.append("video", detailParents.video)
      : formdata.append("about", detailParents.about);
    detailParents.file_path.name
      ? formdata.append("file_path", detailParents.file_path)
      : formdata.append("about", detailParents.about);
    formdata.append("childneedcareno", children);
    formdata.append("childage", JSON.stringify(listSelectElement));
    formdata.append("childsocialneed", detailParents.childsocialneed);
    formdata.append("yourkidallapplicable", JSON.stringify(childrenAge));
    formdata.append("childneediep", detailParents.childneediep);
    formdata.append(
      "childmedicalcondition",
      detailParents.childmedicalcondition
    );
    formdata.append(
      "childmedicaldescription",
      detailParents.childmedicaldescription
    );
    formdata.append("childanyallergies", detailParents.childanyallergies);
    formdata.append(
      "childallergiesdescription",
      detailParents.childallergiesdescription
    );
    formdata.append("liveinnany", detailParents.liveinnany);
    formdata.append("nanyperhrrate", nannyRate.min + "-" + nannyRate.max);
    formdata.append("tutorliketoteach", JSON.stringify(qualifications));
    formdata.append(
      "tutorintrestedonlinecls",
      detailParents.tutorintrestedonlinecls
    );
    formdata.append("tutorperhrrate", tutorRate.min + "-" + tutorRate.max);
    formdata.append("tutorexp", detailParents.tutorexp);
    formdata.append("tutorstartdate", detailParents.tutorstartdate);
    formdata.append("tutorintrestedin", detailParents.tutorintrestedin);
    formdata.append(
      "preferredageofprovider",
      detailParents.preferredageofprovider
    );
    formdata.append("fulltime", JSON.stringify(detailParents.fulltime));
    formdata.append("beforeschool", JSON.stringify(detailParents.beforeschool));
    formdata.append("afterschool", JSON.stringify(detailParents.afterschool));
    formdata.append("weekends", JSON.stringify(detailParents.weekends));
    formdata.append("overnight", JSON.stringify(detailParents.overnight));
    formdata.append("candidatespeaks", JSON.stringify(oralSpeak));
    formdata.append("occasionaltraveling", habit.family);
    formdata.append("workingabroad", detailParents.workingabroad);
    formdata.append("cookingforkids", habit.kids);
    formdata.append(
      "countrycode",
      contactCode.code != "" ? contactCode.code : detailParents.countrycode
    );
    formdata.append("lighthousework", habit.housework);
    formdata.append("childtransportation", habit.licence);
    formdata.append("preferredcountry", detailParents.preferredcountry);
    formdata.append("preferredcity", detailParents.preferredcity);
    formdata.append("facebookverify", detailParents.facebookverify);
    formdata.append("twitterverify", detailParents.twitterverify);
    formdata.append("linkdinverify", detailParents.linkdinverify);
    formdata.append("instaverify", detailParents.instaverify);
    formdata.append("seterhrrate", generalRate.min + "-" + generalRate.max);
    formdata.append("seperhrrate", secondaryRate.min + "-" + secondaryRate.max);
    formdata.append("prefcityforpromo", JSON.stringify(promoCity));
    formdata.append("promotionaloffersfor", JSON.stringify(offerPromotional));
    formdata.append(
      "educationalproductoffersfor",
      JSON.stringify(offerEducation)
    );
    formdata.append("alertDeals", detailParents.alertDeals);
    formdata.append("alertEDUproducts", detailParents.alertEDUproducts);
    formdata.append("alertJobposts", detailParents.alertJobposts);
    formdata.append("sendnotificationsto", detailParents.sendnotificationsto);
    {
      [...Array(detail.job.length)].map((data, index) => {
        formdata.append("jobs[" + index + "][title]", detail.job[index].title);
        formdata.append(
          "jobs[" + index + "][description]",
          detail.job[index].description
        );
        formdata.append(
          "jobs[" + index + "][job_type]",
          detail.job[index].job_type
        );
        formdata.append("jobs[" + index + "][job_id]", detail.job[index].id);
        formdata.append(
          "jobs[" + index + "][plateformonsocialmedia]",
          detailParents.plateformonsocialmedia
        );
      });
    }
    // if (detail.job.length > 0  ) {

    // }
    // if (detail.job.length > 1) {
    //     formdata.append("jobs[1][title]", detail.job[1].title)
    //     formdata.append("jobs[1][description]", detail.job[1].description)
    //     formdata.append("jobs[1][job_type]", detail.job[1].job_type)
    //     formdata.append("jobs[1][job_id]", detail.job[1].id)
    //     formdata.append("jobs[1][plateformonsocialmedia]", detailparents.plateformonsocialmedia)
    // }
    formdata.append(
      "active_service",
      Object.keys(activeProfession).length > 0
        ? JSON.stringify(activeProfession)
        : ""
    );
    formdata.append(
      "inactive_service",
      inactive
        ? Object.keys(inactive)?.length > 0
          ? JSON.stringify(inactive)
          : ""
        : ""
    );
    formdata.append("calanderlastupdate", calendarType);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
        console.log("error", error);
      });
  };

  const handleParentsUpdate = () => {
    setIsPhotoUpload(false);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("file_path", photo);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsPhotoUpload(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setPhoto(event.target.files[0]);
    }
  };
  return (
    <div className="Account daskshow editview">
      <h2>{t("Profile Overview")}</h2>
      <h3>
        <MarkRoundIcon width={20} height={20} />
        {t("keep-profile-candidate")}
      </h3>
      <div className="profile_full left_side_section ">
        <div class="profile_box">
          <h2>{t("Profile Overview")}</h2>
          <div class="profile_box_social">
            <div class="profile_box_social_sec1">
              <img
                src={window.location.origin + "/images/share_icon.svg"}
                alt=""
              />
              {detail.phoneVerifiedStatus == 1 ? (
                <img
                  src={window.location.origin + "/images/nany_phone.svg"}
                  alt=""
                />
              ) : (
                <PhoneIcon />
              )}
              <img
                src={window.location.origin + "/images/nany_msg.svg"}
                alt=""
              />
              {detail.facebookverify != null || detail.linkdinverify != null ? (
                <img
                  src={window.location.origin + "/images/nany_cont.svg"}
                  alt=""
                />
              ) : (
                <ServiceIcon />
              )}
            </div>
            {localStorage.getItem("user_type") == "parents" ? (
              <span className="addjob">
                <ProfessionChange />
              </span>
            ) : (
              ""
            )}
          </div>
          <div class="profile_pic_sec">
            <div class="profile_pic">
              <img
                src={api + "/assets/images/users/" + detail.file_path}
                width="80"
                height="80"
                alt=""
              />
              <div class="edit_icon">
                <Link to="" onClick={(e) => setIsPhotoUpload(true)}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <div class="profile_detail">
            <p>
              <VideoIcon />
              {detail.first_name + " " + detail.last_name} (
              {detail.dob != undefined
                ? new Date().getFullYear() -
                  parseInt(
                    detail.dob
                      .substr(detail.dob.lastIndexOf("\\") + 1)
                      .split("-")[0]
                  )
                : ""}
              )
            </p>
            <div class="profilestart_video">
              {detail?.reviewAvg ? (
                <>
                  {[...Array(detail?.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <StarIcon />;
                  })}
                  {[...Array(5 - detail?.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <WhiteStarIcon />;
                  })}
                </>
              ) : (
                ""
              )}
              <span>({detail.reviewcount})</span>
            </div>
          </div>
          <div class="profile_functions">
            <div class="members">
              <h5>
                {new Date().getFullYear() -
                  new Date(detail.created_at).getFullYear() >
                0
                  ? new Date().getFullYear() -
                    new Date(detail.created_at).getFullYear() +
                    t(" YRS")
                  : 0 + t(" YRS")}
              </h5>
              <h6>{t("Member")}</h6>
            </div>
            <div class="vi"></div>
            {localStorage.getItem("user_type") == "parents" ? (
              <div class="applications">
                <h5>{detail.jobs}</h5>
                <h6>{t("Job posts")}</h6>
              </div>
            ) : (
              <div class="applications">
                <h5>{detail.jobApplicationcount}</h5>
                <h6>{t("text-applications")}</h6>
              </div>
            )}
            <div class="vi"></div>
            <div class="hiring">
              <h5>{detail.hiringcount}</h5>
              <h6>{t("Hirings")}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="about_edit">
        <p>
          <h4 style={{ display: "block" }}>{t("About me and my family")}</h4>
          <PawsIcon />
          <span>{detail.about}</span>
        </p>
        <div className="rating">
          <h5>{t("Average rating")}</h5>
          <div className="score" style={{ borderColor: "#7D2B8B" }}>
            <span style={{ color: "#7D2B8B" }}>
              {detail.reviewAvg} <br />
              {detail?.reviewAvg ? (
                <>
                  {[...Array(detail.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <StarIcon />;
                  })}
                  {[...Array(5 - detail.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <WhiteStarIcon />;
                  })}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
          <p>
            {detail?.reviewAvg == 5
              ? t("post-amazing-employer")
              : detail?.reviewAvg == 4
              ? t("visit-profile-candidate")
              : detail?.reviewAvg == 3
              ? t("remember-outstanding")
              : detail?.reviewAvg <= 2
              ? t("post-falls-about-stars")
              : ""}
          </p>
        </div>
        <div className="rating">
          <h5>{t("Profile views")}</h5>
          <div className="score">
            <span>{detail.visitorcount}</span>
          </div>
          <p>
            <strong>{t("Tip:")}</strong>
            {t("keep-profile-views")}
          </p>
        </div>
      </div>
      <div
        className={
          generalInfo.About == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              About: generalInfo.About == "" ? "active" : "",
            })
          }
        >
          {t("About")}
        </h3>
        {generalInfo.About == "active" ? (
          <div className="editabout">
            {editGeneralInfo.About == "" ? (
              <>
                <button
                  onClick={(e) =>
                    setEditGeneralInfo({ ...editGeneralInfo, About: "edit" })
                  }
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <h4>{t("About me and my family")}</h4>
                <p>{detail.about}</p>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail parents1 stap1">
                  <div className="form_group full">
                    <label>
                      {t("about-yourself")}
                      <span className={errorField.about != "" ? "starred" : ""}>
                        *
                      </span>
                    </label>
                    <textarea
                      className={errorField.about != "" ? "bordererror" : ""}
                      rows={2}
                      placeholder={t("post-activities")}
                      maxlength="150"
                      name="message"
                      defaultValue={detailParents.about}
                      onChange={(e) => {
                        setDetailParents({
                          ...detailParents,
                          about: e.target.value,
                        });
                        setErrorField({ ...errorField, about: "" });
                      }}
                    ></textarea>
                    <span>
                      {t("number-characters")}
                      {150 - detailParents.about.length}
                    </span>
                  </div>

                  <div className="form_group second">
                    <label>
                      {t("upload-profile")} <a>{t("picture")} </a>
                      <span className="smallpop">
                        <strong>{t("text-choice")}</strong> {t("i")}
                        <strong>{t("text-premium")}</strong> {t("upload-photo")}
                      </span>
                      <span
                        className={errorField.file_path != "" ? "starred" : ""}
                      >
                        *
                      </span>
                    </label>
                    <div className="upload">
                      <input
                        type="file"
                        placeholder={t("choose-file")}
                        onChange={(e) => {
                          setErrorField({ ...errorField, file_path: "" });
                          setDetailParents({
                            ...detailParents,
                            file_path: e.target.files[0],
                          });
                        }}
                        accept="image/*"
                      />
                      <span
                        className={
                          errorField.file_path != "" ? "bordererror" : ""
                        }
                      >
                        {detailParents.file_path != ""
                          ? detailParents.file_path.name
                            ? detailParents.file_path.name
                            : detailParents.file_path
                          : t("choose-file")}
                      </span>
                      <button>
                        <UploadIcon />
                      </button>
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                  <div className="form_group third">
                    <label>
                      {t("upload-short")} <a>{t("text-video")} </a>
                      <span className="smallpop">
                        {t("feature-available")}
                        <strong>{t("text-premium")}</strong> {t("members.")}
                      </span>
                      {t("family-yourself")}
                    </label>
                    <div className="upload">
                      <input
                        type="file"
                        placeholder={t("choose-file")}
                        onChange={(e) =>
                          setDetailParents({
                            ...detailParents,
                            video: e.target.files[0],
                          })
                        }
                        accept="video/*"
                      />
                      <span
                        className={errorField.video != "" ? "bordererror" : ""}
                      >
                        {detailParents.video != ""
                          ? detailParents.video.name
                            ? detailParents.video.name
                            : detailParents.video
                          : t("choose-file")}
                      </span>
                      <button>
                        <UploadIcon />
                      </button>
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.kids == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              kids: generalInfo.kids == "" ? "active" : "",
            })
          }
        >
          {t("My Kids")}
        </h3>
        {generalInfo.kids == "active" ? (
          <div className="editkids">
            {editGeneralInfo.kids == "" ? (
              <>
                <button
                  onClick={(e) =>
                    setEditGeneralInfo({ ...editGeneralInfo, kids: "edit" })
                  }
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <label>
                  {t("Number of children to take care of")}
                  <span
                    className={`cir ${
                      children === "twins" && language === "sr"
                        ? "specialStyle"
                        : ""
                    }`}
                  >
                    {t(children)}
                  </span>
                </label>
                <label>
                  {t("child-age")}
                  <span>
                    <strong>
                      {listSelectElement.map((e, index) => {
                        const modifiedName =
                          language === "sr"
                            ? e.name.includes("years")
                              ? e.name.replace("years", "godina")
                              : e.name
                            : e.name.includes("godina")
                            ? e.name.replace("godina", "years")
                            : e.name;
                        return (
                          modifiedName +
                          (listSelectElement.length - 1 >= index + 1
                            ? "; "
                            : "")
                        );
                      })}
                    </strong>
                  </span>
                </label>
                <br />
                <label>
                  {t("Special needs")}
                  <span>
                    <strong>
                      <DoneIcon />
                      {t(detail.childsocialneed)}
                    </strong>
                  </span>
                </label>
                {detail.childsocialneed == "No"
                  ? ""
                  : childrenAge.map((e) => {
                      return (
                        <label>
                          <span>
                            <strong>
                              <DoneIcon />
                              {t(e.name)}
                            </strong>
                          </span>
                        </label>
                      );
                    })}
                {detail.service_type && detail.service_type.tab2 ? (
                  <label>
                    {t("Child needs IEP")}
                    <span>
                      <strong>
                        <DoneIcon />
                        {t(detailParents.childneediep)}
                      </strong>
                    </span>
                  </label>
                ) : (
                  ""
                )}
                <br />
                <label>
                  {t("Allergies")}
                  <span>
                    <strong>
                      <DoneIcon />

                      {t(detailParents.childanyallergies)}
                    </strong>
                  </span>
                </label>
                <label>
                  {t("Medical conditions")}
                  <span>
                    <strong>
                      <DoneIcon />
                      {t(detailParents.childmedicalcondition)}
                    </strong>
                  </span>
                </label>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail job_performance your_kids stap2">
                  <div className="left2 right2">
                    <div className="form_group full">
                      <label>
                        {t("children-question")}
                        <span
                          className={
                            errorField.childneedcareno != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="children_number">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              childneedcareno: "",
                            })
                          }
                        >
                          <li
                            onClick={(e) => setChildren(1)}
                            className={children == 1 ? "active" : ""}
                          >
                            1
                          </li>
                          <li
                            onClick={(e) => setChildren(2)}
                            className={children == 2 ? "active" : ""}
                          >
                            2
                          </li>
                          <li
                            onClick={(e) => setChildren("twins")}
                            className={children == "twins" ? "active" : ""}
                          >
                            {t("text-twins")}
                          </li>
                          <li
                            onClick={(e) => setChildren(3)}
                            className={children == 3 ? "active" : ""}
                          >
                            3+
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form_group full">
                      <label>
                        {t("child-age")}
                        <span
                          className={errorField.childage != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="customselect inp">
                        <div className="childinput">
                          {listSelectElement.map((e, index) => {
                            return (
                              t(e.name) +
                              (listSelectElement.length - 1 >= index + 1
                                ? "; "
                                : "")
                            );
                          })}
                        </div>

                        <div
                          className="overflow"
                          id="over"
                          onClick={(e) => handleElementByID("cate", "over")}
                        ></div>
                        <div
                          className="option"
                          id="cate"
                          onClick={(e) =>
                            setErrorField({ ...errorField, childage: "" })
                          }
                        >
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                listSelectElement.filter(
                                  (e) => e.name == t("years-0-1")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) =>
                                handleSelectElement(t("years-0-1"))
                              }
                            />
                            <h3>{t("years-0-1")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                listSelectElement.filter(
                                  (e) => e.name == t("years-1-3")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) =>
                                handleSelectElement(t("years-1-3"))
                              }
                            />
                            <h3>{t("years-1-3")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                listSelectElement.filter(
                                  (e) => e.name == t("years-4-7")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) =>
                                handleSelectElement(t("years-4-7"))
                              }
                            />
                            <h3>{t("years-4-7")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                listSelectElement.filter(
                                  (e) => e.name == t("years-8-10")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) =>
                                handleSelectElement(t("years-8-10"))
                              }
                            />
                            <h3>{t("years-8-10")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                listSelectElement.filter(
                                  (e) => e.name == t("years-11-15")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) =>
                                handleSelectElement(t("years-11-15"))
                              }
                            />
                            <h3>{t("years-11-15")} </h3>
                            <span></span>
                          </p>
                          <p>
                            <input
                              type="checkbox"
                              checked={
                                listSelectElement.filter(
                                  (e) => e.name == t("years-16")
                                )[0]
                                  ? true
                                  : false
                              }
                              onClick={(a) =>
                                handleSelectElement(t("years-16"))
                              }
                            />
                            <h3>{t("years-16")} </h3>
                            <span></span>
                          </p>
                        </div>
                        <span
                          onClick={(e) => handleElementByID("cate", "over")}
                        >
                          <ArrowUpIcon />
                        </span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                  <div className="right2" style={{ marginBottom: "10px" }}>
                    <div className="form_group full">
                      <label>
                        {t("child-question")}
                        <span
                          className={
                            errorField.childsocialneed != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              childsocialneed: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name=""
                              onClick={(e) =>
                                setDetailParents({
                                  ...detailParents,
                                  childsocialneed: "Yes",
                                })
                              }
                              checked={
                                detailParents.childsocialneed == "Yes"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name=""
                              onClick={(e) =>
                                setDetailParents({
                                  ...detailParents,
                                  childsocialneed: "No",
                                })
                              }
                              checked={
                                detailParents.childsocialneed == "No"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {detailParents.childsocialneed == "Yes" ? (
                      <div className="form_group full">
                        <label>
                          {t("select-applicable")}
                          <span
                            className={
                              errorField.yourkidallapplicable != ""
                                ? "starred"
                                : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="customselect inp">
                          <input
                            className="keyword"
                            type="text"
                            placeholder={t("choose-list")}
                            value={childrenAge.map((e) => {
                              return e.name;
                            })}
                          />
                          <div
                            className="overflow"
                            id="over21"
                            onClick={(e) =>
                              handleElementByID("cate2", "over21")
                            }
                          ></div>

                          <div
                            className="option"
                            id="cate2"
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                yourkidallapplicable: "",
                              })
                            }
                          >
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter((e) => e.name == "ADHD")[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("text-adhd"))
                                }
                              />
                              <h3>{t("text-adhd")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Auditory Impairment"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("auditory-impairment"))
                                }
                              />
                              <h3>{t("auditory-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Autism"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("autism"))
                                }
                              />
                              <h3>{t("autism")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Cerebral palsy"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("cerebral-palsy"))
                                }
                              />
                              <h3>{t("cerebral-palsy")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Down syndrome"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("down-syndrome"))
                                }
                              />
                              <h3>{t("down-syndrome")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Dyslexia"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("dyslexia"))
                                }
                              />
                              <h3>{t("dyslexia")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) =>
                                      e.name == "Mild intellectual disability"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(
                                    t("intellectual-disability")
                                  )
                                }
                              />
                              <h3>{t("intellectual-disability")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) =>
                                      e.name ==
                                      "Moderate/Severe intellectual disability"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("moderate-disability"))
                                }
                              />
                              <h3>{t("moderate-disability")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Orthopedic impairment"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(
                                    t("orthopedic-impairment")
                                  )
                                }
                              />
                              <h3>{t("orthopedic-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) =>
                                      e.name == "Speech learning impairment"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("learn-impairment"))
                                }
                              />
                              <h3>{t("learn-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) =>
                                      e.name == "Specific learning disabilities"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("learn-disabilities"))
                                }
                              />
                              <h3>{t("learn-disabilities")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Visual impairment"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("visual-impairment"))
                                }
                              />
                              <h3>{t("visual-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  childrenAge.filter(
                                    (e) => e.name == "Other"
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleDeleteElement(t("text-other"))
                                }
                              />
                              <h3>{t("text-other")} </h3>
                              <span></span>
                            </p>
                          </div>

                          <span
                            onClick={(e) =>
                              handleElementByID("cate2", "over21")
                            }
                          >
                            <ArrowUpIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {detail.service_type &&
                    detail.service_type.tab2 &&
                    window.innerWidth < 767 ? (
                      <div class="form_group full">
                        <label>
                          {t("text-child-need")} <a>{t("text-iep")} </a>
                          <span class="smallpop">
                            {t("individual-education")}
                          </span>
                          ?
                          <span
                            className={
                              errorField.childneediep != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorField({ ...errorField, childneediep: "" })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    childneediep: "Yes",
                                  })
                                }
                                checked={
                                  detailParents.childneediep == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    childneediep: "No",
                                  })
                                }
                                checked={
                                  detailParents.childneediep == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form_group full">
                      <label>
                        {t("allergies-question")}
                        <span
                          className={
                            errorField.childanyallergies != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              childanyallergies: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="b2"
                              onClick={(e) =>
                                setDetailParents({
                                  ...detailParents,
                                  childanyallergies: "Yes",
                                })
                              }
                              checked={
                                detailParents.childanyallergies == "Yes"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="b2"
                              onClick={(e) =>
                                setDetailParents({
                                  ...detailParents,
                                  childanyallergies: "No",
                                })
                              }
                              checked={
                                detailParents.childanyallergies == "No"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                        {detailParents.childanyallergies == "Yes" ? (
                          <>
                            <textarea
                              rows="2"
                              placeholder={t("short-description")}
                              maxlength="300"
                              name="message"
                              onChange={(e) => {
                                setErrorField({
                                  ...errorField,
                                  childallergiesdescription: "",
                                });
                                setDetailParents({
                                  ...detailParents,
                                  childallergiesdescription: e.target.value,
                                });
                              }}
                              defaultValue={
                                detailParents.childallergiesdescription
                              }
                              className={
                                errorField.childallergiesdescription != ""
                                  ? "bordererror"
                                  : ""
                              }
                            ></textarea>
                            <span>
                              {t("number-characters")}
                              {300 -
                                detailParents.childallergiesdescription.length}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                  <div className="left2 right2">
                    {detail.service_type &&
                    detail.service_type.tab2 &&
                    window.innerWidth > 767 ? (
                      <div class="form_group full">
                        <label>
                          {t("text-child-need")} <a>{t("text-iep")} </a>
                          <span class="smallpop">
                            {t("individual-education")}
                          </span>
                          ?
                          <span
                            className={
                              errorField.childneediep != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorField({ ...errorField, childneediep: "" })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    childneediep: "Yes",
                                  })
                                }
                                checked={
                                  detailParents.childneediep == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    childneediep: "No",
                                  })
                                }
                                checked={
                                  detailParents.childneediep == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form_group full">
                      <label>
                        {t("medical-conditions")}
                        <span
                          className={
                            errorField.childmedicalcondition != ""
                              ? "starred"
                              : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              childmedicalcondition: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="c2"
                              onClick={(e) =>
                                setDetailParents({
                                  ...detailParents,
                                  childmedicalcondition: "Yes",
                                })
                              }
                              checked={
                                detailParents.childmedicalcondition == "Yes"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="c2"
                              onClick={(e) =>
                                setDetailParents({
                                  ...detailParents,
                                  childmedicalcondition: "No",
                                })
                              }
                              checked={
                                detailParents.childmedicalcondition == "No"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                        {detailParents.childmedicalcondition == "Yes" ? (
                          <>
                            <textarea
                              rows="2"
                              placeholder={t("short-description")}
                              maxlength="300"
                              name="message"
                              onChange={(e) => {
                                setErrorField({
                                  ...errorField,
                                  childmedicaldescription: "",
                                });
                                setDetailParents({
                                  ...detailParents,
                                  childmedicaldescription: e.target.value,
                                });
                              }}
                              defaultValue={
                                detailParents.childmedicaldescription
                              }
                              className={
                                errorField.childmedicaldescription != ""
                                  ? "bordererror"
                                  : ""
                              }
                            ></textarea>
                            <span>
                              {t("number-characters")}
                              {300 -
                                detailParents.childmedicaldescription.length}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={generalInfo.job == "active" ? "active personal" : "personal"}
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              job: generalInfo.job == "" ? "active" : "",
            })
          }
        >
          {t("job-preferences")}
        </h3>
        {generalInfo.job == "active" ? (
          <div className="editkids editabout">
            {editGeneralInfo.job == "" ? (
              <>
                <button
                  onClick={(e) =>
                    setEditGeneralInfo({ ...editGeneralInfo, job: "edit" })
                  }
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>
                {detail.service_type && detail.service_type.tab1 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img
                        src={window.location.origin + "/images/nany_pur.svg"}
                      />
                      {t("text-nanny")}
                    </h2>
                    <label>
                      {t("Live in nanny")}
                      <span>
                        <strong>
                          <DoneIcon />
                          {t(detailParents.liveinnany)}
                        </strong>
                      </span>
                    </label>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>$ {nannyRate.min + " - " + nannyRate.max}</b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab2 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img
                        src={
                          window.location.origin +
                          "/images/special_education.svg"
                        }
                      />
                      {t("education-teacher")}
                    </h2>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>$ {generalRate.min + " - " + generalRate.max}</b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab3 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img
                        src={
                          window.location.origin + "/images/professional.svg"
                        }
                      />
                      {t("education-paraprofessional")}
                    </h2>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>
                            $ {secondaryRate.min + " - " + secondaryRate.max}
                          </b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img src={window.location.origin + "/images/tuter.svg"} />
                      {t("text-tutor")}
                    </h2>
                    <h4>{t("We need a tutor for")}</h4>
                    <label>
                      {qualifications.English &&
                      qualifications.English != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("english")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Serbian &&
                      qualifications.Serbian != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("serbian")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Mathematics &&
                      qualifications.Mathematics != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("mathematics")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Physics &&
                      qualifications.Physics != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("physics")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Chemistry &&
                      qualifications.Chemistry != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("chemistry")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Other && qualifications.Other != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("text-other")}: {qualifications.Other}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <br />
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>${tutorRate.min + " - " + tutorRate.max}</b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type &&
                Object.keys(detail.service_type).length > 0 ? (
                  <div className="gernaledt">
                    <h2 class="border ">{t("general-info")}</h2>
                    <div className="left2">
                      <label>
                        {t("work-experience-preffer")}
                        <span>
                          <strong>
                            <b>{t(detailParents.tutorexp)}</b>
                          </strong>
                        </span>
                      </label>
                      <label>
                        {t("age-provider-preffer")}
                        <span>
                          <strong>
                            <b>{t(detailParents.preferredageofprovider)}</b>
                          </strong>
                        </span>
                      </label>
                    </div>
                    <div className="right2">
                      <label>
                        {t("start-date")}
                        <span>
                          <strong>
                            <b>{detailParents.tutorstartdate}</b>
                          </strong>
                        </span>
                      </label>
                      <label>
                        {t("text-frequency")}
                        <span>
                          <strong>
                            <b>{t(detailParents.tutorintrestedin)}</b>
                          </strong>
                        </span>
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="Profile_complete charactor">
                <div className="detail preferences   work-experience job_performance setp3">
                  {detail.service_type && detail.service_type.tab1 ? (
                    <div className="nanny">
                      <h2 className="border">
                        <img
                          src={window.location.origin + "/images/nany_pur.svg"}
                        />
                        {t("text-nanny")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                setIsShowModal(true);
                                setIsDisable(1);
                              }}
                              checked={
                                Object.keys(inActiveProfessional).filter(
                                  (e) => e == "tab1"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("nanny-question")}
                          <span
                            className={
                              errorField.liveinnany != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox">
                          <ul
                            onClick={(e) =>
                              setErrorField({ ...errorField, liveinnany: "" })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="quality"
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    liveinnany: "Yes",
                                  })
                                }
                                checked={
                                  detailParents.liveinnany == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="quality"
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    liveinnany: "No",
                                  })
                                }
                                checked={
                                  detailParents.liveinnany == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={nannyRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (nannyRate.max > 0 ? nannyRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  nannyRate.max == 0
                                    ? 50 / 10
                                    : nannyRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    nannyRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={nannyRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (nannyRate.max > 0 ? nannyRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  nannyRate.max == 0
                                    ? 50 / 10
                                    : nannyRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    nannyRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? nannyRate.min * 100 + " - " + nannyRate.max * 100
                            : nannyRate.min + " - " + nannyRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {detail.service_type && detail.service_type.tab2 ? (
                    <div className="special_education big">
                      <h2 className="border">
                        <img
                          src={
                            window.location.origin +
                            "/images/special_education.svg"
                          }
                        />
                        {t("education-teacher")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                if (e.target.value) {
                                  setIsShowModal(true);
                                  setIsDisable(2);
                                }
                              }}
                              checked={
                                Object.keys(inActiveProfessional).filter(
                                  (e) => e == "tab2"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={generalRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (generalRate.max > 0
                                      ? generalRate.max
                                      : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setGeneralRate({
                                      ...generalRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  generalRate.max == 0
                                    ? 50 / 10
                                    : generalRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    generalRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setGeneralRate({
                                      ...generalRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={generalRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (generalRate.max > 0
                                      ? generalRate.max
                                      : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setGeneralRate({
                                      ...generalRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  generalRate.max == 0
                                    ? 50 / 10
                                    : generalRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    generalRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setGeneralRate({
                                      ...generalRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? generalRate.min * 100 +
                              " - " +
                              generalRate.max * 100
                            : generalRate.min + " - " + generalRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab3 ? (
                    <div className="special_education big">
                      <h2 className="border">
                        <img
                          src={
                            window.location.origin + "/images/professional.svg"
                          }
                        />
                        {t("education-paraprofessional")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                if (e.target.value) {
                                  setIsShowModal(true);
                                  setIsDisable(3);
                                }
                              }}
                              checked={
                                Object.keys(inActiveProfessional).filter(
                                  (e) => e == "tab3"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      secondaryRate.min >= value ||
                                      (secondaryRate.max < value && secondaryRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      secondaryRate.min >= value ||
                                      (secondaryRate.max < value && secondaryRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={secondaryRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (secondaryRate.max > 0
                                      ? secondaryRate.max
                                      : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setSecondaryRate({
                                      ...secondaryRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  secondaryRate.max == 0
                                    ? 50 / 10
                                    : secondaryRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    secondaryRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setSecondaryRate({
                                      ...secondaryRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      secondaryRate.min >= value ||
                                      (secondaryRate.max < value && secondaryRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      secondaryRate.min >= value ||
                                      (secondaryRate.max < value && secondaryRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={secondaryRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (secondaryRate.max > 0
                                      ? secondaryRate.max
                                      : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setSecondaryRate({
                                      ...secondaryRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  secondaryRate.max == 0
                                    ? 50 / 10
                                    : secondaryRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    secondaryRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setSecondaryRate({
                                      ...secondaryRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? secondaryRate.min * 100 +
                              " - " +
                              secondaryRate.max * 100
                            : secondaryRate.min + " - " + secondaryRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab4 ? (
                    <div className="tutor parents">
                      <h2 className="border">
                        <img
                          src={window.location.origin + "/images/tuter.svg"}
                        />
                        Tutor
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                setIsShowModal(true);
                                setIsDisable(4);
                              }}
                              checked={
                                Object.keys(inActiveProfessional).filter(
                                  (e) => e == "tab4"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="form_group qualification">
                        <label>
                          {t("classes-question")}
                          <span
                            className={
                              errorField.tutorliketoteach != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox">
                          <ul
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                tutorliketoteach: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.English == "English"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      English: "English",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      English: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("english")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Serbian == "Serbian"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Serbian: "Serbian",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Serbian: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("serbian")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Mathematics == "Mathematics"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Mathematics: "Mathematics",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Mathematics: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("mathematics")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Physics == "Physics"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Physics: "Physics",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Physics: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("physics")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Chemistry == "Chemistry"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Chemistry: "Chemistry",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Chemistry: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("chemistry")}</span>
                            </li>
                            <li className="aline">
                              <input
                                type="checkbox"
                                name=""
                                checked={qualifications.Other ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Other: "",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Other: "",
                                    });
                                  }
                                }}
                              />
                              <span>
                                {t("text-other")}
                                <input
                                  type="text"
                                  placeholder={t("text-type")}
                                  onChange={(e) =>
                                    setQualifications({
                                      ...qualifications,
                                      Other: e.target.value,
                                    })
                                  }
                                  value={qualifications.Other}
                                />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("online-services")}
                          <span
                            className={
                              errorField.tutorintrestedonlinecls != ""
                                ? "starred"
                                : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                tutorintrestedonlinecls: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="a1"
                                checked={
                                  detailParents.tutorintrestedonlinecls == "Yes"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    tutorintrestedonlinecls: "Yes",
                                  })
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="a1"
                                checked={
                                  detailParents.tutorintrestedonlinecls == "No"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    tutorintrestedonlinecls: "No",
                                  })
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={tutorRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (tutorRate.max > 0 ? tutorRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  tutorRate.max == 0
                                    ? 50 / 10
                                    : tutorRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    tutorRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={tutorRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (tutorRate.max > 0 ? tutorRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  tutorRate.max == 0
                                    ? 50 / 10
                                    : tutorRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    tutorRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? tutorRate.min * 100 + " - " + tutorRate.max * 100
                            : tutorRate.min + " - " + tutorRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="noteedit">
                    <MarkRoundIcon color={"#7D2B8B"} />

                    {t("note-close-post")}
                  </div>
                  {detail.service_type &&
                  Object.keys(detail.service_type).length > 0 ? (
                    <div className="general_info tutor  preferred_school_jobs parents2 gernaledt">
                      <h2 className="border">{t("general-info")}</h2>
                      <div className="left2">
                        <div className="form_group full">
                          <label>
                            {t("work-experience-preffer")}
                            <span
                              className={
                                errorField.tutorexp != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div class="select">
                            <select
                              value={
                                detailParents.tutorexp != ""
                                  ? detailParents.tutorexp
                                  : t("choose-list")
                              }
                              onChange={(e) => {
                                setErrorField({ ...errorField, tutorexp: "" });
                                setDetailParents({
                                  ...detailParents,
                                  tutorexp: e.target.value,
                                });
                              }}
                            >
                              <option disabled={true}>
                                {t("choose-list")}
                              </option>
                              <option value={t("years-0-1")}>
                                {t("years-0-1")}
                              </option>
                              <option value={t("1 - 2 years")}>
                                {t("years-1-2")}
                              </option>
                              <option value={t("years-2-4")}>
                                {t("years-2-4")}
                              </option>
                              <option value={t("years-4-more")}>
                                {t("years-4-more")}
                              </option>
                            </select>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>

                        <div className="form_group full">
                          <label>
                            {t("age-provider-preffer")}
                            <span
                              className={
                                errorField.preferredageofprovider != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div class="select">
                            <select
                              value={
                                detailParents.preferredageofprovider != ""
                                  ? detailParents.preferredageofprovider
                                  : "Choose from the list"
                              }
                              onChange={(e) => {
                                setErrorField({
                                  ...errorField,
                                  preferredageofprovider: "",
                                });
                                setDetailParents({
                                  ...detailParents,
                                  preferredageofprovider: e.target.value,
                                });
                              }}
                            >
                              {/* detailparents.preferredageofprovider ? detailparents.preferredageofprovider :*/}
                              <option disabled={true}>
                                {t("choose-list")}
                              </option>
                              <option value={"18 - 23 years"}>
                                {t("years-18-23")}
                              </option>
                              <option value={"24 - 30 years"}>
                                {t("years-24-30")}
                              </option>
                              <option value={"31 - 40 years"}>
                                {t("years-31-40")}
                              </option>
                              <option value={"Older than 40 years"}>
                                {t("years-40")}
                              </option>
                              <option value={"Any"}>{t("text-any")}</option>
                            </select>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      </div>
                      <div className="right2">
                        <div className="form_group full">
                          <label>
                            {t("start-date")}
                            <span
                              className={
                                errorField.tutorstartdate != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="date_cal">
                            <span>
                              <DateIcon />
                            </span>

                            <DatePicker
                              className={
                                errorField.tutorstartdate != ""
                                  ? "bordererror"
                                  : ""
                              }
                              minDate={today}
                              selected={
                                detailParents.tutorstartdate != null &&
                                detailParents.tutorstartdate != ""
                                  ? new Date(detailParents.tutorstartdate)
                                  : today
                              }
                              onChange={(date) => {
                                setErrorField({
                                  ...errorField,
                                  tutorstartdate: "",
                                });
                                setDetailParents({
                                  ...detailParents,
                                  tutorstartdate:
                                    date.getFullYear() +
                                    "-" +
                                    String(date.getMonth() + 1).padStart(
                                      2,
                                      "0"
                                    ) +
                                    "-" +
                                    String(date.getDate()).padStart(2, "0"),
                                });
                              }}
                            />
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                        <div className="form_group full">
                          <label>
                            {t("Expected Frequency")}
                            <span
                              className={
                                errorField.tutorintrestedin != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <br />
                          <br />
                          <div className="checkbox create">
                            <ul
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  tutorintrestedin: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="a"
                                  checked={
                                    detailParents.tutorintrestedin ==
                                    "Full time"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailParents({
                                      ...detailParents,
                                      tutorintrestedin: "Full time",
                                    })
                                  }
                                />
                                <span> {t("full-time")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="a"
                                  checked={
                                    detailParents.tutorintrestedin ==
                                    "Part time"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailParents({
                                      ...detailParents,
                                      tutorintrestedin: "Part time",
                                    })
                                  }
                                />
                                <span> {t("part-time")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="a"
                                  checked={
                                    detailParents.tutorintrestedin ==
                                    "Occasionally"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    setDetailParents({
                                      ...detailParents,
                                      tutorintrestedin: "Occasionally",
                                    })
                                  }
                                />
                                <span> {t("occasionally")}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className={
          generalInfo.availability == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              availability: generalInfo.availability == "" ? "active" : "",
            })
          }
        >
          {t("availability")}
        </h3>
        {generalInfo.availability == "active" ? (
          <div className="calendershow">
            {editGeneralInfo.availability == "" ? (
              <>
                <button
                  onClick={(e) =>
                    setEditGeneralInfo({
                      ...editGeneralInfo,
                      availability: "edit",
                    })
                  }
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <div className="calander">
                  <div className="form_group full">
                    <label>{t("Availability and working hours")}</label>
                  </div>
                  <div className="legend">
                    <ul>
                      {detailParents.calanderlastupdate != null &&
                      parseInt(detailParents.calanderlastupdate) == 1 ? (
                        <li
                          className={
                            detailParents.calanderlastupdate != null &&
                            parseInt(detailParents.calanderlastupdate) == 1
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("full-time")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detailParents.calanderlastupdate != null &&
                      parseInt(detailParents.calanderlastupdate) == 2 ? (
                        <li
                          className={
                            detailParents.calanderlastupdate != null &&
                            parseInt(detailParents.calanderlastupdate) == 2
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("before-school")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detailParents.calanderlastupdate != null &&
                      parseInt(detailParents.calanderlastupdate) == 5 ? (
                        <li
                          className={
                            detailParents.calanderlastupdate != null &&
                            parseInt(detailParents.calanderlastupdate) == 5
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("text-weekends")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detailParents.calanderlastupdate != null &&
                      parseInt(detailParents.calanderlastupdate) == 3 ? (
                        <li
                          className={
                            detailParents.calanderlastupdate != null &&
                            parseInt(detailParents.calanderlastupdate) == 3
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("after-school")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detailParents.calanderlastupdate != null &&
                      parseInt(detailParents.calanderlastupdate) == 4 ? (
                        <li
                          className={
                            detailParents.calanderlastupdate != null &&
                            parseInt(detailParents.calanderlastupdate) == 4
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("text-overnight")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <div className="form_group full">
                      <label className="border">{t("text-legend")}</label>
                      <ul>
                        <li>
                          <strong>{t("full-time")}</strong>
                          <br />
                          {t("mon-fri-9-6")}
                        </li>
                        <li>
                          <strong>{t("before-school")}</strong>
                          <br />
                          {t("mon-fri-6-9")}
                        </li>
                        <li>
                          <strong>{t("after-school")}</strong>
                          <br />
                          {t("mon-fri-3-9")}
                        </li>
                        <li>
                          <strong>{t("text-overnight")}</strong>
                          <br />
                          {t("mon-fri-9-6-am")}
                        </li>
                        <li>
                          <strong>{t("text-weekends")}</strong>
                          <br />
                          {t("saturday-sunday")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="calanderfull">
                    {calendarType == 1 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailParents.fulltime}
                        data2={"fulltime"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 2 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailParents.beforeschool}
                        data2={"beforeschool"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 3 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailParents.afterschool}
                        data2={"afterschool"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 4 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailParents.overnight}
                        data2={"overnight"}
                      />
                    ) : (
                      ""
                    )}
                    {calendarType == 5 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailParents.weekends}
                        data2={"weekends"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail availability setp5">
                  <div className="form_group full">
                    <label>{t("availability-working")}</label>
                    <p>{t("date-time-post")}</p>
                  </div>
                  <div className="form_group full">
                    <label>{t("auto-calendar")}</label>
                    <ul>
                      <li
                        onClick={(e) => setCalendarType(1)}
                        className={calendarType == 1 ? "active" : ""}
                      >
                        <span
                          className={errorField.fulltime != "" ? "starred" : ""}
                        >
                          {t("full-time")}
                        </span>
                      </li>
                      <li
                        onClick={(e) => setCalendarType(2)}
                        className={calendarType == 2 ? "active" : ""}
                      >
                        <span
                          className={
                            errorField.beforeschool != "" ? "starred" : ""
                          }
                        >
                          {t("before-school")}
                        </span>
                      </li>
                      <li
                        onClick={(e) => setCalendarType(5)}
                        className={calendarType == 5 ? "active" : ""}
                      >
                        <span
                          className={
                            errorField.overnight != "" ? "starred" : ""
                          }
                        >
                          {t("text-weekends")}
                        </span>
                      </li>
                      <li
                        onClick={(e) => setCalendarType(3)}
                        className={calendarType == 3 ? "active" : ""}
                      >
                        <span
                          className={
                            errorField.afterschool != "" ? "starred" : ""
                          }
                        >
                          {t("after-school")}
                        </span>
                      </li>
                      <li
                        onClick={(e) => setCalendarType(4)}
                        className={calendarType == 4 ? "active" : ""}
                      >
                        <span
                          className={
                            errorField.overnight != "" ? "starred" : ""
                          }
                        >
                          {t("text-overnight")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="calander">
                    <div className="form_group full">
                      <label>{t("select-days")}</label>
                    </div>
                    <div className="calanderfull">
                      {calendarType == 1 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailParents.fulltime}
                          data2={"fulltime"}
                        />
                      ) : (
                        ""
                      )}
                      {calendarType == 2 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailParents.beforeschool}
                          data2={"beforeschool"}
                        />
                      ) : (
                        ""
                      )}
                      {calendarType == 3 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailParents.afterschool}
                          data2={"afterschool"}
                        />
                      ) : (
                        ""
                      )}
                      {calendarType == 4 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailParents.overnight}
                          data2={"overnight"}
                        />
                      ) : (
                        ""
                      )}
                      {calendarType == 5 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailParents.weekends}
                          data2={"weekends"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="legend">
                      <div className="form_group full">
                        <label className="border">{t("text-legend")}</label>
                        <ul>
                          <li>
                            <strong>{t("full-time")}</strong>
                            <br />
                            {t("mon-fri-9-6")}
                          </li>
                          <li>
                            <strong>{t("before-school")}</strong>
                            <br />
                            {t("mon-fri-6-9")}
                          </li>
                          <li>
                            <strong>{t("after-school")}</strong>
                            <br />
                            {t("mon-fri-3-9")}
                          </li>
                          <li>
                            <strong>{t("text-overnight")}</strong>
                            <br />
                            {t("mon-fri-9-6-am")}
                          </li>
                          <li>
                            <strong>{t("text-weekends")}</strong>
                            <br />
                            {t("saturday-sunday")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.info == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              info: generalInfo.info == "" ? "active" : "",
            })
          }
        >
          {t("additional-info")}
        </h3>
        {generalInfo.info == "active" ? (
          <div className="editkids editabout editinfo">
            {editGeneralInfo.info == "" ? (
              <>
                <button
                  onClick={(e) =>
                    setEditGeneralInfo({ ...editGeneralInfo, info: "edit" })
                  }
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>
                <div className="nannyediy">
                  <h4>{t("Preferred foreign language1")}</h4>

                  <label>
                    {oralSpeak.English && oralSpeak.English != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {t("english")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                    {oralSpeak.Spanish && oralSpeak.Spanish != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {t("spanish")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                    {oralSpeak.French && oralSpeak.French != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {t("french")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                    {oralSpeak.Chinese && oralSpeak.Chinese != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {t("chinese")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                    {oralSpeak.German && oralSpeak.German != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {t("german")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                    {oralSpeak.Italian && oralSpeak.Italian != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {t("italian")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                    {oralSpeak.Other && oralSpeak.Other != "" ? (
                      <span>
                        <strong>
                          <DoneIcon />
                          {/* {t("text-other")} */}
                          {oralSpeak.Other}
                          {console.log(oralSpeak.Other, "oral speak")}
                        </strong>
                      </span>
                    ) : (
                      ""
                    )}
                  </label>

                  <br />
                  {detail.service_type && detail.service_type.tab1 ? (
                    <div className="icon">
                      <ul>
                        <li>
                          {t("child-transportation")}
                          <div className="icons">
                            {habit.licence == "false" ? (
                              <BanCarIcon
                                color={"#7D2B8B"}
                                colorCircle={"#7D2B8B"}
                                colorPath={"#fff"}
                              />
                            ) : (
                              <BanCarIcon
                                color={"#7D2B8B"}
                                colorCircle={"#7D2B8B"}
                                colorPath={"#fff"}
                              />
                            )}
                          </div>
                        </li>
                        <li>
                          {t("occasional-traveling")}
                          <div className="icons">
                            {habit.family == "false" ? (
                              <BanPlaneIcon
                                color={"#7D2B8B"}
                                colorCircle={"#7D2B8B"}
                                colorPath={"#fff"}
                              />
                            ) : (
                              <PlaneIcon
                                color={"#7D2B8B"}
                                colorCircle={"#7D2B8B"}
                                colorPath={"#fff"}
                              />
                            )}
                          </div>
                        </li>
                        <li>
                          {t("light-housework")}
                          <div className="icons">
                            {habit.housework == "false" ? (
                              <BanHouseIcon
                                color={"#7D2B8B"}
                                colorPath={"#fff"}
                                colorCircle={"#7D2B8B"}
                              />
                            ) : (
                              <HouseIcon
                                color={"#7D2B8B"}
                                colorPath={"#fff"}
                                colorCircle={"#7D2B8B"}
                              />
                            )}
                          </div>
                        </li>
                        <li>
                          {t("cooking-kids")}
                          <div className="icons">
                            {habit.kids == "false" ? (
                              <BanCookIcon
                                color={"#7D2B8B"}
                                colorCircle={"#7D2B8B"}
                                colorPath={"#fff"}
                              />
                            ) : (
                              <CookIcon
                                color={"#7D2B8B"}
                                colorCircle={"#7D2B8B"}
                                colorPath={"#fff"}
                              />
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  <div className="abrod">
                    <label className="abrodd">
                      <ThingIcon />
                      {t("Need a service abroad")}
                      <span>{t(detailParents.workingabroad)}</span>
                    </label>
                    <br />
                    {detailParents.workingabroad == "Yes" ? (
                      <>
                        <label>
                          <span className="half">{t("preffer-country")}</span>
                          <span>
                            <strong>{detailParents.preferredcountry}</strong>
                          </span>
                        </label>
                        <label>
                          <span className="half">{t("preffer-city")}</span>
                          <span>
                            <strong>{detailParents.preferredcity}</strong>
                          </span>
                        </label>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail  additional_info info_parents setp6">
                  <div className="form_group border qualification">
                    <label>
                      {t("Preferred foreign language")}
                      <span
                        className={
                          errorField.candidatespeaks == "" ? "" : "starred"
                        }
                      >
                        *
                      </span>
                    </label>
                    <div className="checkbox create setredio vertical">
                      <ul
                        onClick={(e) =>
                          setErrorField({ ...errorField, candidatespeaks: "" })
                        }
                      >
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            checked={
                              oralSpeak.English == "English" ? true : false
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setOralSpeak({
                                  ...oralSpeak,
                                  English: "English",
                                });
                              } else {
                                setOralSpeak({ ...oralSpeak, English: "" });
                              }
                            }}
                          />
                          <span> {t("english")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            checked={
                              oralSpeak.Spanish == "Spanish" ? true : false
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setOralSpeak({
                                  ...oralSpeak,
                                  Spanish: "Spanish",
                                });
                              } else {
                                setOralSpeak({ ...oralSpeak, Spanish: "" });
                              }
                            }}
                          />
                          <span> {t("spanish")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            checked={
                              oralSpeak.French == "French" ? true : false
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setOralSpeak({
                                  ...oralSpeak,
                                  French: "French",
                                });
                              } else {
                                setOralSpeak({ ...oralSpeak, French: "" });
                              }
                            }}
                          />
                          <span> {t("french")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            checked={
                              oralSpeak.Chinese == "Chinese" ? true : false
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setOralSpeak({
                                  ...oralSpeak,
                                  Chinese: "Chinese",
                                });
                              } else {
                                setOralSpeak({ ...oralSpeak, Chinese: "" });
                              }
                            }}
                          />
                          <span> {t("chinese")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            checked={
                              oralSpeak.German == "German" ? true : false
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setOralSpeak({
                                  ...oralSpeak,
                                  German: "German",
                                });
                              } else {
                                setOralSpeak({ ...oralSpeak, German: "" });
                              }
                            }}
                          />
                          <span> {t("german")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            checked={
                              oralSpeak.Italian == "Italian" ? true : false
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setOralSpeak({
                                  ...oralSpeak,
                                  Italian: "Italian",
                                });
                              } else {
                                setOralSpeak({ ...oralSpeak, Italian: "" });
                              }
                            }}
                          />
                          <span> {t("italian")}</span>
                        </li>
                        <li>
                          <input type="checkbox" name="" />
                          <span>
                            {t("text-other")}
                            <input
                              type="text"
                              placeholder={t("text-type")}
                              defaultValue={oralSpeak.Other}
                              onChange={(e) => {
                                setOralSpeak((prevOralSpeak) => ({
                                  ...prevOralSpeak,
                                  Other: e.target.value,
                                }));
                              }}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="iconsec">
                    <div className="left2">
                      {detail.service_type && detail.service_type.tab1 ? (
                        <div className="icon border">
                          <div
                            className="form_group"
                            style={{ marginBottom: "0" }}
                          >
                            <label>
                              {t("text-i-need")}
                              <span>*</span>
                            </label>
                            <br />
                          </div>
                          <ul>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  childtransportation: "",
                                })
                              }
                              className={
                                errorField.childtransportation != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              {t("child-transportation")}
                              <div className="icons">
                                7
                                <BanCarIcon
                                  className={
                                    habit.licence == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, licence: "false" })
                                  }
                                />
                                <CarIcon
                                  className={
                                    habit.licence == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, licence: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  occasionaltraveling: "",
                                })
                              }
                              className={
                                errorField.occasionaltraveling != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              {t("occasional-traveling")}
                              <div className="icons">
                                <BanPlaneIcon
                                  className={
                                    habit.family == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, family: "false" })
                                  }
                                />

                                <PlaneIcon
                                  className={
                                    habit.family == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, family: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  lighthousework: "",
                                })
                              }
                              className={
                                errorField.lighthousework != "" ? "starred" : ""
                              }
                            >
                              {t("light-housework")}
                              <div className="icons">
                                <BanHouseIcon
                                  className={
                                    habit.housework == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, housework: "false" })
                                  }
                                />

                                <HouseIcon
                                  className={
                                    habit.housework == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, housework: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  cookingforkids: "",
                                })
                              }
                              className={
                                errorField.cookingforkids != "" ? "starred" : ""
                              }
                            >
                              {t("cooking-kids")}
                              <div className="icons">
                                <BanCookIcon
                                  className={
                                    habit.kids == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, kids: "false" })
                                  }
                                />
                                <CookIcon
                                  className={
                                    habit.kids == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setHabit({ ...habit, kids: "true" })
                                  }
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className="form_group full"
                        style={{ marginTop: "20px" }}
                      >
                        <label>
                          {t("services-abroad-question")}
                          <span
                            className={
                              errorField.workingabroad != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                workingabroad: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    workingabroad: "Yes",
                                  })
                                }
                                checked={
                                  detailParents.workingabroad == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    workingabroad: "No",
                                  })
                                }
                                checked={
                                  detailParents.workingabroad == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      {detailParents.workingabroad == "Yes" ? (
                        <>
                          <div className="form_group full">
                            <label>
                              {t("preffer-country")}
                              <span
                                className={
                                  errorField.preferredcountry != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                            <div class="select">
                              <select
                                onChange={(e) => {
                                  setErrorField({
                                    ...errorField,
                                    preferredcountry: "",
                                  });
                                  setDetailParents({
                                    ...detailParents,
                                    preferredcountry: e.target.value,
                                  });
                                }}
                              >
                                <option disabled="">
                                  {detailParents.preferredcountry != ""
                                    ? detailParents.preferredcountry
                                    : t("choose-list")}
                                </option>
                                {DATA_COUNTRY.data.map((e) => {
                                  return (
                                    <option value={e.country}>
                                      {e.country}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {/* <div className='errorfield'>{error.message}</div>*/}
                          </div>

                          <div className="form_group full">
                            <label>
                              {t("preffer-city")}
                              <span
                                className={
                                  errorField.preferredcity != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                            <div class="text">
                              <input
                                type="text"
                                placeholder={t("text-type")}
                                onChange={(e) => {
                                  setErrorField({
                                    ...errorField,
                                    preferredcity: "",
                                  });
                                  setDetailParents({
                                    ...detailParents,
                                    preferredcity: e.target.value,
                                  });
                                }}
                                defaultValue={detailParents.preferredcity}
                              />
                            </div>
                            {/* <div className='errorfield'>{error.message}</div>*/}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.security == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              security: generalInfo.security == "" ? "active" : "",
            })
          }
        >
          {t("security-verification")}
        </h3>
        {generalInfo.security == "active" ? (
          <div className="editkids security">
            {editGeneralInfo.security == "" ? (
              <>
                <button
                  onClick={(e) =>
                    setEditGeneralInfo({ ...editGeneralInfo, security: "edit" })
                  }
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <label>
                  {t("Verified accounts")}
                  <span>
                    <strong>
                      <div className="social_verify">
                        <FacebookIcon
                          className={
                            detailParents.facebookverify ? "active" : ""
                          }
                          fiiColor={"#fff"}
                          color={"#B7B7B7"}
                          textColor={"#B7B7B7"}
                        />
                        <LinkedinIcon
                          className={
                            detailParents.linkdinverify ? "active" : ""
                          }
                          color={"#B7B7B7"}
                        />
                      </div>
                    </strong>
                  </span>
                </label>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail  verification  setp7 mg editkids security">
                  <div className="form_group">
                    <label>{t("verify-accounts")}</label>
                    <div className="social_verify">
                      <FacebookProvider appId="3384521628485216">
                        <LoginButton
                          scope="email"
                          onCompleted={handleResponse}
                          onError={handleErrorMessage}
                          className="facebook"
                        >
                          <span>
                            <FacebookIcon
                              className={
                                detailParents.facebookverify ? "active" : ""
                              }
                              fiiColor={"#fff"}
                              color={"#B7B7B7"}
                              textColor={"#B7B7B7"}
                            />
                          </span>
                        </LoginButton>
                      </FacebookProvider>
                      <LinkedinContent
                        handleResponse={handleResponse}
                        profile={"icon"}
                        status={detailParents.linkdinverify}
                      />
                    </div>
                  </div>
                  <div className="form_group fs">
                    <div className="verification_number ">
                      <label>
                        <b>{t("Verified phone number")} </b>
                      </label>
                      <div className="number">
                        <input
                          type="text"
                          placeholder={t("enter-number")}
                          value={
                            detail.phone != null
                              ? "+" + detail.countrycode + " - " + detail.phone
                              : ""
                          }
                          disabled
                        />

                        <button>
                          {t("Verified")} <DoneIcon />
                        </button>
                      </div>
                      <br />
                      <label>
                        {t("Enter new number for verification")}
                        <span
                          className={
                            errorField.phone != "" && verify == ""
                              ? "starred"
                              : ""
                          }
                        ></span>
                      </label>
                      <div className="number profilecretate">
                        <div
                          className="country_flag"
                          onClick={(e) => handleCodeSelect()}
                        >
                          <img
                            src={
                              contactCode.flag &&
                              `${window.location.origin}/${contactCode.flag}`
                            }
                          />
                          {contactCode.code != ""
                            ? contactCode.code
                            : "+" + detailParents.countrycode}
                        </div>
                        <ul style={isCode ? { display: "none" } : {}}>
                          {DATA_COUNTRY.data.map((e) => {
                            return (
                              <li
                                onClick={(a) => {
                                  handleCodeSelect();
                                  setContactCode({
                                    flag: e.flag,
                                    code: e.dial_code,
                                  });
                                }}
                              >
                                <img
                                  src={`${window.location.origin}/${e.flag}`}
                                  alt="flag"
                                />
                                {e.country + " " + e.dial_code}
                              </li>
                            );
                          })}
                        </ul>
                        <input
                          type="number"
                          className={
                            error.phone && error.phone.length > 2
                              ? "bordererror"
                              : ""
                          }
                          placeholder={t("enter-number")}
                          onChange={(e) => {
                            setDetailParents({
                              ...detailParents,
                              phone: e.target.value,
                            });
                            detailParents.phone.length > 7
                              ? setError({ ...error, phone: "" })
                              : setError({ ...error, phone: "error" });
                          }}
                        />

                        <button
                          onClick={(e) => handleMobileVerify()}
                          disabled={
                            detail.phone != detailParents.phone &&
                            detailParents.phone.length == 8
                              ? false
                              : true
                          }
                        >
                          {isLoad ? t("text-wait") : t("get-code")}
                        </button>
                      </div>
                      <br />
                      <label>{t("enter-code-verify")}</label>
                      <div className="number">
                        <input
                          type="number"
                          className={
                            error.otperror && error.otperror.length > 2
                              ? "bordererror"
                              : ""
                          }
                          placeholder={t("enter-code")}
                          onChange={(e) => {
                            setIsOtp(e.target.value);
                            isOtp.length > 2
                              ? setError({ ...error, otperror: "" })
                              : setError({ ...error, otperror: "error" });
                          }}
                        />
                        <button
                          onClick={(e) => handleVerifyOtp()}
                          style={
                            detail.phoneVerifiedStatus != null &&
                            detail.phoneVerifiedStatus == 1
                              ? { display: "none" }
                              : {}
                          }
                        >
                          {t("text-verify")}
                        </button>
                      </div>
                    </div>
                    <div
                      className="success"
                      id="success"
                      style={
                        detail.phoneVerifiedStatus != null &&
                        detail.phoneVerifiedStatus == 1
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <FlagIcon />

                      {t("verification-thank")}
                    </div>
                    <div
                      className="success2"
                      id="success4"
                      style={{ display: "none" }}
                    >
                      <MarkIcon />
                      {t("verification-fail")}
                    </div>
                    <div
                      className="success2"
                      id="success3"
                      style={{ display: "none" }}
                    >
                      <MarkIcon />
                      {t("verification-fail-contact")}
                      <Link to="/contact-us">{t("tech-support")}</Link>
                      {t("further-assistance")}
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.personal == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              personal: generalInfo.personal == "" ? "active" : "",
            })
          }
        >
          {t("Personal Preferences")}
        </h3>
        {generalInfo.personal == "active" ? (
          <div className="personal_preferences">
            <div className="notification_edt">
              <h6>{t("Alerts and notifications")}</h6>
              <label>{t("Send alerts for")}</label>
              <ul>
                <li>
                  {t("Candidate profiles")}
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        setDetailParents({
                          ...detailParents,
                          alertJobposts: e.target.checked ? "yes" : "no",
                        })
                      }
                      checked={
                        detailParents.alertJobposts == "yes" ||
                        detailParents.alertJobposts == ""
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="notification_edt">
              <h6>{t("Profile actions")}</h6>
              <ul style={{ gap: "10px" }}>
                <li>
                  {t("Share job post")}
                  {detailParents.plateformonsocialmedia == "" ? (
                    <label class="switchedit">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          setDetailParents({
                            ...detailParents,
                            plateformonsocialmedia: e.target.checked
                              ? "Yes"
                              : "No",
                          });
                        }}
                        checked={
                          (detail.job &&
                            detail.job.length > 0 &&
                            detail.job[0].plateformonsocialmedia == "Yes") ||
                          (detail.job &&
                            detail.job.length > 0 &&
                            detail.job[1] &&
                            detail.job[1].plateformonsocialmedia == "Yes")
                            ? true
                            : false
                        }
                      />
                      <span class="slideredit roundedit"></span>
                    </label>
                  ) : (
                    <label class="switchedit">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          setDetailParents({
                            ...detailParents,
                            plateformonsocialmedia: e.target.checked
                              ? "Yes"
                              : "No",
                          });
                        }}
                        checked={
                          detailParents.plateformonsocialmedia == "" ||
                          detailParents.plateformonsocialmedia == "Yes"
                            ? true
                            : false
                        }
                      />
                      <span class="slideredit roundedit"></span>
                    </label>
                  )}
                </li>
                <li style={{ marginLeft: "20px" }}>
                  <button target="_blank">
                    <FacebookIcon
                      width={30}
                      height={30}
                      fillColor={"#7D2B8B"}
                      textColor={"#fff"}
                      color={"#7D2B8B"}
                    />
                  </button>
                </li>
                <li>
                  <button target="_blank">
                    <LinkedinIcon
                      width={31}
                      height={30}
                      color={"#fff"}
                      fillColor={"#7D2B8B"}
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="button text-center">
          <div class="pull-right">
            <button
              class="btn"
              onClick={(e) => {
                localStorage.setItem("search", "Profile");
                window.location.reload();
              }}
            >
              {t("text-cancel")}
            </button>
          </div>
          <div class="pull-right">
            <button
              class="btn confirm"
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                error.email != ""
                  ? setError({ ...error })
                  : handleProfileUpdate();
              }}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>
      </div>

      {isShowModal ? (
        <Modal show={isShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="promocode_content younger confirmchnage">
              <Link to="" onClick={handleCloseModal}>
                +
              </Link>
              <h5>{t("Change the status of profession you’re looking for")}</h5>
              <p style={{ marginBottom: "0" }}>
                <b>{t("post-deactivate-profession-profile")}</b>
              </p>
              <p>{t("continue-question")}</p>
              <div class="button text-center">
                <div class="pull-right">
                  <button
                    class="btn confirm"
                    onClick={(e) => {
                      if (isDisable == 1) {
                        if (Object.keys(inActiveProfessional).length == 1) {
                          setInActiveProfessional({});
                          setActiveProfession(activeProfession);
                        } else {
                          setInActiveProfessional({ tab1: "Nanny" });
                          delete activeProfession["tab1"];
                          setActiveProfession({ ...activeProfession });
                          setTimeout(() => {
                            error.email != ""
                              ? setError({ ...error })
                              : handleProfileUpdate({ tab1: "Nanny" });
                          }, 2000);
                        }
                        handleCloseModal();
                      } else if (isDisable == 2) {
                        if (Object.keys(inActiveProfessional).length == 1) {
                          setInActiveProfessional({});
                          setActiveProfession(activeProfession);
                        } else {
                          setInActiveProfessional({
                            tab2: "Special Education Teacher",
                          });
                          delete activeProfession["tab2"];
                          setActiveProfession({ ...activeProfession });
                          setTimeout(() => {
                            error.email != ""
                              ? setError({ ...error })
                              : handleProfileUpdate({
                                  tab2: "Special Education Teacher",
                                });
                          }, 2000);
                        }
                        handleCloseModal();
                      } else if (isDisable == 3) {
                        if (Object.keys(inActiveProfessional).length == 1) {
                          setInActiveProfessional({});
                          setActiveProfession(activeProfession);
                        } else {
                          setInActiveProfessional({
                            tab3: "Special Education Paraprofessional",
                          });
                          delete activeProfession["tab3"];
                          setActiveProfession({ ...activeProfession });
                          setTimeout(() => {
                            error.email != ""
                              ? setError({ ...error })
                              : handleProfileUpdate({
                                  tab3: "Special Education Paraprofessional",
                                });
                          }, 2000);
                        }
                        handleCloseModal();
                      } else if (isDisable == 4) {
                        if (Object.keys(inActiveProfessional).length == 1) {
                          setInActiveProfessional({});
                          setActiveProfession(activeProfession);
                        } else {
                          setInActiveProfessional({ tab4: "Tutor" });
                          delete activeProfession["tab4"];
                          setActiveProfession({ ...activeProfession });
                          setTimeout(() => {
                            error.email != ""
                              ? setError({ ...error })
                              : handleProfileUpdate({ tab4: "Tutor" });
                          }, 2000);
                        }
                        handleCloseModal();
                      }
                    }}
                  >
                    {t("confirm")}
                  </button>
                </div>
                <div class="pull-right">
                  <button class="btn" onClick={handleCloseModal}>
                    {t("objection")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      {isPhotoUpload ? (
        <Modal show={isPhotoUpload} onHide={(e) => setIsPhotoUpload(false)}>
          <Modal.Body>
            <div className="promocode_content cancelmembership uploadphoto">
              <div className="">
                <p>
                  <strong>{t("Upload profile photos")}</strong>
                </p>
                <br />
                <ul>
                  <li>
                    {photo != "" ? (
                      <>
                        <img src={image} alt="preview image" />
                        <button onClick={(e) => setPhoto("")}>
                          <DumpIcon />
                        </button>
                      </>
                    ) : (
                      <>
                        <img
                          src={api + "/assets/images/users/" + detail.file_path}
                          alt="preview image"
                        />
                      </>
                    )}
                    <input
                      type={"file"}
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </li>
                  <li>
                    <AddIcon />
                    <button>
                      <DumpIcon />
                    </button>
                  </li>
                </ul>
                <ol>
                  <li>
                    <div className="select_photoprofile">
                      <input
                        type="radio"
                        id="photo"
                        name="photo"
                        checked="true"
                      />
                      <span>{t("Set as profile photo")}</span>
                    </div>
                  </li>
                  <li>
                    <div className="select_photoprofile">
                      <input type="radio" id="photo" name="photo" />
                      <span>{t("Set as profile photo")}</span>
                    </div>
                  </li>
                </ol>
                <br />
                <p className="giveus">{t("upload-files")}</p>

                <div class="button text-center">
                  <div class="pull-right">
                    <button class="btn" onClick={(e) => setIsPhotoUpload()}>
                      {t("text-close-up")}
                    </button>
                  </div>
                  <div class="pull-right">
                    <button
                      class="btn confirm"
                      onClick={(e) => handleParentsUpdate()}
                    >
                      {t("Upload photo")}
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}
