import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useControlsStore } from "../../store/useControlsStore";
import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { useContactJobStore } from "../../store/useContactJobStore";

import { FacebookProvider, LoginButton } from "react-facebook";


import { FacebookIcon } from "../../assets/image/FacebookIcon";
import { TwitterIcon } from "../../assets/image/TwitterIcon";
import { LinkedinIcon } from "../../assets/image/LinkedinIcon";
import { InstagramIcon } from "../../assets/image/InstagramIcon";
import { FlagIcon } from "../../assets/image/FlagIcon";
import { MarkIcon } from "../../assets/image/MarkIcon";

import { api } from "../../urls";
import { DATA_COUNTRY } from "../../utils/data";

export function StepSixContent({ updateProfile, afterLoginsField }) {
  const { t } = useTranslation();
  const [setMobile, setStep, setProcess] = useControlsStore((state) => [
    state.setMobile,
    state.setStep,
    state.setProcess,
  ]);
  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const [detailParents, setDetailParents] = useDetailParentsStore((state) => [
    state.detailParents,
    state.setDetailParents,
  ]);
  const { contactCode, fail, job, setContactCode, setFail, setJob } =
    useContactJobStore();

  const {
    detail,
    error,
    isButton,
    otpVerify,
    setError,
    setIsButton,
    setOtpVerify,
  } = useProfileStore();

  const { verify, setSetCode, setCode, setVerify } =
    useAdditionalDetailsStore();

  const handleResponse = (data) => {
    setDetailParents({ ...detailParents, facebookverify: true });
  };
  const handleError = (error) => {
    console.log({ error });
  };
  const toggleCode = () => {
    setSetCode((prevSetCode) => !prevSetCode);
  };

  const mobileverify = () => {
    if (detailParents.phone.length > 7) {
      setIsButton(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonenoforverify:
          contactCode.code != ""
            ? contactCode.code + detailParents.phone
            : "+" + detailParents.countrycode + detailParents.phone,
        phone: detailParents.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : detailParents.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/sendotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsButton(false);
        })
        .catch((error) => {
          setIsButton(false);
        });
    } else {
      setError({
        ...error,
        phone: "error",
      });
    }
  };

  const otpverify = () => {
    if (otpVerify.length > 3) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonetoken: otpVerify,
        phone: detailParents.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : detailParents.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/verifyotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setVerify(result.success ? "done" : "");
          setFail(fail + 1);
          result.success == true
            ? (document.getElementById("success").style.display = "block")
            : (document.getElementById("success").style.display = "none");
          result.message == "failed" && fail == 0
            ? (document.getElementById("success4").style.display = "block")
            : (document.getElementById("success4").style.display = "none");
          result.message == "failed" && fail == 1
            ? (document.getElementById("success3").style.display = "block")
            : (document.getElementById("success3").style.display = "none");
        })
        .catch((error) => console.log("error", error));
    } else {
      setError({
        ...error,
        otperror: "error",
      });
    }
  };

  return (
    <div className="detail  verification  setp7">
      <div className="form_group">
        <label>{t("verify-accounts")}</label>
        <div className="social_verify">
          <FacebookProvider appId="393979595255860">
            <LoginButton
              scope="email"
              onCompleted={handleResponse}
              onError={handleError}
              className="facebook"
            >
              <span>
                <FacebookIcon
                  className={detailParents.facebookverify ? "active" : ""}
                />
              </span>
            </LoginButton>
          </FacebookProvider>

          <TwitterIcon
            onClick={() => {
              if (detailParents.twitterverify) {
                setDetailParents({
                  ...detailParents,
                  twitterverify: false,
                });
              } else {
                setDetailParents({
                  ...detailParents,
                  twitterverify: true,
                });
              }
            }}
            className={detailParents.twitterverify ? "active" : ""}
          />

          <LinkedinIcon
            onClick={(e) => {
              if (detailParents.linkdinverify) {
                setDetailParents({
                  ...detailParents,
                  linkdinverify: false,
                });
              } else {
                setDetailParents({
                  ...detailParents,
                  linkdinverify: true,
                });
              }
            }}
            className={detailParents.linkdinverify ? "active" : ""}
          />

          <InstagramIcon
            onClick={(e) => {
              if (detailParents.instaverify) {
                setDetailParents({
                  ...detailParents,
                  instaverify: false,
                });
              } else {
                setDetailParents({
                  ...detailParents,
                  instaverify: true,
                });
              }
            }}
            className={detailParents.instaverify ? "active" : ""}
          />
        </div>
      </div>
      <div className="form_group fs">
        <label>
          <strong>
            {t("verify-phone-number")}
            <span
              className={
                errorField.phone != "" && verify == "" ? "starred" : ""
              }
            >
              *
            </span>
          </strong>
          {t("enter-phone")} <a>{t("verification")} </a>
          <span className="smallpop">{t("phone-verification-ask")}</span>
          {t("purposes")}
        </label>
        <div className="verification_number ">
          <div className="number profilecretate">
            <div className="country_flag" onClick={(e) => toggleCode()}>
              <img src={contactCode.flag} />
              {contactCode.code != ""
                ? contactCode.code
                : "+" + detailParents.countrycode}
            </div>
            <ul style={setCode ? { display: "none" } : {}}>
              {DATA_COUNTRY.data.map((e) => {
                return (
                  <li
                    onClick={(a) => {
                      toggleCode();
                      setContactCode({
                        flag: e.flag,
                        code: e.dial_code,
                      });
                    }}
                  >
                    <img src={e.flag} /> {e.country + " " + " " + e.dial_code}
                  </li>
                );
              })}
            </ul>
            <input
              type="number"
              className={
                error.phone && error.phone.length > 2 ? "bordererror" : ""
              }
              placeholder={t("enter-number")}
              onChange={(e) => {
                setDetailParents({
                  ...detailParents,
                  phone: e.target.value,
                });
                detailParents.phone.length > 7
                  ? setError({ ...error, phone: "" })
                  : setError({ ...error, phone: "error" });
              }}
              defaultValue={
                detailParents.phone != null ? detailParents.phone : ""
              }
            />

            <button
              onClick={(e) => mobileverify()}
              style={
                detail.phoneVerifiedStatus != null &&
                detail.phoneVerifiedStatus == 1
                  ? { display: "none" }
                  : {}
              }
            >
              {isButton ? t("text-wait") : t("get-code")}
            </button>
          </div>
          <br />
          <br />
          <label>{t("enter-code-verify")}</label>
          <div className="number">
            <input
              type="number"
              className={
                error.otperror && error.otperror.length > 2 ? "bordererror" : ""
              }
              placeholder={t("enter-code")}
              onChange={(e) => {
                setOtpVerify(e.target.value);
                otpVerify.length > 2
                  ? setError({ ...error, otperror: "" })
                  : setError({ ...error, otperror: "error" });
              }}
            />
            <button
              onClick={(e) => otpverify()}
              style={
                detail.phoneVerifiedStatus != null &&
                detail.phoneVerifiedStatus == 1
                  ? { display: "none" }
                  : {}
              }
            >
              {t("text-verify")}
            </button>
          </div>
        </div>
        <div
          className="success"
          id="success"
          style={
            detail.phoneVerifiedStatus != null &&
            detail.phoneVerifiedStatus == 1
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <FlagIcon />
          {t("verification-thank")}
        </div>
        <div className="success2" id="success4" style={{ display: "none" }}>
          <MarkIcon />
          {t("verification-fail")}
        </div>
        <div className="success2" id="success3" style={{ display: "none" }}>
          <MarkIcon />
          {t("verification-fail-contact")}
          <Link to="/contact-us">{t("tech-support")}</Link>
          {t("further-assistance")}
        </div>
      </div>
      <div className="button">
        <button
          onClick={(e) => {
            setProcess(100);
            setStep(5);
            setMobile(-60);
            window.scrollTo({ top: 0 });
          }}
        >
          {t("text-back")}
        </button>
        <button
          onClick={(e) => {
            if (detail.phoneVerifiedStatus === 1 || verify == "done") {
              setStep(7);
              setMobile(-75);
              window.scrollTo({ top: 0 });
              updateProfile(false);
            } else {
              setErrorField({ ...errorField, phone: "error" });
              window.scrollTo({ top: 0 });
            }
          }}
        >
          {t("text-next")}
        </button>
      </div>
    </div>
  );
}
