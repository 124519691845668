import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";

import { FavoriteProfile } from "../FavoriteProfile";
import { SocialFacebook } from "../SocialFacebook";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { SearchIcon } from "../../../assets/image/SearchIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import { fetchVisitorProfileView } from "../../../api/visitorApi";

export function VisitorDetail(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [profileData, setProfileData] = useState([]);
  const [changeProfileData, setChangeProfileData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [profile] = useState();
  const [isElement, setIsElement] = useState(true);
  const [message, setMessage] = useState("");

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const hadnleVisitorProfile = async () => {
    try {
      const result = await fetchVisitorProfileView(props.id);

      setProfileData([result.data.getProfile]);
      setJobData(result.data.job);
      setChangeProfileData(result.data);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleElementById = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  useEffect(() => {
    if (isCheck) {
      hadnleVisitorProfile();
      setIsCheck(false);
    }
  }, [isCheck]);

  return (
    <>
      {profileData && profileData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit">
            <p>
              <span className="date">
                {new Date(
                  profileData && profileData[0] && profileData[0].Visitdate
                ).toLocaleDateString("en-US", { weekday: "short" })}
                ,
                {month[
                  new Date(
                    profileData && profileData[0] && profileData[0].Visitdate
                  ).getMonth()
                ] +
                  " " +
                  new Date(
                    profileData && profileData[0] && profileData[0].Visitdate
                  ).getDate()}
                ,
                {new Date(
                  profileData && profileData[0] && profileData[0].Visitdate
                ).getFullYear()}
                ,
                {new Date(
                  profileData && profileData[0] && profileData[0].Visitdate
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
              {profileData &&
              profileData[0] &&
              profileData[0].read_status == 0 ? (
                <span className="new">{t("text-new-up")}</span>
              ) : (
                ""
              )}
              {profileData &&
              profileData[0] &&
              profileData[0].profile_headline != null
                ? profileData &&
                  profileData[0] &&
                  profileData[0].profile_headline
                : jobData[0] && jobData[0].title}
            </p>
            <h2>
              {t("text-dear")} <span>{message}</span>,
            </h2>
            <h2 style={{ display: "block" }}>
              {t("post-new-visitor")}
              <Link
                to={
                  profileData &&
                  profileData[0] &&
                  profileData[0].user_type == "provider"
                    ? "/profile-provider/" +
                      (profileData && profileData[0] && profileData[0].user_id)
                    : "/profile-parents/" +
                      (profileData && profileData[0] && profileData[0].user_id)
                }
              >
                
                {" " + profileData &&
                  profileData[0] &&
                  profileData[0].first_name}
              </Link>
              {t("visited your profile on")}
              {month[
                new Date(
                  profileData && profileData[0] && profileData[0].Visitdate
                ).getMonth()
              ] +
                " " +
                new Date(
                  profileData && profileData[0] && profileData[0].Visitdate
                ).getDate()}
              th,
              {new Date(
                profileData && profileData[0] && profileData[0].Visitdate
              ).getFullYear()}
              .
            </h2>
          </div>
          <div class="right_side_section">
            <div class="looking_for_candidate">
              {profileData.map((data, index) => {
                if (index <= 5) {
                  return (
                    <div class="looking_for_candidate_boxs">
                      <div class="looking_for_candidate_box">
                        <div class="first_sec">
                          <div class="image_sec">
                            <img
                              src={
                                data.file_path != null
                                  ? api +
                                    "/assets/images/users/" +
                                    data.file_path
                                  : window.location.origin + "/img/nany_img.png"
                              }
                              alt=""
                            />
                            <div class="heart_sec">
                              <FavoriteProfile
                                id={data.id}
                                username={data.first_name}
                              />
                            </div>
                          </div>
                          <div class="nany_social">
                            {data.phoneVerifiedStatus == 1 ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_phone.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <PhoneIcon />
                            )}
                            <img
                              src={
                                window.location.origin + "/images/nany_msg.svg"
                              }
                              alt=""
                            />
                            {data.facebookverify != null ||
                            data.linkdinverify != null ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_cont.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <ServiceIcon />
                            )}
                          </div>
                        </div>
                        <div class="second_sec">
                          <div class="heading">
                            <h3>
                              {data.profile_headline != null
                                ? data.profile_headline
                                : jobData[0] && jobData[0].title}
                            </h3>
                            {data.plateformsocialmedia == "Yes" ? (
                              <SocialFacebook
                                link={
                                  window.location.origin +
                                  "/profile-provider/" +
                                  data.id
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="post_general">
                            <h6>
                              {data.first_name != null ? data.first_name : ""} (
                              {data.dob != undefined
                                ? new Date().getFullYear() -
                                  parseInt(
                                    data.dob
                                      .substr(data.dob.lastIndexOf("\\") + 1)
                                      .split("-")[0]
                                  )
                                : ""}
                              )
                            </h6>
                            <p>
                              {changeProfileData.reviewAvg >= 0 ? (
                                <>
                                  {[...Array(changeProfileData.reviewAvg)].map(
                                    (star, index) => {
                                      index += 1;
                                      return <StarIcon />;
                                    }
                                  )}
                                  {[
                                    ...Array(5 - changeProfileData.reviewAvg),
                                  ].map((star, index) => {
                                    index += 1;
                                    return <WhiteStarIcon />;
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                              <span> ({changeProfileData.reviewcount})</span>
                            </p>
                          </div>
                          <div class="post_detail">
                            <div class="post">
                              {JSON.parse(data.service_type) != null ? (
                                Object.values(
                                  JSON.parse(data.service_type)
                                )[0] == "Nanny" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : Object.keys(
                                    JSON.parse(data.service_type)
                                  )[0] == "tab2" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/teacher_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : Object.keys(
                                    JSON.parse(data.service_type)
                                  )[0] == "tab3" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/education_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : Object.keys(
                                    JSON.parse(data.service_type)
                                  )[0] == "tab4" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/tutor_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              <h5>
                                {JSON.parse(data.service_type) != null
                                  ? t(
                                      Object.values(
                                        JSON.parse(data.service_type)
                                      )[0]
                                    )
                                  : ""}
                              </h5>
                            </div>
                            <div class="vi"></div>

                            <div class="post_pay">
                              <img src="img/post_pay.png" alt="" />
                              {profile && profile.country == "Serbia" ? (
                                <h5>
                                  {data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[1] *
                                        100
                                    : data.tutorperhrrate
                                    ? data.tutorperhrrate
                                        .substr(
                                          data.tutorperhrrate.lastIndexOf(
                                            "\\"
                                          ) + 1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.tutorperhrrate
                                        .substr(
                                          data.tutorperhrrate.lastIndexOf(
                                            "\\"
                                          ) + 1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""}
                                  {t("time-hour")}
                                </h5>
                              ) : (
                                <h5>
                                  $
                                  {data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                    : data.tutorperhrrate
                                    ? data.tutorperhrrate
                                    : ""}
                                  {t("time-hour")}
                                </h5>
                              )}
                            </div>

                            <div class="vi"></div>
                            <div class="post_fet">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_fet.svg"
                                }
                                alt=""
                              />
                              <h5>
                                {data.nanyintrestedin != null
                                  ? t(data.nanyintrestedin)
                                  : t(data.tutorintrestedin)}
                              </h5>
                            </div>
                            <div class="vi"></div>
                            <div class="post_cal">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_cal.svg"
                                }
                                alt=""
                              />
                              <h5>
                                {data.nanystartdate != null
                                  ? data.nanystartdate
                                  : data.tutorstartdate}
                              </h5>
                            </div>
                            <div class="vi"></div>
                            <div class="post_loc">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_loc.svg"
                                }
                                alt=""
                              />
                              <h5>
                                {data.country != null ? data.country : ""} ,
                                {data.city != null ? data.city : ""}
                              </h5>
                            </div>
                          </div>
                          <p id={"half" + index}>
                            {data.about != null
                              ? data.about.substr(0, 100)
                              : jobData &&
                                jobData[0] &&
                                jobData[0].description}
                            {data.about != null && data.about.length > 100 ? (
                              <span
                                onClick={(e) =>
                                  handleElementById(
                                    "half" + index,
                                    "full" + index,
                                    "plink" + index
                                  )
                                }
                              >
                                {t("text-more")}
                              </span>
                            ) : (
                              jobData && jobData[0] && jobData[0].description
                            )}
                          </p>
                          <p id={"full" + index} className="hide">
                            {data.about}
                            <span
                              onClick={(e) =>
                                handleElementById(
                                  "full" + index,
                                  "half" + index,
                                  "plink" + index
                                )
                              }
                            >
                              {t("text-less")}
                            </span>
                          </p>
                        </div>
                        <div className="find_search"></div>
                        <div class="view_profile_btn visiterbtn">
                          <Link
                            to="/search-providers"
                            onClick={(e) =>
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000)
                            }
                          >
                            <SearchIcon />
                            {t("Search for similar job")}
                          </Link>

                          <Link
                            to={
                              profileData &&
                              profileData[0] &&
                              profileData[0].user_type == "provider"
                                ? "/profile-provider/" +
                                  (profileData &&
                                    profileData[0] &&
                                    profileData[0].user_id)
                                : "/profile-parents/" +
                                  (profileData &&
                                    profileData[0] &&
                                    profileData[0].user_id)
                            }
                          >
                            {t("visit-profile")}
                          </Link>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  );
                }
              })}

              <div className="mobile">
                {profileData.map((data, index) => {
                  if (index <= 5) {
                    return (
                      <>
                        <div
                          class="looking_for_candidate_boxs"
                          style={{ display: "block" }}
                        >
                          <div class="looking_for_candidate_box">
                            <div class="second_sec">
                              <div class="heading">
                                <h3>
                                  {data.profile_headline != null
                                    ? data.profile_headline
                                    : ""}
                                </h3>
                                {data.plateformsocialmedia == "Yes" ? (
                                  <SocialFacebook
                                    link={
                                      window.location.origin +
                                      "/profile-provider/" +
                                      data.id
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="post_general">
                                <h6>
                                  {data.first_name != null
                                    ? data.first_name
                                    : ""}
                                  (
                                  {data.dob != undefined
                                    ? new Date().getFullYear() -
                                      parseInt(
                                        data.dob
                                          .substr(
                                            data.dob.lastIndexOf("\\") + 1
                                          )
                                          .split("-")[0]
                                      )
                                    : ""}
                                  )
                                </h6>
                                <p>
                                  {changeProfileData.reviewAvg >= 0 ? (
                                    <>
                                      {[
                                        ...Array(changeProfileData.reviewAvg),
                                      ].map((star, index) => {
                                        index += 1;
                                        return <StarIcon />;
                                      })}
                                      {[
                                        ...Array(
                                          5 - changeProfileData.reviewAvg
                                        ),
                                      ].map((star, index) => {
                                        index += 1;
                                        return <WhiteStarIcon />;
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <span>
                                    
                                    ({changeProfileData.reviewcount})
                                  </span>
                                </p>
                              </div>
                              <div class="post_detail">
                                <div class="post">
                                  {JSON.parse(data.service_type) != null ? (
                                    Object.values(
                                      JSON.parse(data.service_type)
                                    )[0] == "Nanny" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/nany_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : Object.keys(
                                        JSON.parse(data.service_type)
                                      )[0] == "tab2" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/teacher_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : Object.keys(
                                        JSON.parse(data.service_type)
                                      )[0] == "tab3" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/education_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : Object.keys(
                                        JSON.parse(data.service_type)
                                      )[0] == "tab4" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/tutor_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <h5>
                                    {JSON.parse(data.service_type) != null
                                      ? t(
                                          Object.values(
                                            JSON.parse(data.service_type)
                                          )[0]
                                        )
                                      : ""}
                                  </h5>
                                </div>
                                <div class="vi"></div>

                                <div class="post_pay">
                                  <img src="img/post_pay.png" alt="" />
                                  {profile && profile.country == "Serbia" ? (
                                    <h5>
                                      {data.nanyperhrrate != null
                                        ? data.nanyperhrrate
                                            .substr(
                                              data.nanyperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[0] *
                                            100 +
                                          " - " +
                                          data.nanyperhrrate
                                            .substr(
                                              data.nanyperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[1] *
                                            100
                                        : data.tutorperhrrate
                                        ? data.tutorperhrrate
                                            .substr(
                                              data.tutorperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[0] *
                                            100 +
                                          " - " +
                                          data.tutorperhrrate
                                            .substr(
                                              data.tutorperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[1] *
                                            100
                                        : ""}
                                      {t("time-hour")}
                                    </h5>
                                  ) : (
                                    <h5>
                                      $
                                      {data.nanyperhrrate != null
                                        ? data.nanyperhrrate
                                        : data.tutorperhrrate
                                        ? data.tutorperhrrate
                                        : ""}
                                      {t("time-hour")}
                                    </h5>
                                  )}
                                </div>

                                <div class="vi"></div>
                                <div class="post_fet">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/post_fet.svg"
                                    }
                                    alt=""
                                  />
                                  <h5>
                                    {data.nanyintrestedin != null
                                      ? t(data.nanyintrestedin)
                                      : t(data.tutorintrestedin)}
                                  </h5>
                                </div>
                                <div class="vi"></div>
                                <div class="post_cal">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/post_cal.svg"
                                    }
                                    alt=""
                                  />
                                  <h5>
                                    {data.nanystartdate != null
                                      ? data.nanystartdate
                                      : data.tutorintrestedin}
                                  </h5>
                                </div>
                                <div class="vi"></div>
                                <div class="post_loc">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/post_loc.svg"
                                    }
                                    alt=""
                                  />
                                  <h5>
                                    {data.country != null ? data.country : ""} ,
                                    {data.city != null ? data.city : ""}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="first_sec">
                              <div class="image_sec">
                                <img
                                  src={
                                    data.file_path != null
                                      ? api +
                                        "/assets/images/users/" +
                                        data.file_path
                                      : "img/nany_img.png"
                                  }
                                  alt=""
                                />
                                <div class="heart_sec">
                                  <FavoriteProfile
                                    id={data.id}
                                    username={data.first_name}
                                  />
                                </div>
                              </div>
                              <div class="nany_social">
                                {data.phoneVerifiedStatus == 1 ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_phone.svg"
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <PhoneIcon />
                                )}
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_msg.svg"
                                  }
                                  alt=""
                                />
                                {data.facebookverify != null ||
                                data.linkdinverify != null ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_cont.svg"
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <ServiceIcon />
                                )}
                              </div>
                            </div>
                            <div class="second_sec">
                              <p id={"half" + index}>
                                {data.about != null
                                  ? data.about.substr(0, 100)
                                  : ""}
                                {data.about != null &&
                                data.about.length > 100 ? (
                                  <span
                                    onClick={(e) =>
                                      handleElementById(
                                        "half" + index,
                                        "full" + index,
                                        "plink" + index
                                      )
                                    }
                                  >
                                    {t("text-more")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                              <p id={"full" + index} className="hide">
                                {data.about}
                                <span
                                  onClick={(e) =>
                                    handleElementById(
                                      "full" + index,
                                      "half" + index,
                                      "plink" + index
                                    )
                                  }
                                >
                                  {t("text-less")}
                                </span>
                              </p>
                            </div>
                            <div class="view_profile_btn visiterbtn">
                              <Link to="/search-parents">
                                <SearchIcon />

                                {t("search-similar-jobs")}
                              </Link>
                              <Link
                                to={
                                  profileData &&
                                  profileData[0] &&
                                  profileData[0].user_type == "provider"
                                    ? "/profile-provider/" +
                                      (profileData &&
                                        profileData[0] &&
                                        profileData[0].user_id)
                                    : "/profile-parents/" +
                                      (profileData &&
                                        profileData[0] &&
                                        profileData[0].user_id)
                                }
                              >
                                {t("visit-profile")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
