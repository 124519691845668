import { FacebookButton, LinkedInButton } from "react-social";

import { FacebookIcon } from "../../../assets/image/FacebookIcon";
import { LinkedinIcon } from "../../../assets/image/LinkedinIcon";

export function SocialFacebook({ link, icon }) {
  return (
    <>
      <div className="social_share">
        {icon && icon == "yes" ? (
          <i class="fa fa-share-alt" aria-hidden="true"></i>
        ) : (
          <img src={window.location.origin + "/images/share_icon.svg"} alt="" />
        )}
        <ul>
          <li>
            <FacebookButton url={link} appId={3384521628485216}>
              <FacebookIcon
                width={30}
                height={30}
                fillColor={"#7D2B8B"}
                textColor={"#fff"}
                color={"#7D2B8B"}
              />
            </FacebookButton>
          </li>
          <li>
            <LinkedInButton url={link} appId={"860qiqz9pllqgi"}>
              <LinkedinIcon
                width={31}
                height={30}
                color={"#fff"}
                fillColor={"#7D2B8B"}
              />
            </LinkedInButton>
          </li>
        </ul>
      </div>
    </>
  );
}
