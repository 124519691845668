import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { DEFAULT_API } from "../../utils/variables";
import { FavoriteProfile } from "../../components/common/FavoriteProfile";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";
import { ConfirmIcon } from "../../assets/image/ConfirmIcon";
import { SocialFacebook } from "../../components/common/SocialFacebook";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";

export const SearchRightSection = ({
  rateData,
  handleRate,
  profile,
  interestData,
  setListShow,
  result,
  listShow,
  handleElementContent,
  sliderRef,
}) => {


  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [isPageFilter, setIsPageFilter] = useState(false);
  const [shortby, setshortby] = useState("Relevance");
  const [isPageResult, setIsPageResult] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const generalSettings = {
    dots: false,
    infinite: true,
    slidesToShow: interestData.length < 5 ? interestData.length : 5,
    arrows: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="right_side_section">
      <div className="looking_for_candidate">
        <h2>{t("These are experienced candidates from your area")}</h2>
        <div className="looking_for_candidate_filter_sec">
          <div className="result">
            <p>
              {rateData.length}
              <span>
                {t("result")}
                {language === "en" && rateData.length > 1 ? "s" : ""}
              </span>
            </p>
          </div>
          <div className="page_filter">
            <div className="sortby">
              <label for="">{t("Sort by")}</label>
              <div className="sortby_select">
                {isPageFilter ? (
                  <div
                    className="close_eve"
                    onClick={(e) => setIsPageFilter(!isPageFilter)}
                  ></div>
                ) : (
                  ""
                )}
                <div className="drodownsearch">
                  <span onClick={(e) => setIsPageFilter(!isPageFilter)}>
                    {t(shortby)}
                  </span>
                  {isPageFilter ? (
                    <ul>
                      <li
                        onClick={(e) => {
                          handleRate("Relevance");
                          setshortby("Relevance");
                          setIsPageFilter(!isPageFilter);
                        }}
                      >
                        {t("Relevance")}
                      </li>
                      <li
                        onClick={(e) => {
                          handleRate("Reviews");
                          setshortby("Reviews");
                          setIsPageFilter(!isPageFilter);
                        }}
                      >
                        {t("Review")}
                      </li>
                      <li
                        onClick={(e) => {
                          handleRate("Distance");
                          setshortby("Distance");
                          setIsPageFilter(!isPageFilter);
                        }}
                      >
                        {t("Distances.")}
                      </li>
                      <li
                        onClick={(e) => {
                          handleRate("Low to high");
                          setshortby("Low to high");
                          setIsPageFilter(!isPageFilter);
                        }}
                      >
                        {t("Rate: Low to high")}
                      </li>
                      <li
                        onClick={(e) => {
                          handleRate("High to low");
                          setshortby("High to low");
                          setIsPageFilter(!isPageFilter);
                        }}
                      >
                        
                        {t("Rate: High to low")}
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="resultperpage">
              <label for="">{t("result-page")}</label>
              <div className="resultperpage_select">
                {isPageResult ? (
                  <div
                    className="close_eve"
                    onClick={(e) => setIsPageResult(!isPageResult)}
                  ></div>
                ) : (
                  ""
                )}
                <div className="drodownsearch">
                  <span onClick={(e) => setIsPageResult(!isPageResult)}>
                    {perPage}
                  </span>
                  {isPageResult ? (
                    <ul>
                      <li
                        onClick={(e) => {
                          setListShow(10);
                          setPerPage(10);
                          setIsPageResult(!isPageResult);
                        }}
                      >
                        10
                      </li>
                      <li
                        onClick={(e) => {
                          setListShow(15);
                          setPerPage(15);
                          setIsPageResult(!isPageResult);
                        }}
                      >
                        15
                      </li>
                      <li
                        onClick={(e) => {
                          setListShow(20);
                          setPerPage(20);
                          setIsPageResult(!isPageResult);
                        }}
                      >
                        20
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {rateData.map((data, index) => {
          if (index < listShow) {
            return (
              <div className="looking_for_candidate_boxs">
                <div className="looking_for_candidate_box">
                  <div className="first_sec">
                    <div className="image_sec">
                      <img
                        onClick={(e) => {
                          navigate("/profile-provider/" + data.id);
                        }}
                        src={
                          data.file_path != null
                            ? DEFAULT_API +
                              "/assets/images/users/" +
                              data.file_path
                            : "img/nany_img.png"
                        }
                        alt=""
                      />
                      <div className="heart_sec">
                        {localStorage.getItem("user_type") == "parents" ? (
                          <FavoriteProfile
                            id={data.id}
                            username={data.first_name}
                            heart2={data.favorNot}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="nany_social">
                      {data.phoneVerifiedStatus == 1 ? (
                        <img
                          src={
                            window.location.origin + "/images/nany_phone.svg"
                          }
                          alt=""
                        />
                      ) : (
                        <PhoneIcon />
                      )}
                      <img
                        src={window.location.origin + "/images/nany_msg.svg"}
                        alt=""
                      />
                      {data.facebookverify != null ||
                      data.linkdinverify != null ? (
                        <img
                          src={window.location.origin + "/images/nany_cont.svg"}
                          alt=""
                        />
                      ) : (
                        <ServiceIcon />
                      )}
                      {data.docsStatus == "Yes" ? (
                        <img
                          src={window.location.origin + "/images/ok.svg"}
                          alt=""
                        />
                      ) : (
                        <ConfirmIcon />
                      )}
                    </div>
                  </div>
                  <div className="second_sec">
                    <div className="heading">
                      <h3
                        onClick={(e) => {
                          navigate("/profile-provider/" + data.id);
                        }}
                      >
                        {data.profile_headline != null
                          ? data.profile_headline
                          : ""}
                      </h3>
                      {data.plateformsocialmedia == "Yes" ? (
                        <SocialFacebook
                          link={
                            window.location.origin +
                            "/profile-provider/" +
                            data.id
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="post_general">
                      <h6
                        onClick={(e) => {
                          navigate("/profile-provider/" + data.id);
                        }}
                      >
                        {data.first_name + " " + data.last_name} (
                        {data.dob != undefined
                          ? new Date().getFullYear() -
                            parseInt(
                              data.dob
                                .substr(data.dob.lastIndexOf("\\") + 1)
                                .split("-")[0]
                            )
                          : ""}
                        )
                      </h6>
                      <p>
                        {data.reviewAvg >= 0 ? (
                          <>
                            {[...Array(data.reviewAvg)].map((star, index) => {
                              index += 1;
                              return <StarIcon />;
                            })}
                            {[...Array(5 - data.reviewAvg)].map(
                              (star, index) => {
                                index += 1;
                                return <WhiteStarIcon />;
                              }
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <span> ({data.reviewcount})</span>
                      </p>
                    </div>
                    <div className="post_detail">
                      <div className="post">
                        {data.service_type != null ? (
                          t(Object.values(data.service_type)[0]) == "Nanny" ? (
                            <img
                              src={
                                window.location.origin + "/images/nany_post.svg"
                              }
                              alt=""
                            />
                          ) : Object.keys(data.service_type)[0] == "tab2" ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/teacher_post.svg"
                              }
                              alt=""
                            />
                          ) : Object.keys(data.service_type)[0] == "tab3" ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/education_post.svg"
                              }
                              alt=""
                            />
                          ) : Object.keys(data.service_type)[0] == "tab4" ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/tutor_post.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <h5>
                          {data.service_type != null
                            ? t(Object.values(data.service_type)[0])
                            : ""}
                        </h5>
                      </div>
                      <div className="vi"></div>

                      <div className="post_pay">
                        <img
                          src={window.location.origin + "/img/post_pay.png"}
                          alt=""
                        />
                        {profile && profile.country == "Serbia" ? (
                          <h5>
                            {Object.keys(data.service_type)[0] == "tab1"
                              ? data.nanyperhrrate != null
                                ? data.nanyperhrrate
                                    .substr(
                                      data.nanyperhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[0] *
                                    100 +
                                  " - " +
                                  data.nanyperhrrate
                                    .substr(
                                      data.nanyperhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[1] *
                                    100
                                : ""
                              : Object.keys(data.service_type)[0] == "tab2"
                              ? data.seterhrrate != null
                                ? data.seterhrrate
                                    .substr(
                                      data.seterhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[0] *
                                    100 +
                                  " - " +
                                  data.seterhrrate
                                    .substr(
                                      data.seterhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[1] *
                                    100
                                : ""
                              : Object.keys(data.service_type)[0] == "tab3"
                              ? data.seperhrrate != null
                                ? data.seperhrrate
                                    .substr(
                                      data.seperhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[0] *
                                    100 +
                                  " - " +
                                  data.seperhrrate
                                    .substr(
                                      data.seperhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[1] *
                                    100
                                : ""
                              : Object.keys(data.service_type)[0] == "tab4"
                              ? data.tutorperhrrate != null
                                ? data.tutorperhrrate
                                    .substr(
                                      data.tutorperhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[0] *
                                    100 +
                                  " - " +
                                  data.tutorperhrrate
                                    .substr(
                                      data.tutorperhrrate.lastIndexOf("\\") + 1
                                    )
                                    .split("-")[1] *
                                    100
                                : ""
                              : ""}
                            {t("time-hour")}
                          </h5>
                        ) : (
                          <h5>
                            $
                            {Object.keys(data.service_type)[0] == "tab1"
                              ? data.nanyperhrrate != null
                                ? data.nanyperhrrate
                                : ""
                              : Object.keys(data.service_type)[0] == "tab2"
                              ? data.seterhrrate
                                ? data.seterhrrate
                                : ""
                              : Object.keys(data.service_type)[0] == "tab3"
                              ? data.seperhrrate
                                ? data.seperhrrate
                                : ""
                              : Object.keys(data.service_type)[0] == "tab4"
                              ? data.tutorperhrrate
                                ? data.tutorperhrrate
                                : ""
                              : ""}
                            {t("time-hour")}
                          </h5>
                        )}
                      </div>
                      <div className="vi"></div>
                      {data.service_type != null &&
                      Object.values(data.service_type)[1] ? (
                        <>
                          <div className="post">
                            {data.service_type != null ? (
                              Object.values(data.service_type)[1] == "Nanny" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(data.service_type)[1] ==
                                "tab2" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/teacher_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(data.service_type)[1] ==
                                "tab3" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/education_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(data.service_type)[1] ==
                                "tab4" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/tutor_post.svg"
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <h5>
                              
                              {data.service_type != null
                                ? t(Object.values(data.service_type)[1])
                                : ""}
                            </h5>
                          </div>
                          <div className="vi"></div>
                          <div className="post_pay">
                            <img
                              src={
                                window.location.origin + "/images/post_pay.svg"
                              }
                              alt=""
                            />
                            {profile && profile.country == "Serbia" ? (
                              <h5>
                                {Object.keys(data.service_type)[1] == "tab1"
                                  ? data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""
                                  : Object.keys(data.service_type)[1] == "tab2"
                                  ? data.seterhrrate != null
                                    ? data.seterhrrate
                                        .substr(
                                          data.seterhrrate.lastIndexOf("\\") + 1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.seterhrrate
                                        .substr(
                                          data.seterhrrate.lastIndexOf("\\") + 1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""
                                  : Object.keys(data.service_type)[1] == "tab3"
                                  ? data.seperhrrate != null
                                    ? data.seperhrrate
                                        .substr(
                                          data.seperhrrate.lastIndexOf("\\") + 1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.seperhrrate
                                        .substr(
                                          data.seperhrrate.lastIndexOf("\\") + 1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""
                                  : Object.keys(data.service_type)[1] == "tab4"
                                  ? data.tutorperhrrate != null
                                    ? data.tutorperhrrate
                                        .substr(
                                          data.tutorperhrrate.lastIndexOf(
                                            "\\"
                                          ) + 1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.tutorperhrrate
                                        .substr(
                                          data.tutorperhrrate.lastIndexOf(
                                            "\\"
                                          ) + 1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""
                                  : ""}
                                {t("time-hour")}
                              </h5>
                            ) : (
                              <h5>
                                $
                                {Object.keys(data.service_type)[1] == "tab1"
                                  ? data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                    : ""
                                  : Object.keys(data.service_type)[1] == "tab2"
                                  ? data.seterhrrate
                                    ? data.seterhrrate
                                    : ""
                                  : Object.keys(data.service_type)[1] == "tab3"
                                  ? data.seperhrrate
                                    ? data.seperhrrate
                                    : ""
                                  : Object.keys(data.service_type)[1] == "tab4"
                                  ? data.tutorperhrrate
                                    ? data.tutorperhrrate
                                    : ""
                                  : ""}
                                {t("time-hour")}
                              </h5>
                            )}
                          </div>
                          <div className="vi"></div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="post_fet">
                        <img
                          src={window.location.origin + "/images/post_fet.svg"}
                          alt=""
                        />
                        <h5>
                          {data.nanyintrestedin != null
                            ? (() => {
                                let nanyintrestedin = data.nanyintrestedin;

                                if (language === "sr") {
                                  switch (data.nanyintrestedin) {
                                    case "Occasionally":
                                      nanyintrestedin = "Povremen rad";
                                      break;
                                    case "Part time":
                                      nanyintrestedin = "Skraćeno radno vreme";
                                      break;
                                    case "Full time":
                                      nanyintrestedin = "Puno radno vreme";
                                      break;
                                    default:
                                      nanyintrestedin = data.nanyintrestedin;
                                  }
                                }

                                return nanyintrestedin;
                              })()
                            : t("full-time")}
                        </h5>
                      </div>
                      <div className="vi"></div>
                      <div className="post_cal">
                        <img
                          src={window.location.origin + "/images/post_cal.svg"}
                          alt=""
                        />
                        <h5>
                          {data.nanystartdate != null
                            ? data.nanystartdate
                            : data.tutorstartdate}
                        </h5>
                      </div>
                      <div className="vi"></div>
                      <div className="post_loc">
                        <img
                          src={window.location.origin + "/images/post_loc.svg"}
                          alt=""
                        />
                        <h5>
                          {data.country != null ? data.country : ""} ,
                          {data.city != null ? data.city : ""}
                        </h5>
                      </div>
                    </div>
                    <p id={"half" + index}>
                      {data.about != null ? data.about.substr(0, 100) : ""}
                      {data.about != null && data.about.length > 100 ? (
                        <span
                          onClick={(e) =>
                            handleElementContent(
                              "half" + index,
                              "full" + index,
                              "plink" + index
                            )
                          }
                        >
                          {t("text-more")}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <p id={"full" + index} className="hide">
                      {data.about}
                      <span
                        onClick={(e) =>
                          handleElementContent(
                            "full" + index,
                            "half" + index,
                            "plink" + index
                          )
                        }
                      >
                        {t("text-less")}
                      </span>
                    </p>

                    <div className="view_profile_btn">
                      <button
                        onClick={(e) => {
                          navigate("/profile-provider/" + data.id);
                        }}
                      >
                        {t("view-profile")}
                      </button>
                    </div>
                  </div>
                </div>
                <p id={"plink" + index} className="hide">
                  {t("Please refer to our")}
                  <Link to="/faq">{t("text-faq")}</Link> {t("text-and")}
                  <Link to="/safety-center">{t("safety-center")}</Link> to
                  {t("read safety tips.")}
                </p>
              </div>
            );
          }
        })}

        {rateData.length == 0 && result != "" ? (
          <div className="noresult">
            <h3>{t("Sorry your search returned no Result.")}</h3>
            <img src={window.location.origin + "/images/search_noresult.svg"} />
          </div>
        ) : (
          ""
        )}
        {rateData.length >= 6 ? (
          <div className="load_more" onClick={(e) => setListShow(100)}>
            {t("text-load")}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mob_update_to_premium">
        <h2>
          <img
            src={window.location.origin + "/images/upgrade_icon.svg"}
            alt=""
          />
          <Link to={""}>{t("Upgrade to premium")}</Link>
        </h2>
        <h3>
          {t("Appear higher in search Result and find your perfect")}
          {localStorage.getItem("user_type") == "parents"
            ? " candidate"
            : " job today"}
          <span>
            <img
              src={window.location.origin + "/images/upgrade_right_side.svg"}
              alt=""
            />
          </span>
        </h3>
      </div>
      <div className="update_to_premium">
        <h2>
          <img
            src={window.location.origin + "/images/upgrade_icon.svg"}
            alt=""
          />
          {t("Appear higher in search Result and find your perfect")}
          {localStorage.getItem("user_type") == "parents"
            ? " candidate"
            : " job today"}
        </h2>
        <h3>
          <Link to={""}>{t("Upgrade to premium")}</Link>
          <span>
            <img
              src={window.location.origin + "/images/upgrade_right_side.svg"}
              alt=""
            />
          </span>
        </h3>
      </div>
      <div
        className={
          "interested_fam  " + (interestData.length < 5 ? "main-header" : "")
        }
      >
        <h2>{t("See who families are interested in")}</h2>
        <p>{t("View popular candidates in your area")}</p>
        <div className="interested_fam_boxs">
          <div className="interested_fam_boxs images_set">
            {interestData?.length < 5 ? (
              interestData?.map((data, index) => {
                let data2 =  data;
                return (
                  <div
                    className="interested_fam_box "
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="image_sec">
                      <img
                        src={
                          data2.file_path != null
                            ? DEFAULT_API +
                              "/assets/images/users/" +
                              data2.file_path
                            : "img/nany_img.png"
                        }
                        alt=""
                      />
                      <div className="heart_sec">
                        {localStorage.getItem("user_type") == "parents" ? (
                          <FavoriteProfile
                            id={data2.id}
                            username={data2.first_name}
                            heart2={data.favornotprofile}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="general_sec">
                      <h4>
                        <Link
                          to={
                            data2.user_type == "parents"
                              ? "/profile-parents/" + data2.id
                              : "/profile-provider/" + data2.id
                          }
                          target="_blank"
                        >
                          {data2.first_name + " " + data2.last_name}
                        </Link>
                      </h4>
                      {data.reviewAvg >= 0 ? (
                        <>
                          {[...Array(data.reviewAvg)]?.map((star, index) => {
                            index += 1;
                            return <StarIcon />;
                          })}
                          {[...Array(5 - data.reviewAvg)]?.map(
                            (star, index) => {
                              index += 1;
                              return <WhiteStarIcon />;
                            }
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="detail_sec">
                        <img
                          src={window.location.origin + "/images/post_fet.svg"}
                          alt=""
                        />
                        <p>
                          
                          {data.service_types != null
                            ? t(Object.values(data.service_types)[0])
                            : ""}
                          {data.service_type != null
                            ? t(Object.values(data.service_type)[0])
                            : ""}
                        </p>
                      </div>
                      <div className="detail_sec">
                        <img
                          src={window.location.origin + "/images/post_pay.svg"}
                          alt=""
                        />
                        <p>
                          ${data2.nanyperhrrate} {t("time-hour")}
                        </p>
                      </div>
                      <div className="detail_sec">
                        <img
                          src={window.location.origin + "/images/post_rang.svg"}
                          alt=""
                        />
                        <p>
                          {data2.nanyyearexp != null
                            ? data2.nanyperhrrate
                            : data2.setyearexp != null
                            ? data2.setyearexp
                            : data2.tutorexp != null
                            ? data2.tutorexp
                            : data2.yearofexpasteacher != null
                            ? data2.yearofexpasteacher
                            : "0"}
                          {t("years-experience")}
                        </p>
                      </div>
                      <div className="detail_sec">
                        <img
                          src={window.location.origin + "/images/post_loc.svg"}
                          alt=""
                        />
                        <p>{data2.city + ", " + data2.country}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Slider
                ref={sliderRef}
                {...generalSettings}
                id="Slider-4"
                className="slider_test"
              >
                {interestData && interestData.map((data, index) => {
                  let data2 =  data;
                  if (data.status == 0) {
                    return (
                      <div className="item" key={index}>
                        <div className="interested_fam_box ">
                          <div className="image_sec">
                            <img
                              src={
                                data2.file_path != null
                                  ? DEFAULT_API +
                                    "/assets/images/users/" +
                                    data2.file_path
                                  : "img/nany_img.png"
                              }
                              alt=""
                            />
                            <div className="heart_sec">
                              {localStorage.getItem("user_type") ==
                              "parents" ? (
                                <FavoriteProfile
                                  id={data2.id}
                                  username={data2.first_name}
                                  heart2={data.favornotprofile}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="general_sec">
                            <h4>
                              <Link
                                to={
                                  data2.user_type == "parents"
                                    ? "/profile-parents/" + data2.id
                                    : "/profile-provider/" + data2.id
                                }
                                target="_blank"
                              >
                                {data2.first_name + " " + data2.last_name}
                              </Link>
                            </h4>
                            {data.reviewAvg >= 0 ? (
                              <>
                                {[...Array(data.reviewAvg)]?.map(
                                  (star, index) => {
                                    index += 1;
                                    return <StarIcon />;
                                  }
                                )}
                                {[...Array(5 - data.reviewAvg)]?.map(
                                  (star, index) => {
                                    index += 1;
                                    return <WhiteStarIcon />;
                                  }
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <div className="detail_sec">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_fet.svg"
                                }
                                alt=""
                              />
                              <p>
                                
                                {data.service_types != null
                                  ? Object.values(data.service_types)[0]
                                  : ""}
                                {data.service_type != null
                                  ? Object.values(data.service_type)[0]
                                  : ""}
                              </p>
                            </div>
                            <div className="detail_sec">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_pay.svg"
                                }
                                alt=""
                              />
                              <p>
                                ${data2.nanyperhrrate} {t("time-hour")}
                              </p>
                            </div>
                            <div className="detail_sec">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_rang.svg"
                                }
                                alt=""
                              />
                              <p>
                                {data2.nanyyearexp != null
                                  ? data2.nanyperhrrate
                                  : data2.setyearexp != null
                                  ? data2.setyearexp
                                  : data2.tutorexp != null
                                  ? data2.tutorexp
                                  : data2.yearofexpasteacher != null
                                  ? data2.yearofexpasteacher
                                  : "0"}
                                years-experience
                              </p>
                            </div>
                            <div className="detail_sec">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_loc.svg"
                                }
                                alt=""
                              />
                              <p>{data2.city + ", " + data2.country}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </Slider>
            )}
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
