import React, { useEffect } from "react";

import { ProfileAll } from "../ProfileAll";
import { DocumentRequestContent } from "../DocumentRequestContent";
import { DocumentRequests } from "../DocumentRequests";
import { Hiring } from "../Hiring";
import { InterviewJob } from "../../Interview/InterviewJob";
import { JobApplicationHistory } from "../../Job/JobApplicationHistory";
import { JobPosts } from "../../Job/JobPosts";
import { LoyaltyNotification } from "../../Loyalty/LoyaltyNotification";
import { LoyaltyProgram } from "../../Loyalty/LoyaltyProgram";
import { MessageList } from "../../Message/MessageList";
import { Document } from "../Document";
import { InterviewNotification } from "../InterviewNotification";
import { ProfileVisitor } from "../ProfileVisitor";
import { Recommendations } from "../../Recommendations/Recommendations";
import { ReviewProvider } from "../ReviewProvider";
import { Reviews } from "../Reviews";
import { TrashList } from "../TrashList";
import { WhoVisitedContent } from "../WhoVisitedContent";
import { WhoVisitedProfile } from "../WhoVisitedProfile";

export function ProviderNotificationTabs({ subtab, profilesection }) {
  useEffect(() => {
    localStorage.setItem("back", subtab);
    localStorage.setItem("side", profilesection);
  }, [subtab]);

  return (
    <>
      {subtab == "interview-invite" && (
        <div class="interview">
          <InterviewNotification />
        </div>
      )}

      {subtab == "new-recommendation" && (
        <div class="interview">
          <Recommendations />
        </div>
      )}

      {subtab == "document-request" && (
        <div class="interview">
          <DocumentRequests />
        </div>
      )}

      {subtab == "hirings" && (
        <div class="interview">
          <Hiring />
        </div>
      )}

      {subtab == "recent-profile-visitor" && (
        <div class="interview">
          <ProfileVisitor />
        </div>
      )}

      {subtab == "new-reviews" && (
        <div class="interview">
          <ReviewProvider />
        </div>
      )}

      {subtab == "Reviews" && (
        <div class="interview">
          <Reviews />
        </div>
      )}

      {subtab == "document-requests" && (
        <div class="interview">
          <DocumentRequestContent />
        </div>
      )}

      {subtab == "my-document" && (
        <div class="interview">
          <Document />
        </div>
      )}

      {subtab == "job-applications" && (
        <div class="interview">
          <JobApplicationHistory />
        </div>
      )}

      {subtab == "job-post" && (
        <div class="interview">
          <JobPosts />
        </div>
      )}
      {subtab == "all_profile" && (
        <div class="interview">
          <ProfileAll />
        </div>
      )}

      {subtab == "who-i-visited" && (
        <div class="interview">
          <WhoVisitedContent />
        </div>
      )}

      {subtab == "who-visited-me" && (
        <div class="interview" style={{ marginBottom: "100px" }}>
          <WhoVisitedProfile />
        </div>
      )}

      {subtab == "interview-invites" && (
        <div class="interview">
          <InterviewJob />
        </div>
      )}

      {subtab == "message-inbox" && (
        <div class="interview mbin">
          <MessageList />
        </div>
      )}

      {subtab == "Trash" && (
        <div class="interview">
          <TrashList />
        </div>
      )}

      {subtab == "Loyalty-notification" && (
        <div class="interview">
          <LoyaltyNotification />
        </div>
      )}

      {subtab == "Loyalty" && (
        <div class="interview" style={{ width: "calc(100% - 22.5%)" }}>
          <LoyaltyProgram />
        </div>
      )}
    </>
  );
}
