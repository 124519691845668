import React from "react";
import { useTranslation } from "react-i18next";
import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useControlsStore } from "../../store/useControlsStore";
import { SCHEDULE_FIELDS } from "../../utils/variables";
import { Calendar } from "../../components/common/Calendar";

export function StepFourContent({ updateProfile, afterLoginsField }) {
  const { t } = useTranslation();

  const [setMobile, setStep, calendarType, setCalendarType, setProcess] =
    useControlsStore((state) => [
      state.setMobile,
      state.setStep,
      state.calendarType,
      state.setCalendarType,
      state.setProcess,
    ]);
  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const [detailParents, setDetailParents] = useDetailParentsStore((state) => [
    state.detailParents,
    state.setDetailParents,
  ]);
  const detail = useProfileStore((state) => state.detail);

  const updateCalendarData = (name, e) => {
    setDetailParents({ ...detailParents, [name]: e });
    setErrorField({ ...errorField, [name]: "" });
  };

  return (
    <div className="detail availability setp5">
      <div className="form_group full">
        <label>{t("availability-working")}</label>
        <p>{t("date-time-post")}</p>
      </div>
      <div className="form_group full">
        <label>{t("auto-calendar")}</label>
        <ul>
          <li
            onClick={(e) => setCalendarType(1)}
            className={calendarType == 1 ? "active" : ""}
          >
            <span className={errorField.fulltime != "" ? "starred" : ""}>
              {t("full-time")}
            </span>
          </li>
          <li
            onClick={(e) => setCalendarType(2)}
            className={calendarType == 2 ? "active" : ""}
          >
            <span className={errorField.beforeschool != "" ? "starred" : ""}>
              {t("before-school")}
            </span>
          </li>
          <li
            onClick={(e) => setCalendarType(3)}
            className={calendarType == 3 ? "active" : ""}
          >
            <span className={errorField.afterschool != "" ? "starred" : ""}>
              {t("after-school")}
            </span>
          </li>
          <li
            onClick={(e) => setCalendarType(4)}
            className={calendarType == 4 ? "active" : ""}
          >
            <span className={errorField.overnight != "" ? "starred" : ""}>
              {t("text-overnight")}
            </span>
          </li>
          <li
            onClick={(e) => setCalendarType(5)}
            className={calendarType == 5 ? "active" : ""}
          >
            <span className={errorField.overnight != "" ? "starred" : ""}>
              {t("text-weekends")}
            </span>
          </li>
        </ul>
      </div>
      <div className="calander">
        <div className="form_group full">
          <label>{t("select-days")}</label>
        </div>
        <div className="calanderfull">
          {calendarType == 1 ? (
            <Calendar
              data={updateCalendarData}
              data1={detailParents.fulltime}
              data2={"fulltime"}
            />
          ) : (
            ""
          )}
          {calendarType == 2 ? (
            <Calendar
              data={updateCalendarData}
              data1={detailParents.beforeschool}
              data2={"beforeschool"}
            />
          ) : (
            ""
          )}
          {calendarType == 3 ? (
            <Calendar
              data={updateCalendarData}
              data1={detailParents.afterschool}
              data2={"afterschool"}
            />
          ) : (
            ""
          )}
          {calendarType == 4 ? (
            <Calendar
              data={updateCalendarData}
              data1={detailParents.overnight}
              data2={"overnight"}
            />
          ) : (
            ""
          )}
          {calendarType == 5 ? (
            <Calendar
              data={updateCalendarData}
              data1={detailParents.weekends}
              data2={"weekends"}
            />
          ) : (
            ""
          )}
        </div>
        <div className="legend">
          <div className="form_group full">
            <label className="border">Legend</label>
            <ul>
              <li>
                <strong>{t("full-time")}</strong>
                <br />
                {t("mon-fri-9-6")}
              </li>
              <li>
                <strong>{t("before-school")}</strong>
                <br />
                {t("mon-fri-6-9")}
              </li>
              <li>
                <strong>{t("after-school")}</strong>
                <br />
                {t("mon-fri-3-9")}
              </li>
              <li>
                <strong>{t("text-overnight")}</strong>
                <br />
                {t("mon-fri-9-6-am")}
              </li>
              <li>
                <strong>{t("text-weekends")}</strong>
                <br />
                {t("saturday-sunday")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="button">
        <button
          onClick={(e) => {
            setStep(3);
            setMobile(-32);
            window.scrollTo({ top: 0 });
          }}
        >
          {t("text-back")}
        </button>
        <button
          onClick={(e) => {
            if (
              detailParents.fulltime != "" ||
              detailParents.beforeschool != "" ||
              detailParents.afterschool != "" ||
              detailParents.weekends != "" ||
              detailParents.overnight != ""
            ) {
              setMobile(-46);
              setStep(5);
              setProcess(71);
              window.scrollTo({ top: 0 });
              updateProfile(false);
            } else {
              SCHEDULE_FIELDS.map((e) => {
                afterLoginsField(e.name);
              });
            }
          }}
        >
          {t("text-next")}
        </button>
      </div>
    </div>
  );
}
