import React from "react";

export const FormInput = ({
  label,
  type,
  name,
  placeholder,
  errorField,
  onChange,
  onBlur,
}) => {
  return (
    <div className="form_group">
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        className={errorField[name] === "" ? "" : "bordererror"}
      />
      <span className="errorfield">{errorField[name]}</span>
    </div>
  );
};
