import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../../store/useProfileStore";
import { useRatesStore } from "../../../store/useRatesStore";
import { useErrorFieldStore } from "../../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../../store/useDetailParentsStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../../utils/variables";

export function ServiceTypeOne() {
  const { t } = useTranslation();

  const { errorField, setErrorField } = useErrorFieldStore();
  const { detailParents, setDetailParents } = useDetailParentsStore();

  const { detail } = useProfileStore();

  const { nanyRate, setNanyRate } = useRatesStore();

  return (
    <div className="nanny">
      <h2 className="border">
        <img src="./images/nany_pur.svg" /> {t("text-nanny")}
      </h2>
      <div className="form_group full">
        <label>
          {t("nanny-question")}
          <span className={errorField.liveinnany != "" ? "starred" : ""}>
            *
          </span>
        </label>
        <div className="checkbox">
          <ul onClick={(e) => setErrorField({ ...errorField, liveinnany: "" })}>
            <li>
              <input
                type="radio"
                name="quality"
                onClick={(e) =>
                  setDetailParents({
                    ...detailParents,
                    liveinnany: "Yes",
                  })
                }
                checked={detailParents.liveinnany == "Yes" ? true : false}
              />
              <span> {t("confirm")}</span>
            </li>
            <li>
              <input
                type="radio"
                name="quality"
                onClick={(e) =>
                  setDetailParents({
                    ...detailParents,
                    liveinnany: "No",
                  })
                }
                checked={detailParents.liveinnany == "No" ? true : false}
              />
              <span>{t("objection")}</span>
            </li>
          </ul>
        </div>
        {/* <div className='errorfield'>{error.message}</div>*/}
      </div>
      <div className="form_group full">
        <label>
          {t("Rate per hour")} {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
          <span className={errorField.nanyperhrrate != "" ? "starred" : ""}>
            *
          </span>
        </label>
        {detail && detail.country == "Serbia" ? (
          <div class="wrapper rang">
            <div class="container_slide">
              <div class="slider-track">
                <ul>
                {RATE_NUMBERS.map((value, index) => {
                    const style =
                      nanyRate.min >= value ||
                      (nanyRate.max < value && nanyRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <div class="bggray_slider">
                <ul>
                {RATE_NUMBERS_SECOND.map((value, index) => {
                    const style =
                      nanyRate.min >= value ||
                      (nanyRate.max < value && nanyRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <input
                type="range"
                min="0"
                max="5"
                id="slider-1"
                value={nanyRate.min / 10}
                onChange={(e) => {
                  if (
                    (nanyRate.max > 0 ? nanyRate.max : 60) >
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setNanyRate({
                      ...nanyRate,
                      min: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
              <input
                type="range"
                min="0"
                max="5"
                id="slider-2"
                value={nanyRate.max == 0 ? 50 / 10 : nanyRate.max / 10}
                onChange={(e) => {
                  if (
                    nanyRate.min <
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setNanyRate({
                      ...nanyRate,
                      max: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
            </div>
            <div class="slider two">
              <ul>
                <li>0</li>
                <li>1000</li>
                <li>2000</li>
                <li>3000</li>
                <li>4000</li>
                <li>6000+</li>
              </ul>
            </div>
          </div>
        ) : (
          <div class="wrapper rang">
            <div class="container_slide">
              <div class="slider-track">
                <ul>
                {RATE_NUMBERS.map((value, index) => {
                    const style =
                      nanyRate.min >= value ||
                      (nanyRate.max < value && nanyRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <div class="bggray_slider">
                <ul>
                {RATE_NUMBERS_SECOND.map((value, index) => {
                    const style =
                      nanyRate.min >= value ||
                      (nanyRate.max < value && nanyRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <input
                type="range"
                min="0"
                max="5"
                id="slider-1"
                value={nanyRate.min / 10}
                onChange={(e) => {
                  if (
                    (nanyRate.max > 0 ? nanyRate.max : 60) >
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setNanyRate({
                      ...nanyRate,
                      min: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
              <input
                type="range"
                min="0"
                max="5"
                id="slider-2"
                value={nanyRate.max == 0 ? 50 / 10 : nanyRate.max / 10}
                onChange={(e) => {
                  if (
                    nanyRate.min <
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setNanyRate({
                      ...nanyRate,
                      max: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
            </div>
            <div class="slider two">
              <ul>
                <li>0</li>
                <li>{t("number-10")}</li>
                <li>{t("number-20")}</li>
                <li>{t("number-30")}</li>
                <li>{t("number-40")}</li>
                <li>{t("number-60")}</li>
              </ul>
            </div>
          </div>
        )}
        <span class="price">
          {detail && detail.country == "Serbia"
            ? nanyRate.min * 100 + " - " + nanyRate.max * 100
            : nanyRate.min + " - " + nanyRate.max}
        </span>
        {/* <div className='errorfield'>{error.message}</div> */}
      </div>
    </div>
  );
}
