import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

export const LoginModal = ({
  showLoginModal,
  handleCloseLoginModal,
  setShowModal,
  setDisabled,
  handleDescribeSelect,
  handleSubmitProvider,
  setShowTutorModal,
  setShowTeacherModal,
  setShowProfessionalModal,
  setShowNannyModal,
  newProfession,
  initialCountedit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showLoginModal}
      className="professional_select profchange"
      onHide={handleCloseLoginModal}
    >
      <Modal.Body>
        <div className={"describe more_about "}>
          <img src={window.location.origin + "/images/sign_logo.svg"} />
          <h2>{t("ready-change-profession")}</h2>
          <p>
            
            {t(
              "choose-menu-profession"
            )}
          </p>
          <div className="process_guid ">
            <ul>
              <li
                className={
                  describe && describe.tab1 == "Nanny"
                    ? "active"
                    : "" + "" == ""
                    ? ""
                    : "bordererror"
                }
              >
                <br />
                <Link
                  to=""
                  onClick={(e) => {
                    handleDescribeSelect("tab1", "Nanny");
                  }}
                >
                  <img
                    src={
                      window.location.origin +
                      (describe && describe.tab1 == "Nanny"
                        ? "/images/nanny_fill.svg"
                        : "/images/nanny.svg")
                    }
                  />
                  <span>{t("text-nanny")}</span>
                </Link>
                <div class="personal_preferences">
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (
                          (describe && Object.keys(describe).length != 2) ||
                          describe.tab1
                        ) {
                          setShowModal(true);
                          setDisabled(1);
                        }
                      }}
                      checked={
                        Object.keys(describe).filter((e) => e == "tab1")[0]
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </div>
              </li>
              <li
                className={
                  describe && describe.tab2 == "Special Education Teacher"
                    ? "active"
                    : "" + "" == ""
                    ? ""
                    : "bordererror"
                }
              >
                <br />
                <Link
                  to=""
                  onClick={(e) => {
                    handleDescribeSelect("tab2", "Special Education Teacher");
                  }}
                >
                  <img
                    src={
                      window.location.origin +
                      (describe && describe.tab2 == "Special Education Teacher"
                        ? "/images/teacher_fill.svg"
                        : "/images/teacher.svg")
                    }
                  />
                  <span>{t("spec-education-teacher")}</span>
                </Link>
                <div class="personal_preferences">
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (
                          (describe && Object.keys(describe).length != 2) ||
                          describe.tab2
                        ) {
                          setShowModal(true);
                          setDisabled(2);
                        }
                      }}
                      checked={
                        Object.keys(describe).filter((e) => e == "tab2")[0]
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </div>
              </li>
              <li
                className={
                  describe &&
                  describe.tab3 == "Special Education Paraprofessional"
                    ? "active"
                    : "" + "" == ""
                    ? ""
                    : "bordererror"
                }
              >
                <br />
                <Link
                  to=""
                  onClick={(e) => {
                    handleDescribeSelect(
                      "tab3",
                      "Special Education Paraprofessional"
                    );
                  }}
                >
                  <img
                    src={
                      window.location.origin +
                      (describe &&
                      describe.tab3 == "Special Education Paraprofessional"
                        ? "/images/education_fill.svg"
                        : "/images/education.svg")
                    }
                  />
                  <span>{t("spec-education-paraprofessional")}</span>
                </Link>
                <div class="personal_preferences">
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (
                          (describe && Object.keys(describe).length != 2) ||
                          describe.tab3
                        ) {
                          setShowModal(true);
                          setDisabled(3);
                        }
                      }}
                      checked={
                        Object.keys(describe).filter((e) => e == "tab3")[0]
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </div>
              </li>
              <li
                className={
                  describe && describe.tab4 == "Tutor"
                    ? "active"
                    : "" + "" == ""
                    ? ""
                    : "bordererror"
                }
              >
                <br />
                <Link
                  to=""
                  onClick={(e) => {
                    handleDescribeSelect("tab4", "Tutor");
                  }}
                >
                  <img
                    src={
                      window.location.origin +
                      (describe && describe.tab4 == "Tutor"
                        ? "/images/tutor_fill.svg"
                        : "/images/tutor.svg")
                    }
                  />
                  <span>{t("text-tutor")}</span>
                </Link>
                <div class="personal_preferences">
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (
                          (describe && Object.keys(describe).length != 2) ||
                          describe.tab4
                        ) {
                          setShowModal(true);
                          setDisabled(4);
                        }
                      }}
                      checked={
                        Object.keys(describe).filter((e) => e == "tab4")[0]
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <button className="back_sign" onClick={handleCloseLoginModal}>
            {t("text-cancel")}
          </button>
          <button
            className="back_sign"
            onClick={(e) => {
              if (initialCountedit <= 2) {
                newProfession.tab1
                  ? setShowNannyModal(true)
                  : setShowNannyModal(false);
                newProfession.tab2
                  ? setShowTeacherModal(true)
                  : setShowTeacherModal(false);
                newProfession.tab3
                  ? setShowProfessionalModal(true)
                  : setShowProfessionalModal(false);
                newProfession.tab4
                  ? setShowTutorModal(true)
                  : setShowTutorModal(false);
                handleSubmitProvider(true);
              } else {
                toast.error(
                  "you can change profession only two times in a year"
                );
              }
            }}
          >
            {t("Continue")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
