import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { api } from "../../urls";
import {
  CAPITAL_LETTERS,
  NUMBERS,
  SPECIAL_CHARACTERS,
  VALID_PASSWORD,
} from "../../utils/variables";

export function ResetPasswordPage() {
  const { t } = useTranslation();

  const [formLogin, setFormLogin] = useState({
    password: "",
    remember: false,
    c_password: "",
  });
  const [errorField, setErrorField] = useState({
    password: "",
    c_password: "",
  });

  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [postSuccessfully, setPostSuccessfully] = useState("");
  const [verify, setVerify] = useState(false);

  const slugData = useParams();
  const id = slugData.id;
  const token = slugData.token;

  const validateLoginField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        errorField.password = VALID_PASSWORD.test(value) ? "" : "Demo@123";
        break;
      case "c_password":
        errorField.c_password =
          value != formLogin.password ? t("Password did not match.") : "";
        break;
      default:
        break;
    }
    setFormLogin({ ...formLogin, [name]: value });
    setErrorField(errorField);
  };

  const handleResetEmail = () => {
    if (errorField.password == "" && errorField.c_password == "") {
      const formdata = new FormData();
      formdata.append("id", id);
      formdata.append("token", token);
      formdata.append("password", formLogin.password);
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(api + "/api/changeresetpassword", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            setPostSuccessfully(
              t("Your password has been reset successfully!")
            );
            setTimeout(() => {
              window.location.href = "/";
              setPostSuccessfully("");
            }, 4000);
          }
        });
    }
  };

  const handleRedirect = () => {
    window.location.href = "/";
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerify(true);
  }
  return (
    <>
      {postSuccessfully != "" ? (
        <div className="popup_status" id="popup_status">
          {postSuccessfully}
        </div>
      ) : (
        ""
      )}
      <div className="promocode_content reset reset2">
        <div className="">
          <h2>{t("reset-password")}</h2>
          <form>
            <div className="form_group">
              <label>{t("text-password")}</label>
              <input
                type={isPassword ? "text" : t("password")}
                placeholder=". . .  . . . . . . ."
                className={errorField.password == "" ? "" : "bordererror"}
                name="password"
                onChange={(e) => validateLoginField(e)}
              />
              <div className="eyes">
                <input
                  type={"checkbox"}
                  onClick={(e) => {
                    setIsPassword(!isPassword);
                  }}
                />
                <i></i>
              </div>
              {errorField.password || errorField.password ? (
                <div className="password_rule errorfield ">
                  <p>
                    {t(
                      "password-bw-8-15-char"
                    )}
                    <br />

                    {CAPITAL_LETTERS.test(formLogin.password)
                      ? ""
                      : t("- at least one uppercase letter")}
                    <br />
                    {NUMBERS.test(formLogin.password)
                      ? ""
                      : t("- at least one number digit")}
                    <br />
                    {SPECIAL_CHARACTERS.test(formLogin.password)
                      ? ""
                      : t(
                          "- at least one special character -for example:  #, @, !"
                        )}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form_group">
              <label>{t("Confirm password")}</label>
              <input
                type={isNewPassword ? "text" : "password"}
                placeholder=". . .  . . . . . . . "
                className={errorField.c_password == "" ? "" : "bordererror"}
                name="c_password"
                onChange={(e) => validateLoginField(e)}
              />
              <div className="eyes">
                <input
                  type={"checkbox"}
                  onClick={(e) => {
                    setIsNewPassword(!isNewPassword);
                  }}
                />
                <i></i>
              </div>
              <span className="errorfield">{errorField.c_password}</span>
            </div>

            <div className="rmfog">
              <label>
                <input type={"checkbox"} /> <span>{t("remember-me")}</span>
              </label>
            </div>
            <div className="captch">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
            </div>
          </form>
          <div className="buttons reset3">
            <button
              className="rest"
              disabled={!verify}
              onClick={handleResetEmail}
            >
              {t("Reset password")}
            </button>
            <button class="rest" onClick={handleRedirect}>
              {t("text-cancel")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
