import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SafetySection } from "../../components/homePage/SafetySection";
import { GuidSection } from "../../components/homePage/GuidSection";
import { PromoCode } from "../../components/common/PromoCode";
import { Testimonials } from "../../components/common/Testimonials";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

export function HomePage() {
  const { t } = useTranslation();
  const [isPromo, setIsPromo] = useState(false);

  return (
    <>
      <Header page={"home"} />
      {!isPromo && (
        <div className="promocode">
          <p>
            <PromoCode />
            <button onClick={(a) => setIsPromo(true)}>+</button>
          </p>
        </div>
      )}

      <div className="container-fluid">
        <div className="container">
          <div className="better_care">
            <h2>{t("title-better")} </h2>
            <div className="right_banner">
              <img src="./images/home_right.svg" />
            </div>
            <div className="service_gurid">
              <GuidSection />
            </div>
          </div>
          <SafetySection />
        </div>
      </div>
      <Testimonials />
      <Footer />
    </>
  );
}
