import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../../store/useProfileStore";
import { useRatesStore } from "../../../store/useRatesStore";
import { useErrorFieldStore } from "../../../store/useErrorsFieldsStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../../utils/variables";

export function ServiceTypeTwo() {
  const { t } = useTranslation();

  const { errorField } = useErrorFieldStore();

  const { detail } = useProfileStore();

  const { generalRate, setGeneralRate } = useRatesStore();
  return (
    <div className="special_education big">
      <h2 className="border">
        <img src="./images/special_education.svg" /> {t("education-teacher")}
      </h2>
      <div className="form_group full">
        <label>
          {t("Rate per hour")} {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
          <span className={errorField.nanyperhrrate != "" ? "starred" : ""}>
            *
          </span>
        </label>
        {detail && detail.country == "Serbia" ? (
          <div class="wrapper rang">
            <div class="container_slide">
              <div class="slider-track">
                <ul>
                {RATE_NUMBERS.map((value, index) => {
                    const style =
                      generalRate.min >= value ||
                      (generalRate.max < value && generalRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <div class="bggray_slider">
                <ul>
                {RATE_NUMBERS_SECOND.map((value, index) => {
                    const style =
                      generalRate.min >= value ||
                      (generalRate.max < value && generalRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <input
                type="range"
                min="0"
                max="5"
                id="slider-1"
                value={generalRate.min / 10}
                onChange={(e) => {
                  if (
                    (generalRate.max > 0 ? generalRate.max : 60) >
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setGeneralRate({
                      ...generalRate,
                      min: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
              <input
                type="range"
                min="0"
                max="5"
                id="slider-2"
                value={generalRate.max == 0 ? 50 / 10 : generalRate.max / 10}
                onChange={(e) => {
                  if (
                    generalRate.min <
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setGeneralRate({
                      ...generalRate,
                      max: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
            </div>
            <div class="slider two">
              <ul>
                <li>0</li>
                <li>1000</li>
                <li>2000</li>
                <li>3000</li>
                <li>4000</li>
                <li>6000+</li>
              </ul>
            </div>
          </div>
        ) : (
          <div class="wrapper rang">
            <div class="container_slide">
              <div class="slider-track">
                <ul>
                {RATE_NUMBERS.map((value, index) => {
                    const style =
                      generalRate.min >= value ||
                      (generalRate.max < value && generalRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <div class="bggray_slider">
                <ul>
                {RATE_NUMBERS_SECOND.map((value, index) => {
                    const style =
                      generalRate.min >= value ||
                      (generalRate.max < value && generalRate.max > 0)
                        ? { backgroundColor: "#E5E5E5" }
                        : {};
                    return <li key={index} style={style}></li>;
                  })}
                </ul>
              </div>
              <input
                type="range"
                min="0"
                max="5"
                id="slider-1"
                value={generalRate.min / 10}
                onChange={(e) => {
                  if (
                    (generalRate.max > 0 ? generalRate.max : 60) >
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setGeneralRate({
                      ...generalRate,
                      min: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
              <input
                type="range"
                min="0"
                max="5"
                id="slider-2"
                value={generalRate.max == 0 ? 50 / 10 : generalRate.max / 10}
                onChange={(e) => {
                  if (
                    generalRate.min <
                    (e.target.value == 5 ? 60 : e.target.value * 10)
                  ) {
                    setGeneralRate({
                      ...generalRate,
                      max: e.target.value == 5 ? 60 : e.target.value * 10,
                    });
                  }
                }}
              />
            </div>
            <div class="slider two">
              <ul>
                <li>0</li>
                <li>{t("number-10")}</li>
                <li>{t("number-20")}</li>
                <li>{t("number-30")}</li>
                <li>{t("number-40")}</li>
                <li>{t("number-60")}</li>
              </ul>
            </div>
          </div>
        )}
        <span class="price">
          {detail && detail.country == "Serbia"
            ? generalRate.min * 100 + " - " + generalRate.max * 100
            : generalRate.min + " - " + generalRate.max}
        </span>
        {/* <div className='errorfield'>{error.message}</div> */}
      </div>
    </div>
  );
}
