import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { DATA_COUNTRY } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "../../utils/variables";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { fetchSubmitEnquiry, fetchWorkWithUs } from "../../api/countryApi";

export function WorkUsPage() {
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [contactCode, setContactCode] = useState({ code: "", flag: "" });
  const [countryCodeVisible, setCountryCodeVisible] = useState(false);
  const [formFields, setFormFields] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleShowModal = () => setShowModal(true);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    switch (name) {
      case "email":
        error = EMAIL_REGEX.test(value) ? "" : t("email-valid");
        break;
      case "firstName":
        error = value.length < 2 ? t("Minimum 2 characters required") : "";
        break;
      case "lastName":
        error = value.length < 2 ? t("Minimum 2 characters required") : "";
        break;
      case "phone":
        error = value.length < 8 ? t("required") : "";
        break;
      case "message":
        error = value.length < 2 ? t("required") : "";
        error = value.length > 300 ? t("Maximum 300 characters valid") : error;
        break;
      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    setErrors({ ...errors, [name]: error });
  };

  const validateFields = () => {
    const newErrors = {
      email: formFields.email === "" ? "required" : "",
      firstName: formFields.firstName === "" ? "required" : "",
      lastName: formFields.lastName === "" ? "required" : "",
      phone: formFields.phone === "" ? "required" : "",
      message: formFields.message === "" ? "required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (validateFields()) {
      try {
        const result = await fetchSubmitEnquiry(formFields, contactCode);

        result.success ? handleShowModal() : handleCloseModal();
      } catch (error) {
        console.error("Error submitting enquiry:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const toggleCountryCodeVisibility = () => {
    setCountryCodeVisible(!countryCodeVisible);
  };

  const selectCountryCode = (code, flag) => {
    setContactCode({ code, flag });
    toggleCountryCodeVisibility();
  };

  const fetchInvestorData = async () => {
    try {
      const result = await fetchWorkWithUs();
      setInitialData(result.data);
    } catch (error) {
      console.error("Error fetching work with us data:", error);
    }
  };

  useEffect(() => {
    fetchInvestorData();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid position-relative">
        <div className="container">
          <div className="contact">
            <h2>{t("work-with-us", { defaultValue: initialData.title })}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: t("content", { defaultValue: initialData.description }),
              }}
              className="workus"
            />
            <h3>{t("Send us message")}</h3>
            <div className="form border">
              <form>
                <div className="form_group">
                  <label>
                    {t("First name")}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("text-type")}
                    name="firstName"
                    onChange={handleFieldChange}
                    className={errors.firstName ? "bordererror" : ""}
                  />
                  <span className="errorfield">{errors.firstName}</span>
                </div>
                <div className="form_group">
                  <label>
                    {t("Last name")}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("text-type")}
                    name="lastName"
                    onChange={handleFieldChange}
                    className={errors.lastName ? "bordererror" : ""}
                  />
                  <span className="errorfield">{errors.lastName}</span>
                </div>
                <div className="form_group">
                  <label>
                    {t("work-email-address")}
                    <span>*</span>
                  </label>
                  <input
                    type="email"
                    placeholder={t("text-type")}
                    name="email"
                    onChange={handleFieldChange}
                    className={errors.email ? "bordererror" : ""}
                  />
                  <span className="errorfield">{errors.email}</span>
                </div>
                <div className="form_group part2">
                  <label>{t("Telephone number")}</label>
                  <div
                    className="country_flag"
                    onClick={toggleCountryCodeVisibility}
                  >
                    {contactCode.flag && (
                      <img src={contactCode.flag} alt="Country flag" />
                    )}
                    {contactCode.code}
                  </div>
                  <ul style={countryCodeVisible ? {} : { display: "none" }}>
                    {DATA_COUNTRY.data.map((country) => (
                      <li
                        key={country.dial_code}
                        onClick={() =>
                          selectCountryCode(country.dial_code, country.flag)
                        }
                      >
                        <img src={country.flag} alt="Country flag" />
                        {country.country} {country.dial_code}
                      </li>
                    ))}
                  </ul>
                  <div className="citydetail">
                    <input
                      type="number"
                      placeholder={t("text-type")}
                      name="phone"
                      onChange={handleFieldChange}
                      className={errors.phone ? "bordererror" : ""}
                      style={{
                        paddingLeft: contactCode.code ? "32px" : "20px",
                        borderRadius: "0px 40px 40px 0px",
                      }}
                    />
                    <div className="errorfield">{errors.phone}</div>
                  </div>
                </div>
                <div className="form_group full">
                  <label>
                    {t("your-message")}
                    <span>*</span>
                  </label>
                  <textarea
                    rows={2}
                    placeholder={t("text-type")}
                    maxLength="300"
                    name="message"
                    onChange={handleFieldChange}
                    className={errors.message ? "bordererror" : ""}
                  ></textarea>
                  <div className="errorfield">{errors.message}</div>
                  <span>{t("characters-300")}</span>
                </div>
              </form>
              <button onClick={handleSubmit} disabled={isSubmitting}>
                {t("text-submit")}
              </button>
            </div>
            <div className="follow">
              <h3>{t("Follow us on social media")}</h3>
              <ul>
                <li>
                  <Link to="#">
                    <img src="./images/facebookp.svg" alt="Facebook" />
                  </Link>
                  <Link to="#">
                    <img src="./images/indip.svg" alt="LinkedIn" />
                  </Link>
                  <Link to="#">
                    <img src="./images/twiterp.svg" alt="Twitter" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="promocode_content younger contactpop">
            <Link to="" onClick={handleCloseModal}>
              +
            </Link>
            <h5>{t("contact-us-thank")}</h5>
            <p>
              {t("message-senscare")}
              <br />
              {t("allow-up-24")}
            </p>
            <button onClick={handleCloseModal}>{t("text-ok")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
