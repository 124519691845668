import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export function LocationContent(props) {
  const { t } = useTranslation();

  const [address, setAddress] = useState(
    props.typedefault && props.typedefault != "" ? props.typedefault : ""
  );

  const handleChange = (address) => {
    setAddress(address);
    address == ""
      ? props.let("res", "getLatLng", address)
      : setAddress(address);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const res = results[0];
      setAddress(address);
      props.let(res, await getLatLng(res), address);

      for (let i = 0; i < res.address_components.length; i++) {
        for (let j = 0; j < res.address_components[i].types.length; j++) {
          const type = res.address_components[i].types[j];
          if (type === "postal_code" || type === "street_number") {
            const componentAddress = res.address_components[i].long_name;
            if (props.type) {
              setAddress(componentAddress);
              props.let(res, await getLatLng(res), componentAddress);
            } else {
              setAddress(address);
              props.let(res, await getLatLng(res), address);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder:
                props.type && props.type == "my-fil-zip"
                  ? t("zip-code")
                  : t("text-type"),
              className: "location-search-input",
              id: props.type ? props.type : "",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
