import { create } from "zustand"

export const useErrorFieldStore = create((set) => ({
    errorField: {
      about: "",
      video: "",
      file_path: "",
      childneedcareno: "",
      childage: "",
      childsocialneed: "",
      yourkidallapplicable: "",
      childneediep: "",
      childmedicalcondition: "",
      childmedicaldescription: "",
      childanyallergies: "",
      childallergiesdescription: "",
      liveinnany: "",
      nanyperhrrate: "",
      tutorliketoteach: "",
      tutorintrestedonlinecls: "",
      tutorperhrrate: "",
      tutorexp: "",
      tutorstartdate: "",
      tutorintrestedin: "",
      preferredageofprovider: "",
      fulltime: "",
      beforeschool: "",
      afterschool: "",
      weekends: "",
      overnight: "",
      candidatespeaks: "",
      occasionaltraveling: "",
      cookingforkids: "",
      lighthousework: "",
      preferredcountry: "",
      childtransportation: "",
      workingabroad: "",
      preferredcity: "",
      phone: "",
      jobs: "",
      jobs_description: "",
      jobs2: "",
      jobs_description2: "",
      seperhrrate: "",
      seterhrrate: "",
    },
  
    setErrorField: (newErrorField) => set({ errorField: newErrorField }),
  }));