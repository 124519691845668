import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import { FormInput } from "../../components/common/FormInput";
import { useFormStore } from "../../store/useFormStore";
import { LocationContent } from "../../components/common/LocationContent";

import {
  CAPITAL_LETTERS,
  NUMBERS,
  SPECIAL_CHARACTERS,
} from "../../utils/variables";
import { DATA_COUNTRY } from "../../utils/data";
import { validateFieldLogin } from "../../utils/helperValidations";

export const ProviderSignupForm = ({
  today,
  handleShowModal,
  handleCodeSelect,
  contactCode,
  code,
  setContactCode,
}) => {
  const { t } = useTranslation();

  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isPassword, setIsPassword] = useState(false);

  const { formLogin, setFormLogin, errorField, setErrorField } = useFormStore();

  const getLatLong = (latlong2) => {
    latlong2.address_components.map((e) => {
      e.types.map((a) => {
        if (a == "country") {
          setErrorField((prevErrorField) => ({
            ...prevErrorField,
            country: "",
            address: "",
          }));

          setFormLogin((prevFormLogin) => ({
            ...prevFormLogin,
            country: e.long_name,
            address: latlong2.formatted_address,
          }));
        }
      });
    });
  };

  const handleChange = (e) => validateLoginField(e);

  const validateLoginField = (e) => {
    const { name, value } = e.target;
    const error = validateFieldLogin(
      name,
      value,
      formLogin,
      t,
      handleShowModal
    );

    setErrorField((prevErrorField) => ({
      ...prevErrorField,
      [name]: error,
    }));

    setFormLogin((prevFormLogin) => ({
      ...prevFormLogin,
      [name]: value,
    }));
  };

  return (
    <form>
      <FormInput
        label={t("First Name*")}
        type="text"
        placeholder={t("text-type")}
        name="first_name"
        errorField={errorField}
        onChange={handleChange}
      />
      <FormInput
        label={t("Last Name*")}
        type="text"
        placeholder={t("text-type")}
        name="last_name"
        errorField={errorField}
        onChange={handleChange}
      />
      <FormInput
        label={t("Username*")}
        type="text"
        placeholder={t("text-type")}
        name="username"
        errorField={errorField}
        onChange={handleChange}
      />
      <FormInput
        label={t("Email address*")}
        type="email"
        placeholder={t("text-type")}
        name="email"
        errorField={errorField}
        onChange={handleChange}
      />

      <div className="form_group">
        <label>{t("Password*")}</label>
        <input
          type={isPassword ? "text" : "password"}
          placeholder=". . . . . . . . ."
          name="password"
          onChange={(e) => validateLoginField(e)}
          className={errorField.password == "" ? "" : "bordererror"}
        />
        <div className="eyes">
          <input
            type={"checkbox"}
            onClick={(e) => {
              setIsPassword(!isPassword);
            }}
          />
          <i></i>
        </div>
        {errorField.password || errorField.password ? (
          <div className="password_rule errorfield signuppassword">
            <p>
              {t("password-bw-8-15-char")}
              <br />
              {CAPITAL_LETTERS.test(formLogin.password)
                ? ""
                : t("- at least one uppercase letter")}
              <br />
              {NUMBERS.test(formLogin.password)
                ? ""
                : t(t("- at least one number digit"))}
              <br />
              {SPECIAL_CHARACTERS.test(formLogin.password)
                ? ""
                : t("- at least one special character -for example:  #, @, !")}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="form_group">
        <label>{t("Repeat password*")}</label>
        <input
          type={isNewPassword ? "text" : "password"}
          placeholder=". . . . . . . . . . "
          name="c_password"
          onChange={(e) => validateLoginField(e)}
          className={errorField.c_password == "" ? "" : "bordererror"}
        />
        <div className="eyes">
          <input
            type={"checkbox"}
            onClick={(e) => {
              setIsNewPassword(!isNewPassword);
            }}
          />
          <i></i>
        </div>
        <span className="errorfield">{errorField.c_password}</span>
      </div>

      <div className="form_group">
        <label>{t("Date of birth*")}</label>
        <input
          type="date"
          min="1960-01-01"
          value={formLogin.dob}
          name="dob"
          className={errorField.dob == "" ? "" : "bordererror"}
        />
        <DatePicker
          showYearDropdown={true}
          yearDropdownItemNumber={3}
          className={errorField.dob != "" ? "bordererror" : ""}
          minDate={new Date("1960-01-01")}
          maxDate={today}
          selected={formLogin.dob != "" ? new Date(formLogin.dob) : today}
          name="dob"
          onChange={(date) => {
            setErrorField({ ...errorField, dob: "" });
            new Date().getFullYear() - date.getFullYear() > 16
              ? setFormLogin({
                  ...formLogin,
                  dob:
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0"),
                })
              : handleShowModal();
          }}
        />
        <span className="errorfield">{errorField.dob}</span>
      </div>

      <div className="form_group number">
        <label>{t("Mobile phone*")}</label>
        <input
          type="number"
          placeholder={t("text-type")}
          name="phone"
          onChange={(e) => {
            validateLoginField(e);
            setFormLogin({
              ...formLogin,
              phone:
                e.target.value.length <= 10 ? e.target.value : formLogin.phone,
            });
          }}
          className={errorField.phone == "" ? "" : "bordererror"}
          maxLength="10"
          value={formLogin.phone}
        />
        <div
          className={
            errorField.phone == "" ? "country_flag" : "bordererror country_flag"
          }
          onClick={(e) => handleCodeSelect()}
        >
          <img src={contactCode.flag} /> {contactCode.code}
        </div>
        <ul style={code ? { display: "none" } : {}}>
          {DATA_COUNTRY.data.map((e) => {
            return (
              <li
                onClick={(a) => {
                  handleCodeSelect();
                  setContactCode({
                    flag: e.flag,
                    code: e.dial_code,
                  });
                }}
              >
                <img src={e.flag} /> {e.country + " " + " " + e.dial_code}
              </li>
            );
          })}
        </ul>
        <span className="errorfield">{errorField.phone}</span>
      </div>

      <div className="form_group">
        <label>{t("Address*")}</label>
        <div className={errorField.address == "" ? "" : "bordererror"}>
          <LocationContent let={getLatLong} />
        </div>
        <span className="errorfield">{errorField.address}</span>
      </div>
      <div className="form_group part2">
        <div className="citydetail">
          <label>{t("City*")}</label>
          <input
            type="text"
            placeholder={t("text-type")}
            name="city"
            onChange={(e) => validateLoginField(e)}
            className={errorField.city == "" ? "" : "bordererror"}
          />
          <span className="errorfield">{errorField.city}</span>
        </div>
        <div className="citydetail">
          <label>{t("Zip code*")}</label>
          <input
            type="number"
            placeholder={t("text-type")}
            name="zip"
            onChange={(e) => validateLoginField(e)}
            className={errorField.zip == "" ? "" : "bordererror"}
          />
          <span className="errorfield">{errorField.zip}</span>
        </div>
      </div>
      <div className="form_group">
        <label>{t("Country*")}</label>
        <input
          type="text"
          name="country"
          onChange={(e) => validateLoginField(e)}
          value={formLogin.country}
          className={errorField.country == "" ? "" : "bordererror"}
        />
        <span className="errorfield">{errorField.country}</span>
      </div>
      <div className="form_group">
        <label>{t("How did you hear about us?*")}</label>
        <select
          name="hearAboutUs"
          onChange={(e) => console.log(validateLoginField(e), "EEE")}
          className={errorField.hearAboutUs == "" ? "" : "bordererror"}
        >
          <option>{t("choose-list")}</option>
          <option value={t("Internet browsing ")}>
            {t("Internet browsing")}
          </option>
          <option value={"Friend recommendation"}>
            {t("Friend recommendation")}
          </option>
          <option value={t("Facebook")}>{t("Facebook")}</option>
          <option value={t("Our website")}>{t("Our website")} </option>
          <option value={t("text-other")}>{t("text-other")}</option>
        </select>
        <span className="errorfield">{errorField.hearAboutUs}</span>
      </div>
    </form>
  );
};
