import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";

import { usePersonalInfoStore } from "../../../store/usePersonalInfoStore";
import {
  CAPITAL_LETTERS,
  DEFAULT_API,
  NUMBERS,
  SPECIAL_CHARACTERS,
  VALID_PASSWORD,
} from "../../../utils/variables";

export const ChangePasswordModal = ({
  showModal,
  handleClose,
  setShowModal,
  setShowSuccessModal,
}) => {
  const { t } = useTranslation();

  const [isPassword, setIsPassword] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isRepeatPassword, setIsRepeatPassword] = useState(false);

  const { password, setPassword, errorPassword, setErrorPassword } =
    usePersonalInfoStore();

  const validateLoginField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "oldpassword":
        errorPassword.oldpassword = value ? "" : "required";
        break;
      case "c_password":
        errorPassword.c_password =
          value != password.n_password ? t("Password did not match") : "";
        break;
      case "n_password":
        errorPassword.n_password = VALID_PASSWORD.test(value)
          ? ""
          : t("Password did not match");
        break;
      default:
        break;
    }
    setPassword({ ...password, [name]: value });
    setErrorPassword(errorPassword);
  };

  const handleChangePassword = () => {
    if (password.n_password && password.oldpassword && password.c_password) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        current_password: password.oldpassword,
        new_password: password.n_password,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(DEFAULT_API + "/api/v1/passwordchange", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.type == "Failed") {
            setErrorPassword({
              oldpassword: t("password did not match"),
              c_password: "",
              n_password: "",
            });
          } else {
            setShowSuccessModal(true);
            setShowModal(false);
            setErrorPassword({
              oldpassword: "",
              c_password: "",
              n_password: "",
            });
            localStorage.removeItem("id");
            localStorage.removeItem("token");
          }
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorPassword({
        oldpassword: password.oldpassword ? "" : t("Required"),
        c_password: password.c_password ? "" : t("Required"),
        n_password: password.n_password ? "" : t("Required"),
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body>
        <div
          className="promocode_content younger reset reset2"
          style={{ paddingBottom: "40px" }}
        >
          <Link to="" onClick={handleClose}>
            +
          </Link>
          <div className="pdng">
            <h2>{t("Change Password")}</h2>
            <form>
              <div className="form_group">
                <label>{t("Old password")}</label>
                <input
                  type={isNewPassword ? "text" : "password"}
                  placeholder=". . .  . . . . . . ."
                  name="oldpassword"
                  onChange={(e) => validateLoginField(e)}
                />
                <div className="eyes">
                  <input
                    type={"checkbox"}
                    onClick={(e) => {
                      setIsNewPassword(!isNewPassword);
                    }}
                  />
                  <i></i>
                </div>
                <span className="errorfield">{errorPassword.oldpassword}</span>
              </div>
              <div className="form_group">
                <label>{t("New password")}</label>
                <input
                  type={isRepeatPassword ? "text" : "password"}
                  placeholder=". . .  . . . . . . ."
                  name="n_password"
                  onChange={(e) => validateLoginField(e)}
                />
                <div className="eyes">
                  <input
                    type={"checkbox"}
                    onClick={(e) => {
                      setIsRepeatPassword(!isRepeatPassword);
                    }}
                  />
                  <i></i>
                </div>
              </div>
              <div className="form_group">
                <label>{t("Confirm new password")}</label>
                <input
                  type={isPassword ? "text" : "password"}
                  placeholder=". . .  . . . . . . . "
                  name="c_password"
                  onChange={(e) => validateLoginField(e)}
                />
                <div className="eyes">
                  <input
                    type={"checkbox"}
                    onClick={(e) => {
                      setIsPassword(!isPassword);
                    }}
                  />
                  <i></i>
                </div>
                <span className="errorfield">{errorPassword.c_password}</span>
                {errorPassword.n_password || errorPassword.n_password ? (
                  <div className="password_rule errorfield ">
                    <p>
                      {t("password-btw-8-15-char-contain")}
                      <br />
                      {CAPITAL_LETTERS.test(password.n_password)
                        ? ""
                        : t("- at least one uppercase letter")}
                      <br />
                      {NUMBERS.test(password.n_password)
                        ? ""
                        : t("- at least one number digit")}
                      <br />
                      {SPECIAL_CHARACTERS.test(password.n_password)
                        ? ""
                        : t(
                            "- at least one special character -for example:  #, @, !"
                          )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="captch">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={() => {}}
                />
              </div>
            </form>
            <div className="buttons reset3">
              <button
                className="rest"
                disabled={!false}
                onClick={handleChangePassword}
              >
                {t("Change")}
              </button>
              <button className="rest" onClick={handleClose}>
                {t("text-cancel")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
