import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ProviderModal = ({ isProviderModal, handleCloseLoginProvider}) => {
  const { t } = useTranslation();
  
  return (
    <Modal show={isProviderModal} onHide={handleCloseLoginProvider}>
      <Modal.Body>
        <div className="promocode_content login_first">
          <Link to="" onClick={handleCloseLoginProvider}>
            +
          </Link>
          <h2>{t("complete-profile-perfect-job")}</h2>
          <img src={window.location.origin + "/images/landing.png"} />
          <p>
            {t(
              "answer-question-find-job"
            )}
          </p>
          <button>
            <Link to={"/complete-provider-profile"}>
              {t("text-complete-profile")}
            </Link>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
