import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { api } from "../../urls";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useRatesStore } from "../../store/useRatesStore";
import { useControlsStore } from "../../store/useControlsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useProfileDetailsStore } from "../../store/useProfileDetailsStore";
import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useContactJobStore } from "../../store/useContactJobStore";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

import {
  saveToLocalStorage,
  validationLoginField,
} from "../../utils/helperFunctions";

import { updateProfile } from "./updateProfile";

import { StepOneContent } from "./StepOneContent";
import { StepTwoContent } from "./StepTwoContent";
import { StepFourContent } from "./StepFourContent";
import { StepFiveContent } from "./StepFiveContent";
import { StepSixContent } from "./StepSixContent";
import { StepSevenContent } from "./StepSevenContent";
import { StepThreeContent } from "./StepThreeContent/StepThreeContent";

export function CreateParentProfile() {
  const { t } = useTranslation();

  const { errorField, setErrorField } = useErrorFieldStore();
  const { detailParents, setDetailParents } = useDetailParentsStore();
  const { job, setJob } = useContactJobStore();

  const { detail, isUserType, setDetail, setIsUserType } = useProfileStore();

  const {
    nanyRate,
    generalRate,
    secondaryRate,
    tutorRate,
    setNanyRate,
    setGeneralRate,
    setSecondaryRate,
    setTutorRate,
  } = useRatesStore();

  const {
    showModal,
    refresh,
    children,
    step,
    process,
    mobile,
    setShowModal,
    setRefresh,
    setChildren,
    setCalendarType,
    setStep,
  } = useControlsStore();

  const {
    qualifications,
    oralSpeak,
    habit,
    setQualifications,
    setOralSpeak,
    setHabit,
  } = useProfileDetailsStore();

  const { selectCat, childrenAge, setSelectCat, setChildrenAge } =
    useAdditionalDetailsStore();

  const closeLoginModal = () => {
    setShowModal(false);
  };

  const redirectToSearchProviders = () => {
    window.location.href = "/search-providers";
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
        setTimeout(() => {
          const x = Object.keys(detailParents).forEach(function (key) {
            detailParents[key] =
              result.data[key] != null ? result.data[key] : "";
            setDetailParents({ ...detailParents });
          });
        }, 500);
        setChildren(result.data.childneedcareno);
        setSelectCat(result.data.childage != null ? result.data.childage : []);
        setChildrenAge(
          result.data.yourkidallapplicable != null
            ? result.data.yourkidallapplicable
            : []
        );
        setNanyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setGeneralRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setSecondaryRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        setOralSpeak(
          result.data.candidatespeaks != null
            ? result.data.candidatespeaks
            : { ...oralSpeak }
        );
        setHabit({
          licence: result.data.childtransportation
            ? result.data.childtransportation
            : "",
          kids: result.data.cookingforkids ? result.data.cookingforkids : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.occasionaltraveling
            ? result.data.occasionaltraveling
            : "",
        });
        result.data.job[0]
          ? setTimeout(() => {
              setDetailParents({
                ...detailParents,
                jobs: result.data.job[0].title,
                jobs_description: result.data.job[0].description,
                jobs2: result.data.job[1].title ? result.data.job[1].title : "",
                jobs_description2: result.data.job[1].description
                  ? result.data.job[1].description
                  : "",
              });
              setJob({
                ...job,
                id: result.data.job[0].id,
                id2: result.data.job[1].id ? result.data.job[1].id : "",
                plateformonsocialmedia:
                  result.data.job[0].plateformonsocialmedia != ""
                    ? result.data.job[0].plateformonsocialmedia
                    : "Yes",
                plateformonsocialmedia2: result.data.job[1]
                  .plateformonsocialmedia
                  ? result.data.job[1].plateformonsocialmedia
                  : "Yes",
              });
            }, 900)
          : setJob({ ...job });
        setStep(
          result.data.step != null
            ? parseInt(result.data.step) <= 7
              ? result.data.job[0].plateformonsocialmedia
                ? 1
                : parseInt(result.data.step)
              : 1
            : 1
        );
      })
      .catch((error) => console.log("error", error));
  };

  const afterLoginsField = (name) => {
    const newErrorField = validationLoginField(
      name,
      detailParents,
      children,
      selectCat,
      childrenAge,
      nanyRate,
      generalRate,
      secondaryRate,
      qualifications,
      tutorRate,
      oralSpeak,
      habit
    );
    setErrorField((prevErrorField) => ({
      ...prevErrorField,
      ...newErrorField,
    }));

    setTimeout(() => {
      setDetail(detail);
      setRefresh(name);
      window.scrollTo({ top: 0 });
    }, 900);

    setTimeout(() => {
      setDetail(detail);
      setRefresh(name.split());
      window.scrollTo({ top: 0 });
    }, 1500);
  };

  useEffect(() => {
    if (isUserType) {
      handleProfileData();
      setIsUserType(false);
      localStorage.getItem("user_type") == "parents" &&
        localStorage.getItem("user_type");
    }
  }, [detail, detailParents, errorField, refresh, job]);

  useEffect(() => {
    saveToLocalStorage("step", step)

  }, [step]);

  useEffect(() => {
    saveToLocalStorage("procsess", process)
  }, [process])

  console.log(localStorage.getItem("process"))

  return (
    <>
      <Header page={"proile"} />
      <div className="container-fluid">
        <div className="container">
          <div className="Profile_complete">
            <div className="left">
              <div className="stop">
                <h3>
                  {t("profile-progress")} <span>{process}%</span>
                </h3>
                <span>
                  <div class="progress">
                    <div class="color" style={{ width: process + "%" }}></div>
                  </div>
                </span>
              </div>
              <div
                class={
                  "progress-circle" +
                  " " +
                  "p" +
                  process +
                  " " +
                  (process > 50 ? "over50" : "")
                }
              >
                <span>{process}%</span>
                <div class="left-half-clipper">
                  <div class="first50-bar"></div>
                  <div class="value-bar"></div>
                </div>
              </div>
              <ul
                style={
                  window.screen.width < 767
                    ? { transform: "translateX(" + mobile + "% )" }
                    : {}
                }
              >
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia
                    ) {
                      setStep(1);
                    }
                  }}
                  className={step == 1 ? "active" : step >= 1 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("about-person")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia
                    ) {
                      setStep(2);
                    }
                  }}
                  className={step == 2 ? "active" : step >= 2 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("text-kids")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia
                    ) {
                      setStep(3);
                    }
                  }}
                  className={step == 3 ? "active" : step >= 3 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("job-preferences")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia
                    ) {
                      setStep(4);
                      setTimeout(() => {
                        setCalendarType(1);
                      }, 500);
                    }
                  }}
                  className={step == 4 ? "active" : step >= 4 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("availability")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia
                    ) {
                      setStep(5);
                    }
                  }}
                  className={step == 5 ? "active" : step >= 5 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("additional-info")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia
                    ) {
                      setStep(6);
                    }
                  }}
                  className={step == 6 ? "active" : step >= 6 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("security-verification")}</p>
                </li>
                <li
                  onClick={(e) => {
                    if (
                      detail.job[0].plateformonsocialmedia &&
                      detail.job[0].plateformonsocialmedia &&
                      detail.phoneVerifiedStatus === 1
                    ) {
                      setStep(7);
                    }
                  }}
                  className={step == 7 ? "active" : step >= 7 ? "active2" : ""}
                >
                  <span></span>
                  <p>{t("title-job-post")}</p>
                </li>
              </ul>
            </div>
            <div className="right">
              {step === 1 && (
                <StepOneContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              {step === 2 && (
                <StepTwoContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              {step === 3 && (
                <StepThreeContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              {step === 4 && (
                <StepFourContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              {step === 5 && (
                <StepFiveContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              {step === 6 && (
                <StepSixContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              {step === 7 && (
                <StepSevenContent
                  updateProfile={updateProfile}
                  afterLoginsField={afterLoginsField}
                />
              )}
              <Modal show={showModal} onHide={closeLoginModal}>
                <Modal.Body>
                  <div className="promocode_content login_first">
                    <Link to="" onClick={closeLoginModal}>
                      +
                    </Link>
                    <h2>{t("post-live")}.</h2>
                    <img src="./images/create_profile.png" />
                    <p className="pro">
                      {t("senscare-wishes")}
                      <Link to="/search-parents">{t("text-here")}</Link>
                    </p>
                    <button onClick={redirectToSearchProviders}>
                      {t("search-candidates")}
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
