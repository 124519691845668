import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";

export const InviteModal = ({
  isInviteModal,
  setIsInviteModal,
  jobsListProfession,
  setJobID,
  handleInterviewInvite
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isInviteModal} onHide={(e) => setIsInviteModal(false)}>
      <Modal.Body>
        <div className="promocode_content signout invite request request2">
          <h2>{t("Interview invite")}</h2>
          <ul>
            <li>
              <span>{t("Send invite for:")}</span>
            </li>
            {jobsListProfession.map((data, index) => {
              return (
                <li
                  style={data.invitestatus == 1 ? { opacity: 0.6 } : {}}
                  key={index}
                >
                  <input
                    disabled={data.invitestatus == 1 ? true : false}
                    type="radio"
                    name="profession"
                    onClick={(e) => setJobID(data.id)}
                  />
                  <span>
                    {data.job_type == "Special Education Paraprofessional"
                      ? t("text-paraprofessional")
                      : data.job_type == "Special Education Teacher"
                      ? t("sped-teacher")
                      : t(data.job_type)}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="button text-center ">
            <div className="pull-right">
              <button className="btn" onClick={(e) => setIsInviteModal(false)}>
                {t("text-cancel")}
              </button>
            </div>
            <div className="pull-right">
              <button
                className="btn confirm"
                onClick={(e) => handleInterviewInvite()}
              >
                {t("text-send")}
              </button>
            </div>
          </div>
          <div className="noteedit">
            <MarkRoundIcon />
            <span>
              
              <b> {t("note-with")}</b> {t("record-indicate-meeting")}
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
