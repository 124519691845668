import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NannyIcon } from "../../assets/image/NannyIcon";
import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";

import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { useCategoryStore } from "../../store/useCategoryStore";
import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../utils/variables";

export const SearchFilter = ({
  handleProviderData,
  handleElementById,
  handleElementDisplay,
  handleSelectYears,
  handleSelectCategory,
  advanceSearch,
  setType,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { search, setSearch, usd, setUsd, profile } =
    useSearchDetailsStore();

  const { childrenAge } = useAdditionalDetailsStore();

  const {
    selectCategory,
    setSelectCategory,
    jobCategory,
    setJobCategory,
    isCheck,
    category,
  } = useCategoryStore();

   console.log(selectCategory, 'SELECET ')

  return (
    <div className="filter_header" id="">
      <div className="container">
        <div
          className="filter_box"
          onKeyPress={(e) => {
            if (
              e.key == "Enter" &&
              localStorage.getItem("token") &&
              localStorage.getItem("id")
            ) {
              localStorage.removeItem("searchdataprovider");
              handleProviderData();
            }
          }}
        >
          <div className="filter_search">
            <div className="advance_search_filter">
              <div
                className={
                  "advance_search_filter_input Profile_complete " +
                  (!isCheck ? "op" : "")
                }
              >
                <span
                  id="my-adv-filter"
                  onClick={(e) => handleElementById("filterdata")}
                >
                  {t("Advanced Search")}
                </span>
                {advanceSearch != false ? (
                  <div
                    className="filters_search detail work-experience"
                    id="filterdata"
                    style={
                      profile.about == "" ||
                      profile.about == null ||
                      !localStorage.getItem("token") ||
                      !localStorage.getItem("id") ||
                      localStorage.getItem("refine2")
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="form_group full border qualification">
                      <label>{t("text-frequency")}</label>
                      <div className="checkbox create">
                        <ul>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setSearch({
                                    ...search,
                                    tutorintrestedin: "Full time",
                                  });
                                } else {
                                  setSearch({
                                    ...search,
                                    tutorintrestedin: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("full-time")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setSearch({
                                    ...search,
                                    tutorintrestedin1: "Part time",
                                  });
                                } else {
                                  setSearch({
                                    ...search,
                                    tutorintrestedin1: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("part-time")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setSearch({
                                    ...search,
                                    tutorintrestedin2: "Occasionally",
                                  });
                                } else {
                                  setSearch({
                                    ...search,
                                    tutorintrestedin2: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("occasionally")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form_group full border">
                      <label>
                        {t("Hourly rate")}{" "}
                        {profile && profile.country == "Serbia"
                          ? "(RSD)"
                          : "(USD)"}
                      </label>
                      {profile && profile.country == "Serbia" ? (
                        <div className="wrapper">
                          <div className="container_slide">
                            <div className="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    usd.min >= value ||
                                    (usd.max < value && usd.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div className="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    usd.min >= value ||
                                    (usd.max < value && usd.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={usd.min / 10}
                              onChange={(e) => {
                                if (
                                  (usd.max > 0 ? usd.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setUsd({
                                    ...usd,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={usd.max == 0 ? 50 / 10 : usd.max / 10}
                              onChange={(e) => {
                                if (
                                  usd.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setUsd({
                                    ...usd,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="wrapper">
                          <div className="container_slide">
                            <div className="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                  const style =
                                    usd.min >= value ||
                                    (usd.max < value && usd.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <div className="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                  const style =
                                    usd.min >= value ||
                                    (usd.max < value && usd.max > 0)
                                      ? { backgroundColor: "#E5E5E5" }
                                      : {};
                                  return <li key={index} style={style}></li>;
                                })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={usd.min / 10}
                              onChange={(e) => {
                                if (
                                  (usd.max > 0 ? usd.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setUsd({
                                    ...usd,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={usd.max == 0 ? 50 / 10 : usd.max / 10}
                              onChange={(e) => {
                                if (
                                  usd.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setUsd({
                                    ...usd,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span className="price">
                        {profile && profile.country == "Serbia"
                          ? usd.min * 100 + " - " + usd.max * 100
                          : usd.min +
                            " - " +
                            (usd.max > 0
                              ? usd.max == 60
                                ? usd.max + "+"
                                : usd.max
                              : usd.max == 60
                              ? 60 + "+"
                              : 60 + "+")}
                      </span>
                    </div>

                    <div className="form_group full border">
                      <label>{t("Number of children")}</label>
                      <div className="children_number">
                        <ul>
                          <li
                            onClick={(e) => {
                              if (jobCategory.data1 == "") {
                                setJobCategory({ ...jobCategory, data1: 1 });
                              } else {
                                setJobCategory({ ...jobCategory, data1: "" });
                              }
                            }}
                            className={jobCategory.data1 == 1 ? "active" : ""}
                          >
                            1
                          </li>
                          <li
                            onClick={(e) => {
                              if (jobCategory.data2 == "") {
                                setJobCategory({ ...jobCategory, data2: 2 });
                              } else {
                                setJobCategory({ ...jobCategory, data2: "" });
                              }
                            }}
                            className={jobCategory.data2 == 2 ? "active" : ""}
                          >
                            2
                          </li>
                          <li
                            onClick={(e) => {
                              if (jobCategory.data3 == "") {
                                setJobCategory({
                                  ...jobCategory,
                                  data3: "twins",
                                });
                              } else {
                                setJobCategory({ ...jobCategory, data3: "" });
                              }
                            }}
                            className={
                              jobCategory.data3 == "twins" ? "active" : ""
                            }
                          >
                            {t("text-twins")}
                          </li>
                          <li
                            onClick={(e) => {
                              if (jobCategory.data4 == "") {
                                setJobCategory({ ...jobCategory, data4: "3" });
                              } else {
                                setJobCategory({ ...jobCategory, data4: "" });
                              }
                            }}
                            className={jobCategory.data4 == 3 ? "active" : ""}
                          >
                            3+
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="job_performance">
                      <div className="form_group   full border">
                        <label>{t("child-age")}</label>
                        <div className="text customselect ">
                          {childrenAge ? (
                            <span
                              className="keyword"
                              onClick={(e) =>
                                handleElementDisplay("cate5", "over2")
                              }
                              style={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {childrenAge.map((e) => {
                                if (typeof e.name == "string") {
                                  return e.name + " , ";
                                }
                              })}
                              {childrenAge.length > 0 ? "" : t("choose-list")}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="customselect inp">
                          <div
                            className="overflow"
                            id="over2"
                            onClick={(e) =>
                              handleElementDisplay("cate5", "over2")
                            }
                          ></div>
                          <div className="option" id="cate5">
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  handleSelectYears(t("years-0-1"))
                                }
                              />
                              <h3>{t("years-0-1")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  handleSelectYears(t("years-4-7"))
                                }
                              />
                              <h3>{t("years-4-7")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  handleSelectYears(t("years-8-10"))
                                }
                              />
                              <h3>{t("years-8-10")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  handleSelectYears(t("years-11-15"))
                                }
                              />
                              <h3>{t("years-11-15")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                onClick={(a) =>
                                  handleSelectYears(t("years-16"))
                                }
                              />
                              <h3>{t("years-16")} </h3>
                              <span></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form_group full border qualification">
                      <label>{t("start-date")}</label>
                      <div className="checkbox create">
                        <ul>
                          <li>
                            <input
                              type="checkbox"
                              name="b"
                              onClick={(e) => {
                                setSearch({
                                  ...search,
                                  withinweek: e.target.checked,
                                });
                              }}
                            />
                            <span> {t("Within a week")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name="b"
                              onClick={(e) => {
                                setSearch({
                                  ...search,
                                  withinmonth: e.target.checked,
                                });
                              }}
                            />
                            <span> {t("Within a month")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form_group full qualification">
                      <label>{t("Other options")}</label>
                      <div className="checkbox create">
                        <ul>
                          <li>
                            <input
                              type="checkbox"
                              name="b"
                              onClick={(e) => {
                                setSearch({
                                  ...search,
                                  carorlicence: e.target.checked,
                                });
                              }}
                            />
                            <span> {t("Without transportation")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name="b"
                              onClick={(e) => {
                                setSearch({
                                  ...search,
                                  withinweek: e.target.checked,
                                });
                              }}
                            />
                            <span> {t("Jobs posted within a week")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name="b"
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setSearch({
                                    ...search,
                                    workingabroad: "Yes",
                                  });
                                } else {
                                  setSearch({
                                    ...search,
                                    workingabroad: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("Work abroad")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name="b"
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setSearch({
                                    ...search,
                                    tutorintrestedinschool: "Yes",
                                  });
                                } else {
                                  setSearch({
                                    ...search,
                                    tutorintrestedinschool: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("School jobs")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        setType("");
                        localStorage.removeItem("searchdataprovider");
                        handleProviderData();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      {t("Refine result")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="filter_category Profile_complete ">
              <div className="filter_category_select detail work-experience">
                <div className="job_performance">
                  <div className="form_group   full">
                    <div className="customselect inp">
                      <input
                        id="my-fil-cat"
                        className="keyword"
                        type="text"
                        placeholder={t("select")}
                        value={category.map((e) => {
                          return e.name;
                        })}
                      />
                      <div
                        className="overflow"
                        id="over3"
                        onClick={(e) => handleElementDisplay("cate6", "over3")}
                      ></div>
                      <div className="option" id="cate6">
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => {
                              handleSelectCategory(t("text-nanny"));
                              if (a.target.checked) {
                                setSelectCategory({
                                  ...selectCategory,
                                  data1: t("text-nanny"),
                                });
                              } else {
                                setSelectCategory({
                                  ...selectCategory,
                                  data1: "",
                                });
                              }
                            }}
                            checked={
                              selectCategory.data1 == t("text-nanny")
                                ? true
                                : false
                            }
                          />
                          <h3>
                            <NannyIcon />

                            {t("text-nanny")}
                          </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => {
                              handleSelectCategory(t("spec-education-teacher"));
                              if (a.target.checked) {
                                setSelectCategory({
                                  ...selectCategory,
                                  data2: t("spec-education-teacher"),
                                });
                              } else {
                                setSelectCategory({
                                  ...selectCategory,
                                  data2: "",
                                });
                              }
                            }}
                            checked={
                              selectCategory.data2 ==
                              t("spec-education-teacher")
                                ? true
                                : false
                            }
                          />
                          <h3>
                            <TeacherIcon />
                            {t("spec-education-teacher")}
                          </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => {
                              handleSelectCategory(
                                t("spec-education-paraprofessional")
                              );
                              if (a.target.checked) {
                                setSelectCategory({
                                  ...selectCategory,
                                  data3: t("spec-education-paraprofessional"),
                                });
                              } else {
                                setSelectCategory({
                                  ...selectCategory,
                                  data3: "",
                                });
                              }
                            }}
                            checked={
                              selectCategory.data3 ==
                              t("spec-education-paraprofessional")
                                ? true
                                : false
                            }
                          />
                          <h3>
                            <ParaprofessionalIcon />
                            {t("spec-education-paraprofessional")}
                          </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => {
                              handleSelectCategory(t("text-tutor"));
                              if (a.target.checked) {
                                setSelectCategory({
                                  ...selectCategory,
                                  data4: t("text-tutor"),
                                });
                              } else {
                                setSelectCategory({
                                  ...selectCategory,
                                  data4: "",
                                });
                              }
                            }}
                            checked={
                              selectCategory.data4 === t("text-tutor")
                                ? true
                                : false
                            }
                          />
                          <h3>
                            <TutorIcon />
                            {t("text-tutor")}
                          </h3>
                          <span></span>
                        </p>
                      </div>
                      <span
                        onClick={(e) => handleElementDisplay("cate6", "over3")}
                      >
                        <ArrowUpIcon color={"#7D2B8B"} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="city">
              <input
                type="text"
                placeholder={t("city")}
                id="my-fil-city"
                onChange={(e) => setSearch({ ...search, city: e.target.value })}
              />
            </div>
            <div className="zip code">
              <input
                type="text"
                placeholder={t("zip")}
                id="my-fil-zip"
                onChange={(e) => setSearch({ ...search, zip: e.target.value })}
              />
            </div>
            <div className="distance">
              <div className="distance_input">
                <select
                  name=""
                  id="my-fil-dis"
                  onChange={(e) =>
                    setSearch({ ...search, distance: e.target.value })
                  }
                >
                  <option value="" selected>
                    {t("Distances")}
                  </option>
                  <option value={5}> 5 {t("Miles")}</option>
                  <option value={10}>10 {t("Miles")}</option>
                  <option value={15}>15 {t("Miles")}</option>
                  <option value={20}>20 {t("Miles")}</option>
                  <option value={25}>25 {t("Miles")}</option>
                  <option value={30}>30 {t("Miles")}</option>
                  <option value={35}>35 {t("Miles")}</option>
                  <option value={40}>40 {t("Miles")}</option>
                  <option value={45}>45 {t("Miles")}</option>
                  <option value={50}>50 {t("Miles")}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="search_box">
            <div className="search_input">
              <button
                id="my-fil-search"
                type="submit"
                onClick={(e) => {
                  localStorage.getItem("user_type") == "parents"
                    ? navigate("/search-providers")
                    : navigate("/search-parents");
                  setType("");
                  localStorage.removeItem("searchdataprovider");
                  handleProviderData();
                  localStorage.removeItem("filter");
                }}
              >
                {t("text-search")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
