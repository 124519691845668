import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { api } from "../../../urls";

import { useTranslation } from "react-i18next";
import { CircleIcon } from "../../../assets/image/CircleIcon";
import { ToggleIcon } from "../../../assets/image/ToggleIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";
import { DateIcon } from "../../../assets/image/DateIcon";
import { BanCarIcon } from "../../../assets/image/BanCarIcon";
import { CarIcon } from "../../../assets/image/CarIcon";
import { BanPlaneIcon } from "../../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../../assets/image/PlaneIcon";
import { BanHouseIcon } from "../../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../../assets/image/HouseIcon";
import { BanCookIcon } from "../../../assets/image/BanCookIcon";
import { CookIcon } from "../../../assets/image/CookIcon";
import { FlagIcon } from "../../../assets/image/FlagIcon";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../../utils/variables";

export function ProfessionChange(props) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showNannyModal, setShowNannyModal] = useState(false);

  const [showProfessionModal, setShowProfessionModal] = useState(false);
  const [showTeacherModal, setShowTeacherModal] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [showTutorModal, setShowTutorModal] = useState(false);
  const [showLiveModal, setShowLiveModal] = useState(false);

  const [isDisabled, setIsDisabled] = useState(0);

  const [rule, setRule] = useState({
    licence: "",
    kids: "",
    housework: "",
    family: "",
  });
  const [detail, setDetail] = useState({});
  const [describe, setDescribe] = useState({});
  const [newProfession, setNewProfession] = useState({});

  const [isSelect, setIsSelect] = useState("");
  const [activeProfessional, setActiveProfessional] = useState({});
  const [jobDelete, setJobDelete] = useState({});
  const [count, setCount] = useState(0);

  const today = new Date();

  const handleCloseModal = () => setShowModal(false);

  const handleCloseNannyModal = () => {
    setShowNannyModal(false);
    setCount(count - 1);
  };
  const handleCloseProfessionModal = () => {
    setCount(count - 1);
    setShowProfessionModal(false);
  };
  const handleCloseTeacherModal = () => {
    setCount(count - 1);
    setShowTeacherModal(false);
  };
  const handleCloseTutorModal = () => {
    setCount(count - 1);
    setShowTutorModal(false);
  };
  const handleCloseJobModal = () => {
    setShowJobModal(false);
  };
  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleDescribeSelect = (a, b) => {
    if (Object.keys(describe).length < 2) {
      setDescribe({ ...describe, [a]: b });
      Object.keys(detail.service_type).filter((e) => e == a)[0]
        ? setNewProfession({})
        : setNewProfession({ ...newProfession, [a]: b });
      setActiveProfessional({ ...activeProfessional, [a]: b });
      setactiveprofession({ ...activeprofession });
      setDetail({
        ...detail,
        service_type: { ...detail.service_type, [a]: b },
      });
      delete jobDelete[a];
      setJobDelete({ ...jobDelete });
    } else {
      delete describe[a];
      setDescribe({ ...describe });
      delete activeProfessional[a];
      setActiveProfessional({ ...activeProfessional });
      delete activeprofession[a];
      setactiveprofession({ ...activeprofession });
      delete detail.service_type[a];
      setDetail({ ...detail });
      delete newProfession[a];
      setNewProfession({ ...newProfession });
      setJobDelete({ ...jobDelete, [a]: b });
    }
  };
  const [errorField, setErrorField] = useState({
    nanyperhrrate: "",
    childtransportation: "",
    occasionaltraveling: "",
    lighthousework: "",
    cookingforkids: "",
    seperhrrate: "",
    tutorexp: "",
    seterhrrate: "",
    childneediep: "",
    tutorperhrrate: "",
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",
    jobs: "",
    jobs_description: "",
    jobs2: "",
    jobs_description2: "",
    tutorstartdate2: "",
    liveinnany: "",
  });

  const [qualifications, setQualifications] = useState({
    English: "",
    Serbian: "",
    Mathematics: "",
    Physics: "",
    Chemistry: "",
    Other: "",
  });

  const [tutorRate, setTutorRate] = useState({
    min: 0,
    max: 0,
  });
  const [nannyRate, setNannyRate] = useState({
    min: 0,
    max: 0,
  });
  const [rate, setRate] = useState({
    min: 0,
    max: 0,
  });
  const [perRate, setPerRate] = useState({
    min: 0,
    max: 0,
  });

  const [detailParents, setDetailParents] = useState({
    nanyperhrrate: "",
    childtransportation: "",
    occasionaltraveling: "",
    lighthousework: "",
    cookingforkids: "",
    seperhrrate: "",
    tutorexp: "",
    seterhrrate: "",
    childneediep: "",
    tutorperhrrate: "",
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",
    jobs: "",
    jobs_description: "",
    jobs2: "",
    jobs_description2: "",
    tutorstartdate2: "",
    liveinnany: "",
  });

  const step1 = [
    { name: "cookingforkids" },
    { name: "lighthousework" },
    { name: "childtransportation" },
    { name: "occasionaltraveling" },
    { name: "tutorexp" },
    { name: "nanyperhrrate" },
    { name: "liveinnany" },
    { name: "tutorstartdate2" },
  ];
  const step2 = [
    { name: "tutorexp" },
    { name: "seperhrrate" },
    { name: "tutorstartdate2" },
  ];
  const step3 = [
    { name: "tutorexp" },
    { name: "seterhrrate" },
    { name: "childneediep" },
    { name: "tutorstartdate2" },
  ];
  const step4 = [
    { name: "tutorexp" },
    { name: "tutorperhrrate" },
    { name: "tutorliketoteach" },
    { name: "tutorintrestedonlinecls" },
    { name: "tutorstartdate2" },
  ];
  const step5 = [
    { name: "jobs" },
    { name: "jobs_description" },
    { name: "jobs2" },
    { name: "jobs_description2" },
  ];
  const [jobDescription, setJobDescription] = useState({
    tital: "",
    description: "",
    id: "",
    id2: "",
    plateformonsocialmedia: "Yes",
    plateformonsocialmedia2: "Yes",
    job_type: detail.service_type ? Object.values(detail.service_type)[0] : "",
    job_type2: detail.service_type ? Object.values(detail.service_type)[1] : "",
  });

  const validateAfterLoginField = (name) => {
    switch (name) {
      case "nanyperhrrate":
        errorField.nanyperhrrate = nannyRate.max == 0 ? "required" : "";
        break;

      case "childneediep":
        errorField.childneediep =
          detailParents.childneediep == "" ? "required" : "";
        break;
      case "seterhrrate":
        errorField.seterhrrate = rate.max == 0 ? "required" : "";
        break;
      case "seperhrrate":
        errorField.seperhrrate = perRate.max == 0 ? "required" : "";
        break;
      case "tutorliketoteach":
        errorField.tutorliketoteach =
          qualifications.English ||
          qualifications.Serbian ||
          qualifications.Mathematics ||
          qualifications.Physics ||
          qualifications.Chemistry ||
          qualifications.Other
            ? ""
            : "required";
        break;
      case "tutorintrestedonlinecls":
        errorField.tutorintrestedonlinecls =
          detailParents.tutorintrestedonlinecls == "" ? "required" : "";
        break;
      case "tutorperhrrate":
        errorField.tutorperhrrate = tutorRate.max == 0 ? "required" : "";
        break;
      case "liveinnany":
        errorField.liveinnany =
          detailParents.liveinnany == "" ? "required" : "";
        break;
      case "tutorstartdate2":
        errorField.tutorstartdate2 =
          detailParents.tutorstartdate2 == "" ? "required" : "";
        break;
      case "tutorexp":
        errorField.tutorexp = detailParents.tutorexp == "" ? "required" : "";
        break;
      case "occasionaltraveling":
        errorField.occasionaltraveling = rule.family != "" ? "" : "required";
        break;
      case "cookingforkids":
        errorField.cookingforkids = rule.kids == "" ? "required" : "";
        break;
      case "lighthousework":
        errorField.lighthousework = rule.housework == "" ? "required" : "";
        break;
      case "childtransportation":
        errorField.childtransportation = rule.licence == "" ? "required" : "";
        break;
      case "jobs":
        errorField.jobs = detailParents.jobs == "" ? "required" : "";
        break;

      case "jobs_description":
        errorField.jobs_description =
          detailParents.jobs_description == "" ? "required" : "";
        break;
      case "jobs2":
        errorField.jobs2 = detailParents.jobs2 == "" ? "required" : "";
        break;
      case "jobs_description2":
        errorField.jobs_description2 =
          detailParents.jobs_description2 == "" ? "required" : "";
        break;
      default:
        break;
    }
    setErrorField(errorField);
    setTimeout(() => {
      setDetail(detail);
    }, 900);
  };

  const [activeprofession, setactiveprofession] = useState({});

  const [jobFilter, setJobFilter] = useState([]);

  const handleGeneralDescribeSelect = (a, b) => {
    if (Object.keys(activeProfessional).length > 0) {
      Object.keys(activeProfessional).filter((e) => {
        if (e != a) {
          setActiveProfessional({ ...activeProfessional, [a]: b });
          delete jobDelete[a];
          setJobDelete({ ...jobDelete });
        } else {
          delete activeProfessional[a];
          delete describe[a];
          setTimeout(() => {
            setActiveProfessional({ ...activeProfessional });
            setJobDelete({ ...jobDelete, [a]: b });
            setDescribe({ ...describe });
          }, 200);
        }
      });
    } else {
      setActiveProfessional({ [a]: b });
    }
  };

  const handleDescribeAddSelect = (a, b) => {
    if (Object.keys(activeprofession).length > 0) {
      Object.keys(activeprofession).filter((e) => {
        if (e != a) {
          setactiveprofession({ [a]: b });
        } else {
          delete activeprofession[a];
          setTimeout(() => {
            setactiveprofession({ ...activeprofession });
          }, 200);
        }
      });
    } else {
      setactiveprofession({ [a]: b });
    }
  };

  const fetchDetailData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
        setDescribe(result.data.service_type);
        setActiveProfessional(result.data.service_type);
        if (result.data?.job[0]) {
          setJobFilter(result.data?.job?.filter((e) => e.status == 0));
        }
        setTimeout(() => {
          const x = Object.keys(detailParents).forEach(function (key) {
            detailParents[key] =
              result.data[key] != null ? result.data[key] : "";
            setDetailParents({ ...detailParents });
          });
        }, 500);
        setNannyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setPerRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setRule({
          licence: result.data.childtransportation
            ? result.data.childtransportation
            : "",
          kids: result.data.cookingforkids ? result.data.cookingforkids : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.occasionaltraveling
            ? result.data.occasionaltraveling
            : "",
        });
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        result.data.job[0]
          ? setTimeout(() => {
              setJobDescription({
                ...jobDescription,
                id: result.data.job[0].id,
                id2: result.data.job[1] ? result.data.job[1].id : "",
                plateformonsocialmedia:
                  result.data.job[0].plateformonsocialmedia != ""
                    ? result.data.job[0].plateformonsocialmedia
                    : "Yes",
                plateformonsocialmedia2: result.data.job[1]
                  ? result.data.job[1].plateformonsocialmedia
                  : "",
              });
            }, 900)
          : setJobDescription({ ...jobDescription });
      })
      .catch((error) => console.log("error", error));
  };

  const fetchDetailUpdate = (status) => {
    setDetailParents({
      ...detailParents,
      jobs:
        detail.job[0] &&
        detail.job[0].job_type == Object.values(activeprofession)[0]
          ? detail.job[0].title
          : detail.job[1] &&
            detail.job[1].job_type == Object.values(activeprofession)[0]
          ? detail.job[1].title
          : "",
      jobs_description:
        detail.job[0] &&
        detail.job[0].job_type == Object.values(activeprofession)[0]
          ? detail.job[0].description
          : detail.job[1] &&
            detail.job[1].job_type == Object.values(activeprofession)[0]
          ? detail.job[1].description
          : "",
    });

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("nanyperhrrate", nannyRate.min + "-" + nannyRate.max);
    detailParents.tutorexp == ""
      ? formdata.append("nanyperhrrate", nannyRate.min + "-" + nannyRate.max)
      : formdata.append("tutorexp", detailParents.tutorexp);
    formdata.append("occasionaltraveling", rule.family);
    formdata.append("cookingforkids", rule.kids);
    formdata.append("lighthousework", rule.housework);
    formdata.append("childtransportation", rule.licence);
    formdata.append("seterhrrate", rate.min + "-" + rate.max);
    formdata.append("seperhrrate", perRate.min + "-" + perRate.max);
    formdata.append("childneediep", detailParents.childneediep);
    formdata.append("tutorperhrrate", tutorRate.min + "-" + tutorRate.max);
    formdata.append("tutorliketoteach", JSON.stringify(qualifications));
    formdata.append(
      "tutorintrestedonlinecls",
      detailParents.tutorintrestedonlinecls
    );
    formdata.append("active_service", JSON.stringify(describe));
    formdata.append(
      "inactive_service",
      Object.keys(jobDelete).length > 0 ? JSON.stringify(jobDelete) : ""
    );
    detailParents.tutorstartdate2 == ""
      ? formdata.append("nanyperhrrate", nannyRate.min + "-" + nannyRate.max)
      : formdata.append("tutorstartdate", detailParents.tutorstartdate2);

    if (showJobModal && Object.keys(activeprofession).length > 0) {
      detailParents.jobs != ""
        ? formdata.append("jobs[0][title]", detailParents.jobs)
        : formdata.append("about", detail.about);
      detailParents.jobs != ""
        ? formdata.append(
            "jobs[0][description]",
            detailParents.jobs_description
          )
        : formdata.append("about", detail.about);
      detailParents.jobs != ""
        ? formdata.append(
            "jobs[0][plateformonsocialmedia]",
            jobDescription.plateformonsocialmedia
              ? jobDescription.plateformonsocialmedia
              : "Yes"
          )
        : formdata.append("about", detail.about);
      detailParents.jobs != ""
        ? formdata.append(
            "jobs[0][job_type]",
            Object.values(activeprofession)[0]
          )
        : formdata.append("about", detail.about);

      jobFilter[0]?.job_type == Object.values(activeprofession)[0]
        ? formdata.append("jobs[0][job_id]", jobFilter[0]?.id)
        : jobFilter[1]?.job_type == Object.values(activeprofession)[0]
        ? formdata.append("jobs[0][job_id]", jobFilter[1]?.id)
        : formdata.append("about", detail.about);
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        fetchDetailData();
        handleCloseModal();
        // profile_data()
        setTimeout(() => {
          if (
            (activeprofession.tab1
              ? nannyRate.max != 0
              : activeprofession.tab2
              ? rate.max != 0
              : activeprofession.tab4
              ? tutorRate.max != 0
              : activeprofession.tab3
              ? perRate.max != 0
              : status) &&
            status == "preferences"
          ) {
            Object.keys(activeprofession).map((e) => {
              Object.keys(detail.active_service).map((a) => {
                if (a == e) {
                  if (status == "preferences") {
                    setShowJobModal(true);
                  }
                }
              });
            });
          } else {
            if (showLiveModal != true && status != "final") {
              activeprofession.tab1
                ? setShowNannyModal(true)
                : setShowNannyModal(false);
              activeprofession.tab2
                ? setShowTeacherModal(true)
                : setShowTeacherModal(false);
              activeprofession.tab3
                ? setShowProfessionModal(true)
                : setShowProfessionModal(false);
              activeprofession.tab4
                ? setShowTutorModal(true)
                : setShowTutorModal(false);
            }
          }
          if (
            Object.keys(activeprofession).length == count &&
            status &&
            Object.keys(activeprofession).length >= 0
          ) {
            setShowJobModal(true);
          } else {
            setCount(count + 1);
          }
        }, 200);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleRedirect = () => {
    window.location.href = "/search-providers";
    localStorage.removeItem("search");
  };

  return (
    <>
      {props.post ? (
        <button
          onClick={(e) => {
            fetchDetailData();
            setShowLoginModal(true);
          }}
        >
          {props.post} {t("Job Post")}
        </button>
      ) : (
        <button
          onClick={(e) => {
            fetchDetailData();
            setShowLoginModal(true);
          }}
        >
          {t("Job Post")}
          <img src={window.location.origin + "/images/jobpost.png"} />
        </button>
      )}

      {showLoginModal && (
        <Modal
          show={showLoginModal}
          className="professional_select profchange"
          onHide={handleCloseLoginModal}
        >
          <Modal.Body>
            <div className={"describe more_about "}>
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("It looks like you need a new service.")}</h2>
              <div className="process_guid ">
                <ul>
                  <li
                    className={
                      describe && describe.tab1 == "Nanny"
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                  >
                    <div className="checkededit">
                      <CircleIcon />
                      <input
                        type={"checkbox"}
                        onClick={(e) => {
                          if (e.target.value) {
                            handleDescribeAddSelect("tab1", "Nanny");
                          }
                        }}
                        checked={
                          Object.keys(activeprofession).filter(
                            (e) => e == "tab1"
                          )[0]
                            ? true
                            : false
                        }
                        disabled={describe && describe.tab1 ? false : true}
                      />
                      <span></span>
                    </div>
                    <Link
                      to=""
                      onClick={(e) => {
                        handleDescribeSelect("tab1", "Nanny");
                      }}
                    >
                      <img
                        src={
                          window.location.origin +
                          (describe && describe.tab1 == "Nanny"
                            ? "/images/nanny_fill.svg"
                            : "/images/nanny.svg")
                        }
                      />
                      <span>{t("text-nanny")}</span>
                    </Link>
                    <div class="personal_preferences">
                      <label class="switchedit">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            if (e.target.checked) {
                              handleGeneralDescribeSelect("tab1", "Nanny");
                              handleDescribeSelect("tab1", "Nanny");
                            } else {
                              setShowModal(true);
                              setIsDisabled(1);
                            }
                          }}
                          checked={
                            Object.keys(activeProfessional).filter(
                              (e) => e == "tab1"
                            )[0]
                              ? true
                              : false
                          }
                        />
                        <span class="slideredit roundedit"></span>
                      </label>
                    </div>
                  </li>
                  <li
                    className={
                      describe && describe.tab2 == "Special Education Teacher"
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                  >
                    <div className="checkededit">
                      <CircleIcon />
                      <input
                        type={"checkbox"}
                        onClick={(e) => {
                          if (e.target.value) {
                            handleDescribeAddSelect(
                              "tab2",
                              "Special Education Teacher"
                            );
                          }
                        }}
                        checked={
                          Object.keys(activeprofession).filter(
                            (e) => e == "tab2"
                          )[0]
                            ? true
                            : false
                        }
                        disabled={describe && describe.tab2 ? false : true}
                      />
                      <span></span>
                    </div>
                    <Link
                      to=""
                      onClick={(e) => {
                        handleDescribeSelect(
                          "tab2",
                          "Special Education Teacher"
                        );
                      }}
                    >
                      <img
                        src={
                          window.location.origin +
                          (describe &&
                          describe.tab2 == "Special Education Teacher"
                            ? "/images/teacher_fill.svg"
                            : "/images/teacher.svg")
                        }
                      />
                      <span>{t("spec-education-teacher")}</span>
                    </Link>
                    <div class="personal_preferences">
                      <label class="switchedit">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            if (e.target.checked) {
                              handleGeneralDescribeSelect(
                                "tab2",
                                "Special Education Teacher"
                              );
                              handleDescribeSelect(
                                "tab2",
                                "Special Education Teacher"
                              );
                            } else {
                              setShowModal(true);
                              setIsDisabled(2);
                            }
                          }}
                          checked={
                            Object.keys(activeProfessional).filter(
                              (e) => e == "tab2"
                            )[0]
                              ? true
                              : false
                          }
                        />
                        <span class="slideredit roundedit"></span>
                      </label>
                    </div>
                  </li>
                  <li
                    className={
                      describe &&
                      describe.tab3 == "Special Education Paraprofessional"
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                  >
                    <div className="checkededit">
                      <CircleIcon />
                      <input
                        type={"checkbox"}
                        onClick={(e) => {
                          if (e.target.value) {
                            handleDescribeAddSelect(
                              "tab3",
                              "Special Education Paraprofessional"
                            );
                          }
                        }}
                        checked={
                          Object.keys(activeprofession).filter(
                            (e) => e == "tab3"
                          )[0]
                            ? true
                            : false
                        }
                        disabled={describe && describe.tab3 ? false : true}
                      />
                      <span></span>
                    </div>
                    <Link
                      to=""
                      onClick={(e) => {
                        handleDescribeSelect(
                          "tab3",
                          "Special Education Paraprofessional"
                        );
                      }}
                    >
                      <img
                        src={
                          window.location.origin +
                          (describe &&
                          describe.tab3 == "Special Education Paraprofessional"
                            ? "/images/education_fill.svg"
                            : "/images/education.svg")
                        }
                      />
                      <span>{t("spec-education-paraprofessional")}</span>
                    </Link>
                    <div class="personal_preferences">
                      <label class="switchedit">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            if (e.target.checked) {
                              handleGeneralDescribeSelect(
                                "tab3",
                                "Special Education Paraprofessional"
                              );
                              handleDescribeSelect(
                                "tab3",
                                "Special Education Paraprofessional"
                              );
                            } else {
                              setShowModal(true);
                              setIsDisabled(3);
                            }
                          }}
                          checked={
                            Object.keys(activeProfessional).filter(
                              (e) => e == "tab3"
                            )[0]
                              ? true
                              : false
                          }
                        />
                        <span class="slideredit roundedit"></span>
                      </label>
                    </div>
                  </li>
                  <li
                    className={
                      describe && describe.tab4 == "Tutor"
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                  >
                    <div className="checkededit">
                      <CircleIcon />
                      <input
                        type={"checkbox"}
                        onClick={(e) => {
                          if (e.target.value) {
                            handleDescribeAddSelect("tab4", "Tutor");
                          }
                        }}
                        checked={
                          Object.keys(activeprofession).filter(
                            (e) => e == "tab4"
                          )[0]
                            ? true
                            : false
                        }
                        disabled={describe && describe.tab4 ? false : true}
                      />
                      <span></span>
                    </div>
                    <Link
                      to=""
                      onClick={(e) => {
                        handleDescribeSelect("tab4", "Tutor");
                      }}
                    >
                      <img
                        src={
                          window.location.origin +
                          (describe && describe.tab4 == "Tutor"
                            ? "/images/tutor_fill.svg"
                            : "/images/tutor.svg")
                        }
                      />
                      <span>{t("text-tutor")}</span>
                    </Link>
                    <div class="personal_preferences">
                      <label class="switchedit">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            if (e.target.checked) {
                              handleGeneralDescribeSelect("tab4", "Tutor");
                              handleDescribeSelect("tab4", "Tutor");
                            } else {
                              setShowModal(true);
                              setIsDisabled(4);
                            }
                          }}
                          checked={
                            Object.keys(activeProfessional).filter(
                              (e) => e == "tab4"
                            )[0]
                              ? true
                              : false
                          }
                        />
                        <span class="slideredit roundedit"></span>
                      </label>
                    </div>
                  </li>
                </ul>
                <p>
                  <ToggleIcon />
                  {t("service-deactivate-profession")}
                </p>
                <p>
                  <CircleIcon color={"#7D2B8B"} />
                  {t("servise-select-profession")}
                </p>
                <div className="noteedit">
                  <MarkRoundIcon color={"#7D2B8B"} />
                  {t("note-create-new-job-post")}
                </div>
              </div>
              <button className="back_sign" onClick={handleCloseLoginModal}>
                {t("text-cancel")}
              </button>
              <button
                className="back_sign"
                onClick={(e) => {
                  fetchDetailUpdate(true);
                }}
              >
                {t("Continue")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="promocode_content younger confirmchnage">
              <Link to="" onClick={handleCloseModal}>
                +
              </Link>
              <h5>{t("Change the status of profession you’re looking for")}</h5>
              <p style={{ marginBottom: "0" }}>
                <b>{t("deactivate-profession-profile")}</b>
              </p>
              <p>{t("continue-question")}</p>
              <div class="button text-center">
                <div class="pull-right">
                  <button
                    class="btn"
                    onClick={(e) => {
                      if (isDisabled == 1) {
                        handleGeneralDescribeSelect("tab1", "Nanny");
                        handleDescribeSelect("tab1", "Nanny");
                      } else if (isDisabled == 2) {
                        handleGeneralDescribeSelect(
                          "tab2",
                          "Special Education Teacher"
                        );
                        handleDescribeSelect(
                          "tab2",
                          "Special Education Teacher"
                        );
                      } else if (isDisabled == 3) {
                        handleGeneralDescribeSelect(
                          "tab3",
                          "Special Education Paraprofessional"
                        );
                        handleDescribeSelect(
                          "tab3",
                          "Special Education Paraprofessional"
                        );
                      } else if (isDisabled == 4) {
                        handleGeneralDescribeSelect("tab4", "Tutor");
                        handleDescribeSelect("tab4", "Tutor");
                      }
                      handleCloseModal();
                    }}
                  >
                    {t("confirm")}
                  </button>
                </div>
                <div class="pull-right">
                  <button class="btn confirm" onClick={handleCloseModal}>
                    {t("objection")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showTutorModal && (
        <Modal
          show={showTutorModal}
          onHide={handleCloseTutorModal}
          className="question_new"
        >
          <Modal.Body>
            <div className="younger">
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("Complete the Profile")}</h2>
              <p>{t("You can edit your info in settings section later")}</p>
              <div className="Profile_complete">
                <div className="detail preferences   work-experience job_performance setp3">
                  <div className="tutor parents">
                    <h2 className="border">
                      <img src={window.location.origin + "/images/tuter.svg"} />
                      {t("text-tutor")}
                    </h2>
                    <div className="col-6 sideby">
                      <div className="form_group full">
                        <label>
                          {t("work-experience-preffer")}
                          <span
                            className={
                              errorField.tutorexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="select">
                          <select
                            value={
                              detailParents.tutorexp != ""
                                ? detailParents.tutorexp
                                : t("choose-list")
                            }
                            onChange={(e) => {
                              setErrorField({ ...errorField, tutorexp: "" });
                              setDetailParents({
                                ...detailParents,
                                tutorexp: e.target.value,
                              });
                            }}
                          >
                            <option disabled={true}>{t("choose-list")}</option>
                            <option value={"0 - 1 years"}>
                              {t("years-0-1")}
                            </option>
                            <option value={"1 - 2 years"}>
                              {t("years-1-2")}
                            </option>
                            <option value={"2 - 4 years"}>
                              {t("years-2-4")}
                            </option>
                            <option value={t("years-4-more")}>
                              {t("years-4-more")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.tutorperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                  {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value &&
                                        tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                  {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value &&
                                        tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={tutorRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (tutorRate.max > 0 ? tutorRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  tutorRate.max == 0
                                    ? 50 / 10
                                    : tutorRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    tutorRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                    setErrorField({
                                      ...errorField,
                                      tutorperhrrate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                  {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value &&
                                        tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                  {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value &&
                                        tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={tutorRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (tutorRate.max > 0 ? tutorRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  tutorRate.max == 0
                                    ? 50 / 10
                                    : tutorRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    tutorRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                    setErrorField({
                                      ...errorField,
                                      tutorperhrrate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? tutorRate.min * 100 + " - " + tutorRate.max * 100
                            : tutorRate.min + " - " + tutorRate.max}
                        </span>
                      </div>
                      <div className="form_group full qualification">
                        <label>
                          {t("classes-question")}
                          <span
                            className={
                              errorField.tutorliketoteach != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox">
                          <ul
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                tutorliketoteach: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.English == "English"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      English: "English",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      English: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("english")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Serbian == "Serbian"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Serbian: "Serbian",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Serbian: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("serbian")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Mathematics == "Mathematics"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Mathematics: "Mathematics",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Mathematics: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("mathematics")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Physics == "Physics"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Physics: "Physics",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Physics: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("physics")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Chemistry == "Chemistry"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setQualifications({
                                      ...qualifications,
                                      Chemistry: "Chemistry",
                                    });
                                  } else {
                                    setQualifications({
                                      ...qualifications,
                                      Chemistry: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("chemistry")}</span>
                            </li>
                            <li className="aline">
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  qualifications.Other ? true : false
                                }
                              />
                              <span>
                                {t("text-other")}
                                <input
                                  type="text"
                                  placeholder={t("text-type")}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setQualifications({
                                        ...qualifications,
                                        Other: e.target.value,
                                      });
                                    } else {
                                      setQualifications({
                                        ...qualifications,
                                        Other: "",
                                      });
                                    }
                                  }}
                                  defaultValue={
                                    qualifications.Other
                                      ? qualifications.Other
                                      : ""
                                  }
                                />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("online-services")}
                          <span
                            className={
                              errorField.tutorintrestedonlinecls != ""
                                ? "starred"
                                : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                tutorintrestedonlinecls: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="a1"
                                checked={
                                  detailParents.tutorintrestedonlinecls == "Yes"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    tutorintrestedonlinecls: "Yes",
                                  })
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="a1"
                                checked={
                                  detailParents.tutorintrestedonlinecls == "No"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    tutorintrestedonlinecls: "No",
                                  })
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                        <br />
                        <br />

                        <label>
                          {t("start-date")}
                          <span
                            className={
                              errorField.tutorstartdate2 != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="date_cal">
                          <span>
                            <DateIcon />
                          </span>

                          <DatePicker
                            className={
                              errorField.tutorstartdate2 != ""
                                ? "bordererror"
                                : ""
                            }
                            minDate={today}
                            selected={
                              detailParents.tutorstartdate2 != null &&
                              detailParents.tutorstartdate2 != ""
                                ? new Date(detailParents.tutorstartdate2)
                                : today
                            }
                            onChange={(date) => {
                              setErrorField({
                                ...errorField,
                                tutorstartdate2: "",
                              });
                              setDetailParents({
                                ...detailParents,
                                tutorstartdate2:
                                  date.getFullYear() +
                                  "-" +
                                  String(date.getMonth() + 1).padStart(2, "0") +
                                  "-" +
                                  String(date.getDate()).padStart(2, "0"),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span>{t("required-fields")}</span>
                  </div>
                </div>
              </div>
              <div class="button text-center">
                <div class="pull-right">
                  <button class="btn" onClick={(e) => handleCloseTutorModal()}>
                    {t("text-back")}
                  </button>
                </div>
                <div class="pull-right">
                  <button
                    class="btn confirm"
                    onClick={(e) => {
                      if (
                        detailParents.tutorexp != "" &&
                        (qualifications.English ||
                          qualifications.Serbian ||
                          qualifications.Mathematics ||
                          qualifications.Physics ||
                          qualifications.Chemistry ||
                          qualifications.Other) &&
                        detailParents.tutorintrestedonlinecls != "" &&
                        tutorRate.max > 5 &&
                        detailParents.tutorstartdate2 != ""
                      ) {
                        // setqcount(qcount + 1)
                        handleCloseTutorModal();
                        // setjobpost(true)
                        setTimeout(() => {
                          fetchDetailUpdate("preferences");
                        }, 400);
                      } else {
                        step4.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-finish")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showTeacherModal && (
        <Modal
          show={showTeacherModal}
          onHide={handleCloseTeacherModal}
          className="question_new"
        >
          <Modal.Body>
            <div className="younger">
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("Complete the Profile")}</h2>
              <p>{t("You can edit your info in settings section later")}</p>
              <div className="Profile_complete">
                <div className="detail preferences   work-experience job_performance setp3">
                  <div className="tutor parents">
                    <h2 className="border">
                      <img
                        src={
                          window.location.origin +
                          "/images/special_education.svg"
                        }
                      />
                      {t("education-teacher")}
                    </h2>
                    <div className="col-6 sideby">
                      <div className="form_group full">
                        <label>
                          {t("work-experience-preffer")}
                          <span
                            className={
                              errorField.tutorexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="select">
                          <select
                            value={
                              detailParents.tutorexp != ""
                                ? detailParents.tutorexp
                                : "Choose from the list"
                            }
                            onChange={(e) => {
                              setErrorField({ ...errorField, tutorexp: "" });
                              setDetailParents({
                                ...detailParents,
                                tutorexp: e.target.value,
                              });
                            }}
                          >
                            <option disabled={true}>{t("choose-list")}</option>
                            <option value={"0 - 1 years"}>
                              {t("years-0-1")}
                            </option>
                            <option value={"1 - 2 years"}>
                              {t("years-1-2")}
                            </option>
                            <option value={"2 - 4 years"}>
                              {t("years-2-4")}
                            </option>
                            <option value={t("years-4-more")}>
                              {t("years-4-more")}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.seterhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                  {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                  {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={rate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (rate.max > 0 ? rate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                                onChange={(e) => {
                                  if (
                                    rate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                    setErrorField({
                                      ...errorField,
                                      seterhrrate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={rate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (rate.max > 0 ? rate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                                onChange={(e) => {
                                  if (
                                    rate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                    setErrorField({
                                      ...errorField,
                                      seterhrrate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? rate.min * 100 + " - " + rate.max * 100
                            : rate.min + " - " + rate.max}
                        </span>
                      </div>
                      <div class="form_group full">
                        <label>
                          {t("text-child-need")} <a>{t("text-iep")} </a>
                          <span class="smallpop">{t("Education program")}</span>
                          ?
                          <span
                            className={
                              errorField.childneediep != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorField({ ...errorField, childneediep: "" })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    childneediep: "Yes",
                                  })
                                }
                                checked={
                                  detailParents.childneediep == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name=""
                                onClick={(e) =>
                                  setDetailParents({
                                    ...detailParents,
                                    childneediep: "No",
                                  })
                                }
                                checked={
                                  detailParents.childneediep == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("start-date")}
                          <span
                            className={
                              errorField.tutorstartdate2 != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="date_cal">
                          <span>
                            <DateIcon />
                          </span>
                          <DatePicker
                            className={
                              errorField.tutorstartdate2 != ""
                                ? "bordererror"
                                : ""
                            }
                            minDate={today}
                            selected={
                              detailParents.tutorstartdate2 != null &&
                              detailParents.tutorstartdate2 != ""
                                ? new Date(detailParents.tutorstartdate2)
                                : today
                            }
                            onChange={(date) => {
                              setErrorField({
                                ...errorField,
                                tutorstartdate2: "",
                              });
                              setDetailParents({
                                ...detailParents,
                                tutorstartdate2:
                                  date.getFullYear() +
                                  "-" +
                                  String(date.getMonth() + 1).padStart(2, "0") +
                                  "-" +
                                  String(date.getDate()).padStart(2, "0"),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span>{t("required-fields")}</span>
                  </div>
                </div>
              </div>
              <div class="button text-center">
                <div class="pull-right">
                  <button
                    class="btn"
                    onClick={(e) => handleCloseTeacherModal()}
                  >
                    {t("text-back")}
                  </button>
                </div>
                <div class="pull-right">
                  <button
                    class="btn confirm"
                    onClick={(e) => {
                      if (
                        detailParents.tutorexp != "" &&
                        rate.max > 5 &&
                        detailParents.childneediep != "" &&
                        detailParents.tutorstartdate2 != ""
                      ) {
                        handleCloseTeacherModal();
                        setTimeout(() => {
                          fetchDetailUpdate("preferences");
                        }, 400);
                      } else {
                        step3.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-finish")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showProfessionModal && (
        <Modal
          show={showProfessionModal}
          onHide={handleCloseProfessionModal}
          className="question_new"
        >
          <Modal.Body>
            <div className="younger">
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("Complete the Profile")}</h2>
              <p>{t("You can edit your info in settings section later")}</p>
              <div className="Profile_complete">
                <div className="detail preferences   work-experience job_performance setp3">
                  <div className="tutor parents">
                    <h2 className="border">
                      <img
                        src={
                          window.location.origin + "/images/professional.svg"
                        }
                      />
                      {t("education-paraprofessional")}
                    </h2>
                    <div className="col-6 sideby">
                      <div className="form_group full">
                        <label>
                          {t("work-experience-preffer")}
                          <span
                            className={
                              errorField.tutorexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="select">
                          <select
                            value={
                              detailParents.tutorexp != ""
                                ? detailParents.tutorexp
                                : "Choose from the list"
                            }
                            onChange={(e) => {
                              setErrorField({ ...errorField, tutorexp: "" });
                              setDetailParents({
                                ...detailParents,
                                tutorexp: e.target.value,
                              });
                            }}
                          >
                            <option disabled={true}>
                              {"Choose from the list"}
                            </option>
                            <option value={"0 - 1 years"}>
                              {t("years-0-1")}
                            </option>
                            <option value={"1 - 2 years"}>
                              {t("years-1-2")}
                            </option>
                            <option value={"2 - 4 years"}>
                              {t("years-2-4")}
                            </option>
                            <option value={t("years-4-more")}>
                              {t("years-4-more")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorField.seperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {detail && detail.country == "Serbia" ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={perRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (perRate.max > 0 ? perRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  perRate.max == 0 ? 50 / 10 : perRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    perRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                    setErrorField({
                                      ...errorField,
                                      seperhrrate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={perRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (perRate.max > 0 ? perRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  perRate.max == 0 ? 50 / 10 : perRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    perRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                    setErrorField({
                                      ...errorField,
                                      seperhrrate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {detail && detail.country == "Serbia"
                            ? perRate.min * 100 + " - " + perRate.max * 100
                            : perRate.min + " - " + perRate.max}
                        </span>
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("start-date")}
                          <span
                            className={
                              errorField.tutorstartdate2 != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="date_cal">
                          <span>
                            <DateIcon />
                          </span>

                          <DatePicker
                            className={
                              errorField.tutorstartdate2 != ""
                                ? "bordererror"
                                : ""
                            }
                            minDate={today}
                            selected={
                              detailParents.tutorstartdate2 != null &&
                              detailParents.tutorstartdate2 != ""
                                ? new Date(detailParents.tutorstartdate2)
                                : today
                            }
                            onChange={(date) => {
                              setErrorField({
                                ...errorField,
                                tutorstartdate2: "",
                              });
                              setDetailParents({
                                ...detailParents,
                                tutorstartdate2:
                                  date.getFullYear() +
                                  "-" +
                                  String(date.getMonth() + 1).padStart(2, "0") +
                                  "-" +
                                  String(date.getDate()).padStart(2, "0"),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span>{t("required-fields")}</span>
                  </div>
                </div>
              </div>
              <div class="button text-center">
                <div class="pull-right">
                  <button
                    class="btn"
                    onClick={(e) => handleCloseProfessionModal()}
                  >
                    {t("text-back")}
                  </button>
                </div>
                <div class="pull-right">
                  <button
                    class="btn confirm"
                    onClick={(e) => {
                      if (
                        detailParents.tutorexp != "" &&
                        perRate.max > 5 &&
                        detailParents.tutorstartdate2 != ""
                      ) {
                        handleCloseProfessionModal();
                        setTimeout(() => {
                          fetchDetailUpdate("preferences");
                        }, 400);
                      } else {
                        step2.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-finish")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showNannyModal && (
        <Modal
          show={showNannyModal}
          onHide={handleCloseNannyModal}
          className="question_new"
        >
          <Modal.Body>
            <div className="younger">
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("Complete the Profile")}</h2>
              <p>{t("You can edit your info in settings section later")}</p>
              <div className="Profile_complete">
                <div className="detail additional_info info_parents preferences   work-experience job_performance setp3">
                  <div className="tutor parents">
                    <h2 className="border">
                      <img
                        src={window.location.origin + "/images/nany_pur.svg"}
                      />
                      {t("text-nanny")}
                    </h2>
                    <div className="col-6">
                      <div className="iconsec">
                        <div className="form_group full">
                          <label>
                            {t("nanny-question")}
                            <span
                              className={
                                errorField.liveinnany != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox">
                            <ul
                              onClick={(e) =>
                                setErrorField({ ...errorField, liveinnany: "" })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="quality"
                                  onClick={(e) =>
                                    setDetailParents({
                                      ...detailParents,
                                      liveinnany: "Yes",
                                    })
                                  }
                                  checked={
                                    detailParents.liveinnany == "Yes"
                                      ? true
                                      : false
                                  }
                                />
                                <span> {t("confirm")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="quality"
                                  onClick={(e) =>
                                    setDetailParents({
                                      ...detailParents,
                                      liveinnany: "No",
                                    })
                                  }
                                  checked={
                                    detailParents.liveinnany == "No"
                                      ? true
                                      : false
                                  }
                                />
                                <span> {t("objection")}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="icon ">
                          <div
                            className="form_group"
                            style={{ marginBottom: "0" }}
                          >
                            <label>
                              {t("text-i-need")}
                              <span
                                className={
                                  errorField.childtransportation != "" ||
                                  errorField.occasionaltraveling != "" ||
                                  errorField.lighthousework != "" ||
                                  errorField.cookingforkids != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                          </div>
                          <ul>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  childtransportation: "",
                                })
                              }
                            >
                              {t("child-transportation")}
                              <div className="icons">
                                <BanCarIcon
                                  className={
                                    rule.licence == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, licence: "false" })
                                  }
                                />
                                <CarIcon
                                  className={
                                    rule.licence == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, licence: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  occasionaltraveling: "",
                                })
                              }
                            >
                              {t("occasional-traveling")}
                              <div className="icons">
                                <BanPlaneIcon
                                  className={
                                    rule.family == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, family: "false" })
                                  }
                                />

                                <PlaneIcon
                                  className={
                                    rule.family == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, family: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  lighthousework: "",
                                })
                              }
                            >
                              {t("light-housework")}
                              <div className="icons">
                                <BanHouseIcon
                                  className={
                                    rule.housework == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, housework: "false" })
                                  }
                                />
                                <HouseIcon
                                  className={
                                    rule.housework == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, housework: "true" })
                                  }
                                />
                              </div>
                            </li>
                            <li
                              onClick={(e) =>
                                setErrorField({
                                  ...errorField,
                                  cookingforkids: "",
                                })
                              }
                            >
                              {t("cooking-kids")}
                              <div className="icons">
                                <BanCookIcon
                                  className={
                                    rule.kids == "false" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, kids: "false" })
                                  }
                                />

                                <CookIcon
                                  className={
                                    rule.kids == "true" ? "active" : ""
                                  }
                                  onClick={(e) =>
                                    setRule({ ...rule, kids: "true" })
                                  }
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="rightsec">
                        <div className="form_group full">
                          <label>
                            {t("work-experience-preffer")}
                            <span
                              className={
                                errorField.tutorexp != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div class="select">
                            <select
                              value={
                                detailParents.tutorexp != ""
                                  ? detailParents.tutorexp
                                  : "Choose from the list"
                              }
                              onChange={(e) => {
                                setErrorField({ ...errorField, tutorexp: "" });
                                setDetailParents({
                                  ...detailParents,
                                  tutorexp: e.target.value,
                                });
                              }}
                            >
                              <option disabled={true}>
                                {t("choose-list")}
                              </option>
                              <option value={"0 - 1 years"}>
                                {t("years-0-1")}
                              </option>
                              <option value={"1 - 2 years"}>
                                {t("years-1-2")}
                              </option>
                              <option value={"2 - 4 years"}>
                                {t("years-2-4")}
                              </option>
                              <option value={t("years-4-more")}>
                                {t("years-4-more")}
                              </option>
                            </select>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                        <div className="form_group full">
                          <label>
                            {t("Rate per hour")}
                            {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                            <span
                              className={
                                errorField.nanyperhrrate != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          {detail && detail.country == "Serbia" ? (
                            <div class="wrapper rang">
                              <div class="container_slide">
                                <div class="slider-track">
                                  <ul>
                                  {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                  </ul>
                                </div>
                                <div class="bggray_slider">
                                  <ul>
                                  {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                  </ul>
                                </div>
                                <input
                                  type="range"
                                  min="0"
                                  max="5"
                                  id="slider-1"
                                  value={nannyRate.min / 10}
                                  onChange={(e) => {
                                    if (
                                      (nannyRate.max > 0 ? nannyRate.max : 60) >
                                      (e.target.value == 5
                                        ? 60
                                        : e.target.value * 10)
                                    ) {
                                      setNannyRate({
                                        ...nannyRate,
                                        min:
                                          e.target.value == 5
                                            ? 60
                                            : e.target.value * 10,
                                      });
                                    }
                                  }}
                                />
                                <input
                                  type="range"
                                  min="0"
                                  max="5"
                                  id="slider-2"
                                  value={
                                    nannyRate.max == 0
                                      ? 50 / 10
                                      : nannyRate.max / 10
                                  }
                                  onChange={(e) => {
                                    if (
                                      nannyRate.min <
                                      (e.target.value == 5
                                        ? 60
                                        : e.target.value * 10)
                                    ) {
                                      setNannyRate({
                                        ...nannyRate,
                                        max:
                                          e.target.value == 5
                                            ? 60
                                            : e.target.value * 10,
                                      });
                                      setErrorField({
                                        ...errorField,
                                        nanyperhrrate: "",
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div class="slider two">
                                <ul>
                                  <li>0</li>
                                  <li>1000</li>
                                  <li>2000</li>
                                  <li>3000</li>
                                  <li>4000</li>
                                  <li>6000+</li>
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <div class="wrapper rang">
                              <div class="container_slide">
                                <div class="slider-track">
                                  <ul>
                                  {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                  </ul>
                                </div>
                                <div class="bggray_slider">
                                  <ul>
                                  {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                  </ul>
                                </div>
                                <input
                                  type="range"
                                  min="0"
                                  max="5"
                                  id="slider-1"
                                  value={nannyRate.min / 10}
                                  onChange={(e) => {
                                    if (
                                      (nannyRate.max > 0 ? nannyRate.max : 60) >
                                      (e.target.value == 5
                                        ? 60
                                        : e.target.value * 10)
                                    ) {
                                      setNannyRate({
                                        ...nannyRate,
                                        min:
                                          e.target.value == 5
                                            ? 60
                                            : e.target.value * 10,
                                      });
                                    }
                                  }}
                                />
                                <input
                                  type="range"
                                  min="0"
                                  max="5"
                                  id="slider-2"
                                  value={
                                    nannyRate.max == 0
                                      ? 50 / 10
                                      : nannyRate.max / 10
                                  }
                                  onChange={(e) => {
                                    if (
                                      nannyRate.min <
                                      (e.target.value == 5
                                        ? 60
                                        : e.target.value * 10)
                                    ) {
                                      setNannyRate({
                                        ...nannyRate,
                                        max:
                                          e.target.value == 5
                                            ? 60
                                            : e.target.value * 10,
                                      });
                                      setErrorField({
                                        ...errorField,
                                        nanyperhrrate: "",
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div class="slider two">
                                <ul>
                                  <li>0</li>
                                  <li>10</li>
                                  <li>20</li>
                                  <li>30</li>
                                  <li>40</li>
                                  <li>60+</li>
                                </ul>
                              </div>
                            </div>
                          )}
                          <span class="price">
                            {detail && detail.country == "Serbia"
                              ? nannyRate.min * 100 +
                                " - " +
                                nannyRate.max * 100
                              : nannyRate.min + " - " + nannyRate.max}
                          </span>
                        </div>

                        <div className="form_group full">
                          <label>
                            {t("start-date")}
                            <span
                              className={
                                errorField.tutorstartdate2 != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="date_cal">
                            <span>
                              <DateIcon />
                            </span>
                            <DatePicker
                              className={
                                errorField.tutorstartdate2 != ""
                                  ? "bordererror"
                                  : ""
                              }
                              minDate={today}
                              selected={
                                detailParents.tutorstartdate2 != null &&
                                detailParents.tutorstartdate2 != ""
                                  ? new Date(detailParents.tutorstartdate2)
                                  : today
                              }
                              onChange={(date) => {
                                setErrorField({
                                  ...errorField,
                                  tutorstartdate2: "",
                                });
                                setDetailParents({
                                  ...detailParents,
                                  tutorstartdate2:
                                    date.getFullYear() +
                                    "-" +
                                    String(date.getMonth() + 1).padStart(
                                      2,
                                      "0"
                                    ) +
                                    "-" +
                                    String(date.getDate()).padStart(2, "0"),
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span>{t("required-fields")}</span>
                  </div>
                </div>
              </div>
              <div class="button text-center">
                <div class="pull-right">
                  <button class="btn" onClick={(e) => handleCloseNannyModal()}>
                    {t("text-back")}
                  </button>
                </div>
                <div class="pull-right">
                  <button
                    class="btn confirm"
                    onClick={(e) => {
                      if (
                        detailParents.tutorexp != "" &&
                        nannyRate.max > 5 &&
                        rule.licence &&
                        rule.family &&
                        rule.housework &&
                        rule.kids &&
                        detailParents.liveinnany != "" &&
                        detailParents.tutorstartdate2 != ""
                      ) {
                        handleCloseNannyModal();
                        setTimeout(() => {
                          fetchDetailUpdate("preferences");
                        }, 400);
                      } else {
                        step1.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                      }
                    }}
                  >
                    {t("text-finish")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showJobModal && (
        <Modal
          show={showJobModal}
          onHide={(e) => {
            handleCloseJobModal();
            window.location.reload();
          }}
          className="question_new jobs"
        >
          <Modal.Body>
            <div className="younger">
              <Link
                to=""
                onClick={(e) => {
                  handleCloseJobModal();
                  window.location.reload();
                }}
              >
                +
              </Link>
              <br></br>
              <p>
                <b>{t("Create new Job post")}</b>
              </p>
              <div className="Profile_complete">
                <div className="detail work-experience job_performance job_post setp4">
                  {activeprofession &&
                  Object.keys(activeprofession).length == 2 ? (
                    <>
                      <div className="form_group full">
                        <label>
                          <strong>
                            {t("Details for ")}
                            {t(Object.values(activeprofession)[1])}
                            {t("text-position")}
                          </strong>
                        </label>
                        <br />
                        <label>
                          {t("post-job-title")}
                          <span
                            className={errorField.jobs2 != "" ? "starred" : ""}
                          >
                            *
                          </span>
                        </label>
                        <textarea
                          rows={2}
                          placeholder={t("post-activities")}
                          maxlength="70"
                          name="message"
                          onChange={(e) => {
                            setErrorField({ ...errorField, jobs2: "" });
                            setJobDescription({
                              ...jobDescription,
                              job_type2: Object.values(activeprofession)[1],
                            });
                            setDetailParents({
                              ...detailParents,
                              jobs2: e.target.value,
                            });
                          }}
                          defaultValue={detailParents.jobs2}
                        ></textarea>
                        <span>
                          {t("number-characters")}
                          {70 -
                            (detailParents.jobs2 != ""
                              ? detailParents.jobs2.length
                              : 0)}
                        </span>
                      </div>
                      <div className="form_group full sec">
                        <label>
                          {t("job-description")}
                          <span
                            className={
                              errorField.jobs_description2 != ""
                                ? "starred"
                                : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <textarea
                          rows={4}
                          placeholder={
                            activeprofession
                              ? Object.keys(activeprofession)[1] == "tab1"
                                ? t("post-jelena-nanny")
                                : Object.keys(activeprofession)[1] == "tab2"
                                ? t("post-jelena-teacher")
                                : Object.keys(activeprofession)[1] == "tab3"
                                ? t("post-jelena-paraprofessional")
                                : Object.keys(activeprofession)[1] == "tab4"
                                ? t("post-jelena-english")
                                : ""
                              : ""
                          }
                          maxlength="300"
                          name="message"
                          onChange={(e) => {
                            setErrorField({
                              ...errorField,
                              jobs_description2: "",
                            });
                            setDetailParents({
                              ...detailParents,
                              jobs_description2: e.target.value,
                            });
                          }}
                          defaultValue={detailParents.jobs_description2}
                        ></textarea>
                        <span>
                          {t("number-characters")}
                          {300 - detailParents.jobs_description2.length}
                        </span>
                      </div>

                      <div class="form_group full socialpost border">
                        <label>
                          {t(
                            "  I give my consent for this job to be shared by SensCare platform on social media"
                          )}
                          <span
                            className={
                              jobDescription.plateformonsocialmedia2 == ""
                                ? "starred"
                                : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div class="checkbox create">
                          <ul>
                            <li style={{ width: "25% !important" }}>
                              <input
                                type="radio"
                                name="ke"
                                onClick={(e) =>
                                  setJobDescription({
                                    ...jobDescription,
                                    plateformonsocialmedia2: "Yes",
                                  })
                                }
                                checked={
                                  jobDescription.plateformonsocialmedia2 ==
                                  "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="ke"
                                onClick={(e) =>
                                  setJobDescription({
                                    ...jobDescription,
                                    plateformonsocialmedia2: "No",
                                  })
                                }
                                checked={
                                  jobDescription.plateformonsocialmedia2 == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="form_group full">
                    <label>
                      <strong>
                        {t("Details ")}
                        {activeprofession
                          ? t(Object.values(activeprofession)[0])
                          : ""}
                        {t("text-position")}
                      </strong>
                    </label>
                    <br />
                    <label>
                      {t("post-job-title")}
                      <span className={errorField.jobs != "" ? "starred" : ""}>
                        *
                      </span>
                    </label>
                    <textarea
                      rows={2}
                      placeholder={
                        t("text-experience-gap") +
                        (activeprofession
                          ? t(Object.values(activeprofession)[0])
                          : "") +
                        t("years-experience-gap")
                      }
                      maxlength="70"
                      name="message"
                      onChange={(e) => {
                        setErrorField({ ...errorField, jobs: "" });
                        setDetailParents({
                          ...detailParents,
                          jobs: e.target.value,
                        });
                        setJobDescription({
                          ...jobDescription,
                          job_type: Object.values(activeprofession)[0],
                        });
                      }}
                      defaultValue={
                        detail.job[0] &&
                        detail.job[0].job_type ==
                          Object.values(activeprofession)[0]
                          ? detailParents.jobs
                          : detail.job[1] &&
                            detail.job[1].job_type ==
                              Object.values(activeprofession)[0]
                          ? detailParents.jobs
                          : ""
                      }
                    ></textarea>

                    <span>
                      {t("number-characters")}
                      {70 -
                        (typeof detailParents.jobs != "number"
                          ? detailParents.jobs.length
                          : 0)}
                    </span>
                  </div>

                  <div className="form_group full sec">
                    <label>
                      {t("job-description")}
                      <span
                        className={
                          errorField.jobs_description != "" ? "starred" : ""
                        }
                      >
                        *
                      </span>
                    </label>
                    <textarea
                      rows={4}
                      placeholder={
                        activeprofession
                          ? Object.keys(activeprofession)[1] == "tab1"
                            ? t("post-jelena-nanny")
                            : Object.keys(activeprofession)[1] == "tab2"
                            ? t("post-jelena-teacher")
                            : Object.keys(activeprofession)[1] == "tab3"
                            ? t("post-jelena-paraprofessional")
                            : Object.keys(activeprofession)[1] == "tab4"
                            ? t("post-jelena-english")
                            : ""
                          : ""
                      }
                      maxlength="300"
                      name="message"
                      onChange={(e) => {
                        setErrorField({ ...errorField, jobs_description: "" });
                        setDetailParents({
                          ...detailParents,
                          jobs_description: e.target.value,
                        });
                      }}
                      defaultValue={
                        detail.job[0] &&
                        detail.job[0].job_type ==
                          Object.values(activeprofession)[0]
                          ? detailParents.jobs_description
                          : detail.job[1] &&
                            detail.job[1].job_type ==
                              Object.values(activeprofession)[0]
                          ? detailParents.jobs_description
                          : ""
                      }
                    ></textarea>
                    <span>
                      {t("number-characters")}
                      {300 - detailParents.jobs_description.length}
                    </span>
                  </div>
                  <div className="job_note" id="job_note">
                    <FlagIcon />
                    <p>
                      {t(
                        " Your old profile information will be applied to this job post, too. "
                      )}
                      <br />
                      {t("please-update-profile")}
                      <Link
                        to="/search-parents/Profile"
                        onClick={(e) => {
                          localStorage.setItem("edittime", "editall");
                          localStorage.setItem("search", "Profile");
                        }}
                        target={"_blank"}
                      >
                        {t(" here")}
                      </Link>
                      {t("changes-meantime")}
                    </p>
                  </div>
                  <div class="form_group full socialpost ">
                    <label>
                      {t(
                        " I give my consent for this job to be shared by SensCare platform on social media"
                      )}
                      <span
                        className={
                          jobDescription.plateformonsocialmedia == ""
                            ? "starred"
                            : ""
                        }
                      >
                        *
                      </span>
                    </label>
                    <div class="checkbox create">
                      <ul>
                        <li style={{ width: "25% !important" }}>
                          <input
                            type="radio"
                            name="e"
                            onClick={(e) =>
                              setJobDescription({
                                ...jobDescription,
                                plateformonsocialmedia: "Yes",
                              })
                            }
                            checked={
                              jobDescription.plateformonsocialmedia == "Yes"
                                ? true
                                : false
                            }
                          />
                          <span> {t("confirm")}</span>
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="e"
                            onClick={(e) =>
                              setJobDescription({
                                ...jobDescription,
                                plateformonsocialmedia: "No",
                              })
                            }
                            checked={
                              jobDescription.plateformonsocialmedia == "No"
                                ? true
                                : false
                            }
                          />
                          <span> {t("objection")}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button text-center">
                <div class="pull-right">
                  <button class="btn" onClick={(e) => handleCloseJobModal()}>
                    {t("text-back")}
                  </button>
                </div>
                <div class="pull-right">
                  <button
                    class="btn confirm"
                    onClick={(e) => {
                      if (
                        detailParents.jobs != "" &&
                        detailParents.jobs_description != "" &&
                        (activeprofession &&
                        Object.keys(activeprofession).length == 2
                          ? detailParents.jobs2 != "" &&
                            detailParents.jobs_description2 != "" &&
                            jobDescription.plateformonsocialmedia2 &&
                            jobDescription.plateformonsocialmedia
                          : detailParents.jobs != "")
                      ) {
                        setTimeout(() => {
                          handleCloseJobModal();
                          fetchDetailUpdate("final");
                          setShowLiveModal(true);
                        }, 1000);
                      } else {
                        step5.map((e) => {
                          validateAfterLoginField(e.name);
                        });
                        window.scrollTo({ top: 0 });
                      }
                    }}
                  >
                    {t("text-publish")}
                  </button>
                </div>
              </div>
              <br />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showLiveModal && (
        <Modal show={showLiveModal} onHide={(e) => window.location.reload()}>
          <Modal.Body>
            <div className="promocode_content login_first">
              <Link to="" onClick={(e) => window.location.reload()}>
                +
              </Link>
              <h2>{t("Congratulations! Your post is live now.")}</h2>
              <img
                src={window.location.origin + "/images/create_profile.png"}
              />
              <p className="pro">
                {t("senscare-wishes")}
                <Link
                  to="/search-parents/job-post"
                  target="_blank"
                  onClick={(e) => {
                    localStorage.setItem("search", "job_post");
                    localStorage.removeItem("search2", "Job_history");
                  }}
                >
                  {t("text-here")}
                </Link>
                .
              </p>
              <button onClick={handleRedirect}>{t("search-candidates")}</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
