import React, { useState, useEffect } from "react";

import { fetchMessageCount } from "../../../api";

export function MessageCount() {
  const [messageData, setMessageData] = useState({});
  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    fetchAndUpdateMessageCount();
  }, [messageData, requestCount]);

  const fetchAndUpdateMessageCount = async () => {
    try {
      const data = await fetchMessageCount();

      setMessageData(data);
      setRequestCount((prevCount) => prevCount + 1);
      setTimeout(fetchAndUpdateMessageCount, 10000);
    } catch (error) {
      console.error("Error fetching message count:", error);
    }
  };

  return <>{messageData.msg_count}</>;
}
